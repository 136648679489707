import React, { useEffect } from 'react'
import Tracking from './component/tracking'
import { AdsContainer, GraphWrapper, Wrapper } from './index.styled'
import ActiveCampaign from './component/activeCampaign'
import TotalAds from './component/totalAds'
import TopDomains from './component/topDomains'
import AdDistribution from './component/adDistribution'
import Trend from './component/trend/index'
import { useDomainOverview } from '../context/domainoverview'
import { getSearchParams } from '../../../../utils/helper'
import { useLocation } from 'react-router-dom'
import TableComponent from './component/tableComponent/tableComponent'
import { countriesList } from '../../../../utils/countryData'
import { useDomainOverviewInfo } from '../hooks/useDomainOverviewInfo'

const KeywordOverviewDetails = () => {
  const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()
  const useDomainOverviewInfoHook = useDomainOverviewInfo()
  const location = useLocation()

  const handleCountrySearch = (data) => {
    let searchTerm = data;
    if (!searchTerm.trim()) {
        domainOverviewDispatch({ type: 'SET_OVERVIEW_COUNTRY', value: {} });
        return; // Return early if searchTerm is empty
    }
    let country = countriesList.find((country) => country.name.common.toLocaleLowerCase() === searchTerm.toLocaleLowerCase())
    domainOverviewDispatch({ type: 'SET_OVERVIEW_COUNTRY', value: country });
  }

  useEffect(() => {
    const searchParamFromState = getSearchParams(location.search)

    const {
      domain,
      country
    } = searchParamFromState
    domainOverviewDispatch({type: 'SET_DOMAIN_INPUT', value: domain})
    handleCountrySearch(country)
    useDomainOverviewInfoHook.getAdStatsMutation({ query: domain, 'country': country });
    useDomainOverviewInfoHook.getTopDomainsMutation({ query: domain, 'country': country });
    useDomainOverviewInfoHook.getHeatmapDataMutation({query: domain, 'country': country})
  }, [location.search])

  return (
    <Wrapper>
        <Tracking />
        <AdsContainer>
          <GraphWrapper>
              {<ActiveCampaign isTableDataLoading={useDomainOverviewInfoHook.isGetAdStatsLoading}/>}
              {<TotalAds isTableDataLoading={useDomainOverviewInfoHook.isGetAdStatsLoading}/>}                        
          </GraphWrapper>
          <TopDomains isTopDomainsLoading={useDomainOverviewInfoHook.isGetTopDomainsLoading}/>
        </AdsContainer>
        <AdDistribution />
        <Trend isHeatMapDetailsLoading={useDomainOverviewInfoHook.isGetHeatmapDataLoading} isTableDataLoading={useDomainOverviewInfoHook.isGetAdStatsLoading}/>
        <TableComponent/>
    </Wrapper>
  )
}

export default KeywordOverviewDetails   