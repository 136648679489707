import styled from "styled-components";

export const Wrapper = styled.div`
  width: calc(100% - 5px);
  height: calc(100% - 9px);
  padding: 25px 0px 10px 16px;
  overflow-x: auto;
  margin-left: 5px;
  ::-webkit-scrollbar-track {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
    background-color: #fff;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgb(245 245 245 / 92%);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 10px;
  }
`;

export const TopImageAdsContainer = styled.div`
  display: flex;
  gap: 14px;
`;

export const AdContainer = styled.div`
  width: 201px;
  height: 209px;
  flex-shrink: 0;
  border-radius: 3.211px;
  border: 0.401px solid #e8e8e8;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const LogoContainer = styled.div`
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.401px solid #ececec;
  background: #f1f3f4;
`;

export const Logo = styled.img`
  width: 9px;
  height: 9px;
`;

export const Advertiser = styled.div``;

export const Name = styled.div`
  overflow: hidden;
  color: #2e2e2e;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 6.421px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SponseredTag = styled.div`
  overflow: hidden;
  color: #598763;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 4.816px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const AdImage = styled.img`
  width: 200.656px;
  object-fit: contain;
  height: 100%;
`;
export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83%;
`;
export const OopsDiv = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  p {
    color: rgb(95 95 95);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-top: 8px;
  }
`;
