import React from "react";
import { useReducer, createContext, useContext } from "react";

import produce from "immer";

const initialState = {
  loadingResults: false,
  searchMode: "url",
  noResults: false,

  searched: false,
  /* KEYWORD */
  keywordSearchType: "phrase",
  /* Keyword broad match state */
  keywords: [],
  keywordInputError: false,
  broadMinShouldMatch: {
    name: "Min. match",
    value: 0,
  },
  broadSearchIn: [
    {
      name: "Title",
      value: "title",
      checked: true,
    },
    {
      name: "Content",
      value: "content",
      checked: true,
    },
  ],
  searchInError: false,

  excludedTerm: "",

  /* Keyword phrase match state */
  phraseSearchIn: [
    {
      name: "Title",
      value: "title",
      checked: true,
    },
    {
      name: "Content",
      value: "content",
      checked: true,
    },
  ],
  phraseInput: "",

  /* URL */
  urlSearchType: "exact",
  // selectedUrlSearchType: "domain",

  domainUrlInput: {
    value: "",
    valid: false,
  },
  urlInputError: false,

  ads: [],
  totalAds: 0,
  currentPageNo: 1,
  highlightWords: [],

  detailsModal: false,

  queryId: '',
  
  // FILTERS
  filterStatus: "loaded",

  // Country filter
  isCountrySelected: false,
  availableCountries: [],
  selectedCountries: [],
  filteredCountries: [],
  selectedRegion: [],
  openDropDown: false,

  // Date filter
  startDate: "",
  endDate: "",
  dateFilterType: "",

  // Agency filter
  agencies: [],
  selectedAgency: "",

  // Aspect ratio filter
  selectedAspectRatio: "",

  //SortBy filter
  selectedSortType: {
    name: "Relevance",
    type: "relevant",
  },

  adDetails: {},
};

const DisplayReducer = produce((draft, action) => {
  switch (action.type) {
    case "UPDATE_LOADING_RESULTS":
      draft.loadingResults = action.value;
      break;

    case "UPDATE_NO_RESULTS":
      draft.noResults = action.value;
      break;
    case "SET_SEARCH_MODE":
      draft.searchMode = action.value;
      break;
    case "SET_KEYWORD_SEARCHTYPE":
      draft.keywordSearchType = action.value;
      break;
    case "SET_KEYWORDS":
      draft.keywords = action.value;
      break;
    case "SET_KEYWORD_INPUT_ERROR":
      draft.keywordInputError = action.value;
      break;

    // BROAD ACTIONS
    case "SET_BROAD_MIN_SHOULD_MATCH":
      draft.broadMinShouldMatch = action.value;
      break;
    case "UPDATE_EXCLUDED_TERM":
      draft.excludedTerm = action.value;
      break;
    case "SET_BROAD_SEARCH_IN":
      if (action.payload.value === "title") {
        draft.broadSearchIn = draft.broadSearchIn.filter(
          (cb) => cb.value !== action.payload.value
        );
        draft.broadSearchIn.unshift(action.payload);
      } else if (action.payload.value === "content") {
        draft.broadSearchIn = draft.broadSearchIn.filter(
          (cb) => cb.value !== action.payload.value
        );
        draft.broadSearchIn.push(action.payload);
      }
      break;

    // PHRASE ACTIONS
    case "SET_PHRASE_SEARCH_IN":
      if (action.payload.value === "title") {
        draft.phraseSearchIn = draft.phraseSearchIn.filter(
          (cb) => cb.value !== action.payload.value
        );
        draft.phraseSearchIn.unshift(action.payload);
      } else if (action.payload.value === "content") {
        draft.phraseSearchIn = draft.phraseSearchIn.filter(
          (cb) => cb.value !== action.payload.value
        );
        draft.phraseSearchIn.push(action.payload);
      }
      break;

    case 'SET_SEARCH_IN_ERROR':
      draft.searchInError = action.value
      break

    case "SET_PHRASE_INPUT":
      draft.phraseInput = action.value;
      break;
    case "SET_HIGHLIGHT_WORDS":
      draft.highlightWords = action.value;
      break;

    // DOMAIN
    case "SET_DOMAIN_URL_INPUT":
      draft.domainUrlInput = action.payload;
      break;
    case "SET_URL_SEARCHTYPE":
      draft.urlSearchType = action.value;
      break;
    case "SET_URL_INPUT_ERROR":
      draft.urlInputError = action.value;
      break;

    case "SET_ADS":
      draft.ads = action.value;
      break;
    case "SET_TOTAL_ADS":
      draft.totalAds = action.value;
      break;
    case "SET_CURRENT_PAGE_NO":
      draft.currentPageNo = action.value;
      break;
    case "SET_AVAILABLE_COUNTRIES":
      draft.availableCountries = action.value;
      break;

    case "UPDATE_DETAILS_MODAL":
      draft.detailsModal = action.value;
      break;

    // Filter Status
    case "UPDATE_FILTER_STATUS":
      draft.filterStatus = action.value;
      break;

    // Filters
    case "UPDATE_SELECTED_COUNTRIES":
      draft.selectedCountries = action.value;
      break;
    case "UPDATE_FILTERED_COUNTRIES":
      draft.filteredCountries = action.value;
      break;
    case "UPDATE_IS_COUNTRY_SELECTED":
      draft.isCountrySelected = action.value;
      break;
    case "UPDATE_SELECTED_REGION":
      draft.selectedRegion = action.value;
      break;
    case "UPDATE_OPEN_DROP_DOWN":
      draft.openDropDown = action.value;
      break;

    //Date filter
    case "UPDATE_START_DATE":
      draft.startDate = action.value;
      break;
    case "UPDATE_END_DATE":
      draft.endDate = action.value;
      break;
    case "UPDATE_DATE_FILTER_TYPE":
      draft.dateFilterType = action.value;
      break;
    // AgencyFilter
    case "UPDATE_AGENCIES":
      draft.agencies = action.value;
      break;
    case "UPDATE_SELECTED_AGENCY":
      draft.selectedAgency = action.value;
      break;
    // Aspect Ratio Filter
    case "UPDATE_ASPECT_RATIO":
      draft.selectedAspectRatio = action.value;
      break;
    // Sort type
    case "UPDATE_SELECTED_SORT_TYPE":
      draft.selectedSortType = action.value;
      break;
    case "UPDATE_SEARCHED":
      draft.searched = action.value;
      break;

    case "CLEAR_FILTERS":
      // draft.currentPageNo = 1
      // draft.highlightWords = []
      draft.availableCountries = [];
      draft.selectedCountries = [];
      draft.filteredCountries = [];
      draft.selectedRegion = [];
      draft.dateFilterType = "";
      draft.startDate = "";
      draft.endDate = "";
      draft.selectedSortType = { name: "Relevance", type: "relevant" };
      // draft.filterStatus = 'loaded'
      break;

    case "SET_AD_DETAILS":
      draft.adDetails = action.value;
      break;
    
    case 'SET_EMPTY_RESULT_ID':
        draft.queryId = action.value
        break

    case "CLEAR_ALL":
      return initialState;

    default:
      break;
  }
});

const useDisplayReducer = () => {
  return useReducer(DisplayReducer, initialState);
};

const DisplayContext = createContext();

export const DisplayProvider = ({ children }) => {
  const [state, dispatch] = useDisplayReducer();

  const value = { displayState: state, displayDispatch: dispatch };
  // const value = useMemo(() => {
  //     return { displayState: state, displayDispatch: dispatch };
  //   }, [state]);

  return (
    <DisplayContext.Provider value={value}>{children}</DisplayContext.Provider>
  );
};

export const useDisplay = () => {
  return useContext(DisplayContext);
};
