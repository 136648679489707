import React, { memo, useCallback } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

import { Input, InputContainer, ToolTipImageContainer, ToolTipWarning, Wrapper, ToolTipContainer, PopularSearches } from './index.styled'
import ToolTipLogo from "../../../../../../../../../media/Dashboard/tooltip.svg"

import SearchIcon from '../../../../../../../../../media/Dashboard/searchIcon.svg'
import { Tooltip } from 'react-tooltip'

import { useAuth } from "../../../../../../../../../context/auth"

const PhraseInput = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { authState, authDispatch } = useAuth()
    const navigate = useNavigate()

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            const { searchMode } = authState

            if (!authState.searchPlatform) {
                return authDispatch({ type: 'SET_SEARCH_PLATFORM_ERROR', value: true })
            }

            if (!authState.features?.allowedPlatforms.includes(authState.searchPlatform)) {
                authDispatch({ type: 'UPDATE_IS_RESTRICTED', value: true })
            } else if (searchMode === "keyword") {
                // console.log("2", searchState)
                const { keywordSearchType } = authState
                if (keywordSearchType === "broad") {
                    const { broadMinShouldMatch, keywords } = authState

                    if (keywords.length == 0) {
                        return authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: true })
                    }

                    let broadSearchIn = ["content", "title"]
                    if (authState.searchPlatform == "meta") {
                        broadSearchIn = broadSearchIn.concat(["innertitle", "innerdescription"])
                    }

                    authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: keywords })

                    let paramQuery = keywords.join(",")

                    searchParams.set('searchMode', "keyword")
                    searchParams.set('keywordSearchType', "broad")
                    searchParams.set('keywords', paramQuery);
                    searchParams.set('page', 1);
                    searchParams.set('excludedTerm', "");
                    searchParams.set('minimumShouldMatch', broadMinShouldMatch.value);
                    searchParams.set('searchIn', broadSearchIn.join(","));
                    searchParams.set('sortType', 'relevant');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate');
                    searchParams.delete('cta');
                    searchParams.delete('tone');
                    searchParams.delete('otherFilters');
                    searchParams.delete('sentiment');
                    // setSearchParams(searchParams)                

                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

                } else if (keywordSearchType === "phrase") {
                    const { phraseInput } = authState
                    if (phraseInput.trim().length == 0) {
                        return authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: true })
                    }

                    let phraseSearchIn = ["content", "title"]
                    if (authState.searchPlatform == "meta") {
                        phraseSearchIn = phraseSearchIn.concat(["innertitle", "innerdescription"])
                    }

                    authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: phraseInput.split(" ") })

                    let paramQuery = phraseInput.trim()

                    searchParams.set('searchMode', "keyword")
                    searchParams.set('keywordSearchType', "phrase")
                    searchParams.set('phraseInput', paramQuery);
                    searchParams.set('page', 1);
                    searchParams.set('excludedTerm', "");
                    searchParams.set('searchIn', phraseSearchIn.join(","));
                    searchParams.set('sortType', 'relevant');
                    searchParams.delete('selectedCountries');
                    searchParams.delete('startDate');
                    searchParams.delete('endDate');
                    searchParams.delete('cta');
                    searchParams.delete('tone');
                    searchParams.delete('otherFilters');
                    searchParams.delete('sentiment');
                    // setSearchParams(searchParams)
                    navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)


                }
            }
        }
    }

    const inputChange = useCallback((e) => {
        authDispatch({ type: "SET_PHRASE_INPUT", value: e.target.value })
        authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: false })
    })

    return <Wrapper
        data-tooltip-id="disabled-tooltip"
        data-tooltip-content="Please select a platform first"
    >
        {!authState.searchPlatform && <Tooltip id="disabled-tooltip" />}
        <InputContainer
            isDisabled={!authState.searchPlatform}
        >
            <img src={SearchIcon} />
            <Input
                isDisabled={!authState.searchPlatform}
                onKeyDown={handleSearch}
                error={authState.keywordInputError}
                name="phrase-input"
                type="text"
                autoComplete="off"
                placeholder={authState.searchPlatform ? "Search here" : "Select a Platform first"}
                value={authState.phraseInput}
                onChange={inputChange}
            />
            <ToolTipImageContainer
                error={authState.keywordInputError}
                className="tooltip-container">
                <img src={ToolTipLogo} />
                <ToolTipWarning>
                    You can enter only 1 term
                </ToolTipWarning>
            </ToolTipImageContainer>
        </InputContainer>
    </Wrapper>
}

export default memo(PhraseInput)