import React, { useEffect } from 'react'
import Tracking from './component/tracking'
import { AdsContainer, GraphWrapper, Wrapper } from './index.styled'
import ActiveCampaign from './component/activeCampaign'
import TotalAds from './component/totalAds'
import TopDomains from './component/topDomains'
import AdDistribution from './component/adDistribution'
import Trend from './component/trend/index'
import { useKeywordTracking } from '../context/keywordtracking';
import { useLocation } from 'react-router-dom'
import { getSearchParams } from '../../../../utils/helper'
import { useKeywordTrackingInfo } from '../hooks/useKeywordTrackingInfo'


const KeywordTrackingDetails = () => {
  const { keywordTrackingState, keywordTrackingDispatch } = useKeywordTracking()
  const location = useLocation()
  const useKeywordTrackingInfoHook = useKeywordTrackingInfo()

  useEffect(() => {
    if (!keywordTrackingState.keywordInputModalOpen) {

      const searchParamFromState = getSearchParams(location.search)

      const {
        userKeywordRefId
      } = searchParamFromState

      if (userKeywordRefId) {
        useKeywordTrackingInfoHook.getTrackingDetailsMutation({ userKeywordRefId })
      }
    }

  }, [keywordTrackingState.keywordInputModalOpen])


  return (
    <Wrapper>
      {useKeywordTrackingInfoHook.isGetTrackingDetailsLoading ? ""
        : <><Tracking />
          <AdsContainer>
            <GraphWrapper>
              {<ActiveCampaign isTableDataLoading={useKeywordTrackingInfoHook.isGetAdStatsLoading} />}
              {<TotalAds isTableDataLoading={useKeywordTrackingInfoHook.isGetAdStatsLoading} />}
            </GraphWrapper>
            <TopDomains isTopDomainsLoading={useKeywordTrackingInfoHook.isGetTopDominsLoading} />
          </AdsContainer>
          {<AdDistribution isTableDataLoading={useKeywordTrackingInfoHook.isGetAdStatsLoading} />}
          {keywordTrackingState.keywordInput.length > 0 && <Trend keyword={keywordTrackingState.keywordInput} />}
          {/* <TableComponent /> */}
        </>}
    </Wrapper>
  )
}

export default KeywordTrackingDetails