import { useAuth } from '../../context/auth';
import { appClient } from '../../utils/axios-utils';
import {useMutation, useQuery} from "@tanstack/react-query"
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";

const CreateSubscription = async (data) => {
    const config = {
      url: "/payment/session/checkout-session",
      method: "POST",
      data,
    };
  
    return appClient(config);
  };

  
export const useSubscription = () => {
    const { authState, authDispatch } = useAuth();
    const stripe = useStripe();

    const { mutate: CreateSubscriptionMutate, isLoading: isSubscriptionLoading } =
    useMutation(CreateSubscription, {
      onSuccess: (data) => {
        window.open(`${data}`)
        // stripe.redirectToCheckout({ sessionId: data });
      },
      onError: (err) => {
        console.log(err);
      },
    });

    return {
        CreateSubscriptionMutate,
        isSubscriptionLoading
    };
};
