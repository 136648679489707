import React, { useEffect } from "react";
import { GraphContainer, Header } from "./index.styled";
import { Line } from "react-chartjs-2";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";
import { useDashboard } from "../../../context/dashboard";

const CompetitorsChart = () => {
  let dataLabel = [];
  let dataResult = [];
  let colorsArray = [
    "#9362FC",
    "#62A9FC",
    "#FCD384",
    "#00bbb4",
    "#ff76ae",
    "#ed7218",
  ];
  const { getCompetitorChartValue, isCompetitorChartValue } =
    useDashboardInfo();
  const {
    dashboardState: {
      selectedProject: { id, competitors },
      competitorsChart,
    },
  } = useDashboard();

  competitorsChart.length > 0 &&
    competitorsChart.map((item, index) => {
      index == 0 &&
        item.chartResult.map((result) => {
          dataLabel.push(result.month);
        });
      const value = item.chartResult.map((val) => val.value);
      const color = colorsArray.map((col, idx) => index == idx && col);
      dataResult.push({
        label: item.domainName,
        fill: false,
        lineTension: 0.05,
        backgroundColor: color[index],
        borderColor: color[index],
        pointRadius: 5,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointHoverBorderColor: color[index],
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: value,
      });
    });
  const data = {
    labels: dataLabel,
    datasets: dataResult,
    // [
    //   {
    //     label: "loom.com",
    //     fill: false,
    //     lineTension: 0.05,
    //     backgroundColor: "#9362FC",
    //     borderColor: "#9362FC",
    //     pointRadius: 5,
    //     pointBackgroundColor: "transparent",
    //     pointBorderColor: "transparent",
    //     pointHoverBorderColor: "#9362FC",
    //     pointHoverRadius: 5,
    //     pointHoverBorderWidth: 2,
    //     pointHoverBackgroundColor: "#fff",
    //     borderWidth: 3,
    //     data: [35, 50, 55, 67, 70, 74, 87, 90],
    //   },
    //   {
    //     label: "abcd.com",
    //     fill: false,
    //     lineTension: 0.05,
    //     backgroundColor: "#62A9FC",
    //     borderColor: "#62A9FC",
    //     pointRadius: 5,
    //     pointBackgroundColor: "transparent",
    //     pointBorderColor: "transparent",
    //     pointHoverBorderColor: "#62A9FC",
    //     pointHoverRadius: 5,
    //     pointHoverBorderWidth: 2,
    //     pointHoverBackgroundColor: "#fff",
    //     borderWidth: 3,
    //     data: [24, 32, 44, 49, 57, 60, 72, 80],
    //   },
    //   {
    //     label: "efgh.com",
    //     fill: false,
    //     lineTension: 0.05,
    //     backgroundColor: "#FCD384",
    //     pointRadius: 5,
    //     pointBackgroundColor: "transparent",
    //     pointBorderColor: "transparent",
    //     pointHoverBorderColor: "#FCD384",
    //     pointHoverRadius: 5,
    //     pointHoverBorderWidth: 2,
    //     pointHoverBackgroundColor: "#fff",
    //     borderColor: "#FCD384",
    //     borderWidth: 3,
    //     data: [20, 25, 30, 47, 52, 64, 62, 60],
    //   },
    // ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      x: {
        grid: {
          // display: false,
        },
      },
      y: {
        display: true,
      },
    },
  };

  useEffect(() => {
    getCompetitorChartValue({
      projectId: id,
    });
  }, [competitorsChart.length]);

  return (
    competitors.length > 0 && (
      <GraphContainer>
        <Header>
          <h2>Competitor ads Tracker (Top 3) </h2>
          <p>Average ads runs in last 1 year</p>
        </Header>
        <Line data={data} options={options} />
      </GraphContainer>
    )
  );
};

export default CompetitorsChart;
