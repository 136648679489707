import React from "react";
import styled from "styled-components";
import { useNavigate} from 'react-router-dom'
import {
     useQuery
} from "@tanstack/react-query"
import {appClient} from '../../../../utils/axios-utils'
import Layout from "./../Components/Layout"
import SuccessfulVerify from "../../../../media/Dashboard/successfulVerify.svg"
import produce from 'immer'
import {useAuth} from '../../../../context/auth'


const Section = styled.div`
    h2{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #2E2E2E;
    }   
    p{
        margin-top: 20px;
        font-size: 14px;
        font-weight: 300;
        color: #707070;
    }
`; 

const VerifiedContainer = styled.div`
    display: flex;
    width: 306px;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    img{
        height: 70px;
    }
    
    p{
        margin-top: 20px;
        margin-left: 20px;
        color: #707070;
        font-size: 16px;
        font-weight: 300;
    }
`;
const HomeButton = styled.button`
    width: 234px;
    height: 40px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: ${props => props.currentPlan ? "#FFD0C0" : "#FF711E"};
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 16px;
    :hover {
        filter: brightness(90%);
    }
`
//   const sessioDetails = async (id) => {
//     const config = {
//       url: `/payment/session/getPaymentDetails/${id}`,
//       method: "GET"
//     }
  
//     return appClient(config)
//   }

const currentUser = () => {
    const config = {
        url: "/auth/current-user",
        method: "GET"
    }

    return appClient(config)
}

const PaymentSuccess = () => {
    const {authDispatch, authState} = useAuth()
    const navigate = useNavigate()
    // const location = useLocation()
    // const {session_id} = getSearchParams(location.search)

    // const {mutate: getDetails, isLoading: isDetailsLoading} = useMutation(sessioDetails, {
    //     onSuccess: (data) => {
            
    //     },
    //     onError:  ((e)=>{
    //         console.log(e)
    //     })
    // })

    // useEffect(() => {
    //     if(session_id){
    //         getDetails(session_id)
    //     }
    // }, [])

    const handleSuccess = (data) => {
        let renamedRoutes = {
            'facebook': "meta",
            'bing-ecom': "bingpla",
            'google-ecom': "googlepla"
        }
    
        if(data.features){
            data = produce(data, (draft) => {
                let alteredArray = data?.features?.allowedPlatforms.map((platform)=>{
                    if (platform in renamedRoutes) {
                        return renamedRoutes[platform]
                    }
    
                    return platform
                })
    
                draft.features.allowedPlatforms = alteredArray
            });
        }
    
        authDispatch({type: 'CURRENT_USER_UPDATE', data, isAuthenticated: true})
        authDispatch({type: 'STOP_LOADER'})
        navigate("/adspy")
    }

    const handleAcquireError = (err) => {
        console.log(err)
        authDispatch({type: 'LOGOUT_SUCCESS'})
        authDispatch({type: 'STOP_LOADER'})
    }

    let currentUserQuery = useQuery(['current-user'], currentUser, {
        retry: false,
        // refetchInterval: 20000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
        onSuccess: handleSuccess,
        onError: handleAcquireError
    })

    const onHome = () => {
        currentUserQuery.refetch()
    }
    
    return <Layout>
        <Section> 
            <VerifiedContainer>
                <img src={SuccessfulVerify}/>
            </VerifiedContainer>
            <h2>
                Your trial has been activated
            </h2>
            <p>
            Your card has been validated via a $1.00 reversible transaction. It may take about 10 minutes to be processed. You can start using AdSpyder now.
            </p>
            <HomeButton onClick={onHome}>
                Start using AdSpyder
            </HomeButton>
        </Section>
    </Layout>
}

export default PaymentSuccess