import React, {useEffect} from "react";
import styled from "styled-components";
import { useAccount } from "../../context/account";
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from "../../../../../context/auth";
import UserIcon from "../../../../../media/Dashboard/UserIcon.png"
import { useGetAccount } from "../../../../hooks/useGetAccount";
import ProfileLogo from "../../../../../media/Dashboard/accounts-profile.svg"
import SubscriptionLogo from "../../../../../media/Dashboard/accounts-subscription.svg";
import DatabaseLogo from "../../../../../media/Dashboard/accounts-database.svg"
import SearchLogo from "../../../../../media/Dashboard/searchIcon.svg"
import ProfileDarkLogo from "../../../../../media/Dashboard/accounts-profile-dark.svg"
import SubscriptionDarkLogo from "../../../../../media/Dashboard/accounts-subscription-dark.svg";
import DatabaseDarkLogo from "../../../../../media/Dashboard/accounts-database-dark.svg"
import SearchDarkLogo from "../../../../../media/Dashboard/accounts-search-dark.svg"

const NavBar = styled.div`
    width: 244px;
    padding: 12px;
    height: auto;
    min-height: 400px;
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    /* background: #FFFFFF; */
    margin-right: 16px;
    height: fit-content;
    h2{
        color: #2E2E2E;
        font-size: 22px;
        font-weight: 600;
        line-height: 38px;
    }
    h3{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(28, 28, 28, 0.50);
    }
`;

const NavItem = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    padding: 6px 8px;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    line-height: 16px;
    margin-top: 4px;
    color: ${props => props.isActive ? "#rgba(28, 28, 28, 0.90)" : "rgba(28, 28, 28, 0.50)"};

`;

const Divider = styled.div`
    margin: 12px 0px;
    height: 2px;
    background: #9e9e9e6b;
    width: 20px;
    margin-left: 8px;
`;

const ProfileDetails = styled.div`
    margin: 52px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        height: 90px;
        width: 90px;
        border-radius: 50%;
    }
`;

const Email = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: rgba(28, 28, 28, 0.90);
    margin-top: 14px;
`;

const AccountType = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(28, 28, 28, 0.50);
    margin-top: 4px;
`;

const navItems = [
    {
        name: "Profile",
        id: "profile",
        logodark: ProfileDarkLogo,
        logolight: ProfileLogo
    },
    {
        name: "Subscription",
        id: "subscription",
        logodark: SubscriptionDarkLogo,
        logolight: SubscriptionLogo
    },
    // {

    // },
    // {
    //     name: "Saved Items Collections",
    //     id: "saved-ads"
    // },
    {
        name: "Search History",
        id: "search-history",
        logodark: SearchDarkLogo,
        logolight: SearchLogo
    },
    {
        name: "Data Crawl Requests",
        id: "crawl-requests",
        logodark: DatabaseDarkLogo,
        logolight: DatabaseLogo
    },
    // {
    //     name: "Keyword Search Summary",
    //     id: "Keywords-summary"
    // },
    // {
    //     name: "Tracking Settings",
    //     id: "tracking"
    // },
    // {
    //     name: "Notifications",
    //     id: "notifications"
    // }
]

const SideNavBar = () => {
    const { accountState, accountDispatch } = useAccount()
    const { authState, authDispatch } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const accountInfoHook = useGetAccount();

    const getDaysLeft = (planEndDate) => {
        let endDate = new Date(planEndDate).getTime() / 1000;
        let currentDate = Date.now() / 1000;

        const timestamp = endDate - currentDate; // Replace with your timestamp in seconds
        const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

        const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds

        // Calculate the number of days
        const days = Math.floor(date.getTime() / millisecondsPerDay);

        return days;
    };

    useEffect(() => {
        accountDispatch({ type: "UPDATE_IS_USER_DATA_LOADING", value: true });
        accountInfoHook.getUserAccountInfo();
    }, []);
    

    return <NavBar>
        <h2>
            Account Settings
        </h2>
        <h3>
            Manage your profile here
        </h3>
        <ProfileDetails>
            <img src={authState?.profileImage ? authState.profileImage : UserIcon} />
            <Email>{authState.email}</Email>
            <AccountType>
                {getDaysLeft(accountState.userData.planValidity) < 0 ? (
                    "Expired"
                ) : (
                    <>
                        <span>{getDaysLeft(accountState.userData.planValidity)}</span>{" "}
                        days{" "}
                        {accountState.userData.type == "free"
                            ? "free"
                            : accountState.userData.type == "subscription"
                                ? accountState.userData.isTrialing
                                    ? "trialing"
                                    : "(Renews automatically)"
                                : ""}
                    </>
                )}
            </AccountType>
        </ProfileDetails>
        
        {navItems.map((item, idx) => {
            if (item.name) {
                return <NavItem key={idx} isActive={item.id == accountState.currentPage} onClick={() => {
                    // accountDispatch({ type: 'SET_CURRENT_PAGE', value: item.id })
                    navigate(`/account/${item.id}`)
                }}>
                    <img src={item.id == accountState.currentPage ? item.logodark : item.logolight}/>{item.name}
                </NavItem>
            } else {
                return <Divider />
            }
        })}
    </NavBar>
}

export default SideNavBar