import { appClient } from "../../../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDashboard } from "../context/dashboard";

const getKeywordChoices = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/keyword-suggestions`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getCompetitorChoices = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/competitor-suggestions`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const addKeywordsForTracking = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/add-keyword`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const addCompetitorsForTracking = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/add-competitors`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const TrackingKeywords = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-tracking-keywords/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};

const getKeywordVolumeChartData = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/keyword-chart`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const Competitors = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-competitor-tracking-domains/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};
const getProjectData = () => {
  const config = {
    url: `/dashboard-overview/competitors/get-projects`,
    method: "GET",
  };

  return appClient(config);
};
const getIndividual = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-individual-project/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};

const DeleteTrackingKeywords = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/remove-keyword-tracking`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const DeleteCompetitors = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/remove-competitors-tracking`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getImageAds = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/top-image-ads`,
    method: "POST",
    data,
  };
  return appClient(config);
};

const getTextAds = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/top-text-ads`,
    method: "POST",
    data,
  };
  return appClient(config);
};

const getCompetitorsChartData = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/competitors-chart`,
    method: "POST",
    data,
  };
  return appClient(config);
};

export const useDashboardInfo = () => {
  const {
    dashboardState: { selectedProject, selectedCountry, projectList },
    dashboardDispatch,
  } = useDashboard();

  const {
    mutate: getTrackingKeywordData,
    isLoading: isTrackingKeywordLoading,
  } = useMutation(TrackingKeywords, {
    onSuccess: (data) => {
      dashboardDispatch({ type: "SET_TRACKING_KEYWORDS", value: data });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: getProjectDetails, isLoading: isProjectDetailsLoading } =
    useMutation(getProjectData, {
      onSuccess: (data) => {
        dashboardDispatch({ type: "SET_PROJECT_LIST", value: data });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const {
    mutate: getIndividualProject,
    isLoading: isIndividualProjectLoading,
  } = useMutation(getIndividual, {
    onSuccess: (data) => {
      dashboardDispatch({
        type: "SET_SELECTED_PROJECT",
        value: data,
      });
      dashboardDispatch({
        type: "SET_COMPETITOR_SETUP_STATUS",
        value: data.setupDomains,
      });
      dashboardDispatch({
        type: "SET_KEYWORD_SETUP_STATUS",
        value: data.setupKeywords,
      });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: deleteTrackingKeywords,
    isLoading: isDeleteTrackingKeywordsLoading,
  } = useMutation(DeleteTrackingKeywords, {
    onSuccess: (data) => {
      getTrackingKeywordData(selectedProject.id);
      getIndividualProject(selectedProject.id);
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: deleteCompetitors, isLoading: isDeleteCompetitorsLoading } =
    useMutation(DeleteCompetitors, {
      onSuccess: (data) => {
        getCompetitorsData(selectedProject.id);
        getIndividualProject(selectedProject.id);
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const { mutate: getImageAdsMutate, isImageAdsLoading } = useMutation(
    getImageAds,
    {
      onSuccess: (data) => {
        dashboardDispatch({
          type: "SET_IMAGEADS_DATA",
          value: data,
        });
      },
      onError: (e) => console.log(e),
    }
  );
  const { mutate: getTextAdsMutate, isTextAdsLoading } = useMutation(
    getTextAds,
    {
      onSuccess: (data) => {
        dashboardDispatch({
          type: "SET_TEXTADS_DATA",
          value: data,
        });
      },
      onError: (e) => console.log(e),
    }
  );

  const {
    mutate: getKeywordChoicesMutate,
    isLoading: isGetKeywordChoicesLoading,
  } = useMutation(getKeywordChoices, {
    onSuccess: (data) => {
      if (data.length > 0) {
        dashboardDispatch({ type: "SET_KEYWORD_CHOICES", value: data });
      } else {
        getKeywordChoicesMutate({
          projectId: selectedProject.id,
          country: selectedCountry,
        });
      }
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: getCompetitorChoicesMutate,
    isLoading: isGetCompetitorChoicesLoading,
  } = useMutation(getCompetitorChoices, {
    onSuccess: (data) => {
      if (data.length > 0) {
        dashboardDispatch({ type: "SET_COMPETITOR_CHOICES", value: data });
      } else {
        getCompetitorChoicesMutate({
          projectId: selectedProject.id,
          country: selectedCountry,
        });
      }
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: addCompetitorsForTrackingMutate,
    isLoading: isAddCompetitorsForTrackingLoading,
  } = useMutation(addCompetitorsForTracking, {
    onSuccess: (data) => {
      dashboardDispatch({
        type: "SET_COMPETITOR_SETUP_STATUS",
        value: true,
      });
      dashboardDispatch({
        type: "SET_SETUP_STATUS",
        value: false,
      });
      getCompetitorsData(selectedProject.id);
      getIndividualProject(selectedProject.id);
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: addKeywordsForTrackingMutate,
    isLoading: isAddKeywordsForTrackingLoading,
  } = useMutation(addKeywordsForTracking, {
    onSuccess: (data) => {
      dashboardDispatch({
        type: "SET_KEYWORD_SETUP_STATUS",
        value: true,
      });
      dashboardDispatch({
        type: "SET_SETUP_STATUS",
        value: false,
      });
      getTrackingKeywordData(selectedProject.id);
      getIndividualProject(selectedProject.id);
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: getKeywordVolumeChartDataMutate,
    isLoading: isGetKeywordVolumeChartDataLoading,
  } = useMutation(getKeywordVolumeChartData, {
    onSuccess: (data) => {
      dashboardDispatch({ type: "SET_KEYWORD_CHART_DATA", value: data });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: getCompetitorsData, isLoading: isCompetitorsLoading } =
    useMutation(Competitors, {
      onSuccess: (data) => {
        dashboardDispatch({ type: "SET_COMPETITORS", value: data });
        const hasNullCompetingKeywords = data.some(item => item.competingKeywords === null);

        console.log(hasNullCompetingKeywords)
        if(hasNullCompetingKeywords){
          setTimeout(() => {
            getCompetitorsData(selectedProject.id)
          }, 3000);
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const { mutate: getCompetitorChartValue, isLoading: isCompetitorChartValue } =
    useMutation(getCompetitorsChartData, {
      onSuccess: (data) => {
        dashboardDispatch({ type: "SET_COMPETITORS_CHART", value: data.chartData});
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  return {
    getTrackingKeywordData,
    isTrackingKeywordLoading,

    getCompetitorsData,
    isCompetitorsLoading,

    deleteTrackingKeywords,
    isDeleteTrackingKeywordsLoading,

    deleteCompetitors,
    isDeleteCompetitorsLoading,

    getProjectDetails,
    isProjectDetailsLoading,

    getIndividualProject,
    isIndividualProjectLoading,

    getImageAdsMutate,
    isImageAdsLoading,

    getTextAdsMutate,
    isTextAdsLoading,

    getKeywordChoicesMutate,
    isGetKeywordChoicesLoading,

    getCompetitorChoicesMutate,
    isGetCompetitorChoicesLoading,

    addKeywordsForTrackingMutate,
    isAddKeywordsForTrackingLoading,

    addCompetitorsForTrackingMutate,
    isAddCompetitorsForTrackingLoading,

    getTrackingKeywordData,
    isTrackingKeywordLoading,

    getKeywordVolumeChartDataMutate,
    isGetKeywordVolumeChartDataLoading,

    getCompetitorsData,
    isCompetitorsLoading,

    getCompetitorChartValue,
    isCompetitorChartValue,
  };
};
