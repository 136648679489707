import React, {useState, useEffect, useRef} from "react";
import { Container, TrackingDomainsList, CardContainer, WebsiteBasic, WebsiteSnap, WebsiteName, CardHeader, Options, DropDown, AnalyseButton, RemoveButton, DomainCount, Header, AddDomain, HeaderLeft, ResultsContainer } from "./index.styled"
import { Helmet } from "react-helmet";
import DomainCard from "./Components/DomainCard";
import { TrackingProvider, useTracking } from "./context/tracking";
import TreeDots from "../../../media/Dashboard/threedots-horizontal.svg"
import AddDomainModal from "./Components/AddDomainModal"
import AddDomainLoader from './Components/AddDomainLoader'
import Skeleton from "react-loading-skeleton";
import {
    useMutation
} from "@tanstack/react-query"
import {appClient} from '../../../utils/axios-utils'
import { useNavigate } from "react-router-dom"
import FeatureRestrictionPopup from "../../components/featureRestrictionPopup";
import { useAuth } from "../../../context/auth";

const UserTrackingDomains = async () => {
    const config = {
        url: "/track/domain/all",
        method: "GET"
    }

    return appClient(config)
}

const RemoveTrackingDomains = async (data) => {
    const config = {
        url: "/track/domain/remove",
        method: "POST",
        data
    }

    return appClient(config)
}


function useOutsideAlerter(ref, setOpenedDropdown) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenedDropdown("")
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const TrackingPage = () => {
    const { authState, authDispatch } = useAuth()
    const { trackingState, trackingDispatch } = useTracking()
    const [openedDropdown, setOpenedDropdown] = useState('')
    const navigate = useNavigate()


    const { mutate: getTrackingDomains, isLoading: isTrackingDomainsLoading } = useMutation(UserTrackingDomains, {
        onSuccess: (data) => {
            trackingDispatch({type: 'SET_TRACKING_DOMAIN_LIST', value: data})
            trackingDispatch({type: 'SET_ADD_DOMAIN_POPUP', value: false})
            trackingDispatch({type: 'SET_ADD_DOMAIN_LOADER_POPUP', value: false})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: removeDomainTracking, isLoading: isRemoveDomainTrackingLoading } = useMutation(RemoveTrackingDomains, {
        onSuccess: (data) => {
            getTrackingDomains()
        },
        onError: (err) => {
            
        }
    })

    const openDropDown = (data) => {
        if(data == openedDropdown){
            setOpenedDropdown("")
        }else{
            setOpenedDropdown(data)
        }
    }

    const OptionCard = ({domain,idx}) => {
        let wrapperRef = useRef(null)

        useOutsideAlerter(wrapperRef, setOpenedDropdown)

        return <Options>
        <img src={TreeDots} onClick={()=>openDropDown((idx + 1).toString())}/>
        <DropDown open={openedDropdown == (idx+1).toString()}>
            <AnalyseButton onClick={()=>
                // console.log(trackingState.trackingDomains, domain)
                window.open(`https://app.adspyder.io/url-analysis/details?url=${domain.hostname}&searchMode=domain`)
                }>
                Analyse domain
            </AnalyseButton>
            <RemoveButton onClick={()=>removeDomainTracking({domainId: domain.id})}>
                Remove domain
            </RemoveButton>
        </DropDown>
    </Options>
    }

    useEffect(() => {
        if(!authState.features?.isKeywordAnalysisAllowed){
            authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
        }else{
            getTrackingDomains()
        }
    }, [])
    

    return  <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tracking | AdSpyder</title>
                    <meta name="title" content="Tracking | AdSpyder" />
                    <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
                </Helmet>
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <FeatureRestrictionPopup feature={"Domain Tracking"} showCloseBtn={false}/>
                    </ResultsContainer>
                }
                {trackingState.addDomainPopup && <AddDomainModal getTrackingDomains={getTrackingDomains}/>}
                {trackingState.addDomainLoaderPopup && <AddDomainLoader getTrackingDomains={getTrackingDomains}/>}
                <Header>
                    <HeaderLeft>
                        <h1>Domain Tracking</h1>
                        <DomainCount>
                            {/* 5 out of 30 tracked */}
                        </DomainCount>
                        {/* <SearchContainer>
                            <Input
                                placeholder="Search by domain name"
                            />
                            <SearchIconContainer>
                                <img src={SearchIcon}/>
                            </SearchIconContainer>
                        </SearchContainer> */}
                    </HeaderLeft>
                    <AddDomain onClick={()=>{
                    trackingDispatch({type: 'SET_ADD_DOMAIN_POPUP', value: true})
                    }}>
                        + Add Domain
                    </AddDomain>
                </Header>
                <TrackingDomainsList>
                    {isTrackingDomainsLoading ? [...Array(2)].map((domain, idx)=>{
                        return <CardContainer>
                            <Skeleton style={{
                                lineHeight: 19,
                                width: 800,
                                marginBottom: 20
                            }}/> 
                        </CardContainer>
                    })
                : trackingState.trackingDomains.map((domain, idx)=>{
                    return <CardContainer>
                        <CardHeader>
                            <WebsiteBasic>
                                <WebsiteSnap>
                                
                                </WebsiteSnap>
                                <WebsiteName>
                                    <h2>
                                        {domain.hostname}
                                    </h2>
                                    <p>
                                        {domain.hostname}
                                    </p>
                                </WebsiteName>
                            </WebsiteBasic>
                            <OptionCard domain={domain} idx={idx} />
                        </CardHeader>
                        <DomainCard domain={domain}/>
                    </CardContainer>
                })} 
                </TrackingDomainsList>
            </Container>
}

const TrackingProviderWrapper = () => {
    return <TrackingProvider>
        <TrackingPage/>
    </TrackingProvider>
}

export default TrackingProviderWrapper