import styled from 'styled-components';


export const ResultsContainer = styled.div`
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap; */
    column-gap: 16px;
    row-gap: 16px;
    max-width: 1532px;
    position: relative;
        height: ${props => props.noResults ? "70%" : props.restricted ? "100%" : props.resultLoading ? "calc(100vh - 402px)": "unset"};
    background: #006ecc00;
    .loader{
        height: 200px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    ::-webkit-scrollbar {
        width: ${props => props.detailsModal ? "0px" : "8px"};               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: grey;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;
export const ResultsWraper = styled.div`
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    max-width: 1330px; */
`;
export const Card = styled.div`
position: relative;
    border: ${props => props.detailsOpen ? "2px solid #006ECC" : "1px solid #F2F2F2"} ;
    border-radius: 8px;
    background: #FFF;
    width: 371px;
    height: 356px;
   
    .hover-element {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  :hover .hover-element {
    opacity: 1;
    visibility: visible;
  }
`;

export const AdDetailsBody = styled.div`
    overflow-y: scroll;
    height: calc(100vh - 330px);
    position: relative;
    padding-right: 5px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    /* position: relative; */
    padding: 16px;
    padding-bottom: 19px;
    row-gap: 14px;
    .title-wrapper-span{
        font-size: 18px;
        color: #1A0DAB;
        line-height: 27px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* max-width: 420px; */
        font-weight: 400;
        
        display: flex;
        flex-wrap: wrap;
    }

    .title-highlight-bold{
        font-weight: 400;
        font-size: 18px;
        padding: 0px 3px;
    }

    .content-wrapper-span{
        font-size: 14px;
        color: #2E2E2E;
        line-height: 22px;
        /* margin-bottom: 8px; */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2; 
        -webkit-box-orient: vertical;
        height: 44px;
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
    }

    .content-highlight-bold{
        color: #2E2E2E;
        font-weight: 600;
        font-size: 14px;
        padding: 0px 2px;
    }
    
`;

export const CardFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // border-top: 1px solid #F2F2F2;
    padding: 0px 16px 16px 16px;
`;

export const FooterLeft = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FooterRight = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

export const AdDate = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const AdCountries = styled.div`
    display: flex;
    font-size: 14px;
    color: #202124;
    line-height: 22px;
    align-items: center;
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
`;

export const DetailsButton = styled.div`
    border-radius: 4px;
    background: rgb(232, 242, 255);
    padding: 4px 12px;
    line-height: 22px;
    color: #006ECC;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

`;

export const CardsList = styled.div`
    display: flex;
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    /* gap: 12px; */
    // max-width: ${props => props.detailsModal ? "890px": "1330px"};
    /* overflow-y: scroll; */
    /* height: calc(100vh - 245px); */
    /* position: relative; */
    flex-wrap: wrap;
    gap: 16px;
    overflow-y: ${props => props.detailsModal ? "auto": "none"};
    position: relative;
    @media screen and (max-width: 1650px) {
        max-width: ${props => props.detailsModal ? "445px": "1330px"};
    }
    ::-webkit-scrollbar {
        width: ${props => props.detailsModal ? "0px" : "8px"};               /* width of the entire scrollbar */
    }

    body::-webkit-scrollbar-track {
        /* background: red;        color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
        background-color: grey;    /* color of the scroll thumb */
        border-radius: 10px;     
    }
`;

export const CloseDetails = styled.div`
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 15px;
    &:hover{
        background: #E2E2E2;
    }
`;

export const DetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailsContainer = styled.div`
    /* max-width: 724px; */
    position: sticky;
    top: 0px;
    width: 724px;
    /* min-width: 600px; */
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    padding: 20px;
    padding-right: 15px;
    height: fit-content;
    h3{
        color: #707070;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 16px;
    }
`;

export const DetailsHeadline = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const DetailDescription = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const DestinationUrl = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const DateRange = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const AvgPosition = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const TagsContainer = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;

export const CountriesContainer = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;


export const CountriesList = styled.div`
    line-height: 22px;
    color: #707070;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    span{
        background: #F9F9F9;
        border-radius: 8px;
        color: #707070;
        padding: 6px 12px;
    }
`;


export const TagsList = styled.div`
    line-height: 22px;
    color: #707070;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    span{
        background: #F9F9F9;
        border-radius: 8px;
        color: #707070;
        padding: 6px 12px;
    }
`;

export const SearchTermsContainer = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;

export const SearchTermsList = styled.div`
    margin-bottom: 16px;
    margin-top: 12px;
    line-height: 22px;
    color: #707070;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    span{
        background: #F9F9F9;
        border-radius: 8px;
        color: #707070;
        padding: 6px 12px;
        margin-right: 8px;
        margin-bottom: 10px;
    }
`;

export const LandingPageScreenShot = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
        margin-top: 5px;
    }
    color: #707070;
    font-size: 14px;
`;

export const ScreenshotContainer = styled.div`
    margin-top: 12px;
    img{
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
`;


export const FloatingButtonsContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #D4D4D4;
    font-size: 8px;
    border-radius: 4px;
`;

export const Favorite = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    img{
        margin: 25%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
    
`;

export const DropdownIcon = styled.div`
    background: #F9F9F9;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    border-radius: 0px 4px 4px 0px;
    span{
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin: 30%;
    }
    &:hover{
        background: #ECECEC;
    }
    &:active{
        background: #DCEEFF;
    }
`;

export const VideoContainer = styled.div`
    height: 190px;
    border-radius: 12px 12px 0px 0px;
`;

export const DetailsVideoContainer = styled.div`
    margin-bottom: 16px;
`;

export const DetailsImageContainer = styled.div`
    height: 538px;
    background: #F2F2F2;
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 16px;
    img{
        width: 100%; /* Makes the image width 100% of the container width */
        height: 100%; /* Makes the image height 100% of the container height */
        object-fit: contain; 
    }
`;

export const ImageResolution = styled.div`
    position: absolute;
    top: 270px;
    right: 8px;
    background: #050505;
    border-radius: 4px;
    opacity: 0.5;
    color: #FFFFFF;
    font-size: 12px;
    padding: 4px;
`;

export const VideoTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: #2E2E2E;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    // width: 80%;
`;

export const ChannelDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const LogoContainer = styled.div`
    height: 30px;
    img{
        height: 30px;
        width: 30px;
    }
`;

export const ChannelName = styled.p`
    /* margin-left: 8px; */
    font-size: 14px;
    font-weight: 500;
    color: #050505;
`;

export const VideoDescription = styled.p`
    font-size: 14px;
    min-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
color: rgba(28, 28, 28, 0.50);
// white-space: nowrap;
font-family: Inter;
font-weight: 400;
line-height: 20px; 
`;

export const PublishedOn = styled.div`
    color: #202124;
    font-size: 14px;
    label{
        color: #707070;
        font-size: 14px;
        font-weight: 300;
        margin-right: 5px;
    }
`;

export const Category = styled.div`
    margin-bottom: 16px;
    margin-top: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;

export const PublishedOnDetails = styled.div`
    margin-bottom: 16px;
    line-height: 22px;
    label{
        color: #2E2E2E;
        font-size: 14px;
        margin-right: 8px;
    }
    color: #707070;
    font-size: 14px;
`;


export const NoResults = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 200px;
        margin-bottom: 32px;
    }
    h2{
        font-size: 22px;
        font-weight: 500;
        color: #505050;
        text-align: center;
        margin-bottom: 12px;
    }
    p{
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        max-width: 360px;
        text-align: center;
    }
    a{
        font-size: 14px;
        font-weight: 300;
        color: #006ECC;
        text-align: center;
        margin-top: 16px;
    }
`;

export const RequestDataBtn = styled.div`
    height: 100%;
    margin-left: 8px;
    border-radius: 8px;
    background-color: rgb(255, 113, 30);
    opacity: ${props => props.isDisabled ? "0.5": "1"};
    font-size: 14px;
    border: none;
    cursor: pointer;
    padding: 9px 16px;
    margin-top: 20px;
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};
    width: ${props => props.isDisabled ? "auto": "265px"};
    p{
        display: flex;
        max-width: 360px;
        justify-content: center;
        /* text-decoration: ${props => props.isDisabled || props.disable ? "none": "underline"}; */
        /* color: ${props => props.isDisabled ? "#B0B0B0": "#006ECC"}; */
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: #FFF;
        pointer-events: ${props => props.isDisabled ? "none" : "auto"};
        img{
            height: 14px;
            width: 14px;
            margin-left: 4px;
        }
    }
`;

export const CopyImg = styled.img`
    height: 18px;
    margin: 3px 6px;
    cursor: pointer;
`;


export const Header = styled.div`
display: flex;
column-gap: 16px;
`
export const LogoWrapper = styled.div`
border-radius: 16px;
border: 1px solid #ECECEC;
background: #F1F3F4;
display: flex;
padding: 6px;
width: 32px;
height: 32px;
`
export const TitleWrap = styled.div`
display: flex;
flex-direction: column;
row-gap: 2px;
width: 100%;
`
export const Title = styled.div`
overflow: hidden;
color: #2E2E2E;
text-overflow: ellipsis;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const ChannelDiv = styled.div`
overflow: hidden;
color: #598763;
text-overflow: ellipsis;
font-family: Inter;
font-size: 12px;
font-weight: 400;
`
export const CountryWrap = styled.div`
display: flex;
column-gap: 12px;
align-items: center;
`
export const Country = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const HoverCard = styled.div`
    position: absolute;
   width: 100%;
height: 100%;
    // background: #1C1C1C59;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px;
    display: flex;
    justify-content: end;
    align-items: end;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.45) 32.4%, rgba(0, 0, 0, 0.25) 63.4%, rgba(0, 0, 0, 0.10) 100%);
`;

export const AdDetailsButton = styled.div`
    border-radius: 24px;
    background: #FFF;
    width: fit-content;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;

export const SaveAdsButton = styled.div`
    border-radius: 24px;
    background: ${props => props.isSaved ? "#1C1C1C" : "#FF711E"};
    color: #FFF;
    display: flex;
    padding: 10px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 75px;
`;

export const HoverCardWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`;