import React from 'react'
import { Container, EditDiv, EditWrap, ProductDetails, ProductDetailsDiv, ProductDetailsWrap, ProductName, ProductNameWrap, ProductProgress, ProductProgressIn, ProductWrap, RefreshWrap, TrackingDiv, UpdatedDiv, UpdatedWrap } from './index.styled'
import edit from '../../../../../../media/Dashboard/edit.svg'
import refresh from '../../../../../../media/Dashboard/refresh.svg'
import google from '../../../../../../media/Dashboard/google.svg'
import bing from '../../../../../../media/Dashboard/bing.svg'
import { useKeywordTracking } from '../../../context/keywordtracking';
import { countryList } from '../../../../../../utils/maps'
import { CircleFlag } from "react-circle-flags";
import KeywordInputModal from '../../../KeywordTrackingHome/components/KeywordInputModal'
import { ResultsContainer } from '../../../KeywordTrackingHome/index.styled'

import { countriesList } from '../../../../../../utils/countryData'

const Tracking = () => {
  const { keywordTrackingState, keywordTrackingDispatch } = useKeywordTracking()

  const { keywordTrackingData } = keywordTrackingState;

  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        // <FlagLogo>
        <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        // </FlagLogo>
      );
    }
  };

  const findCountryByName = (name) => {
    const foundCountry = countriesList.find((country) => country.name.common === name)
    if(foundCountry) return foundCountry
    return null;
  }

  const togglePopup = () => {
    const countryNameToFind = keywordTrackingState.keywordTrackingData.country;
    let country = {}

    if (countryNameToFind !== "global") {
      const foundCountry = findCountryByName(countryNameToFind);
      country = foundCountry || {}
    }

    keywordTrackingDispatch({
      type: 'SET_KEYWORD_INPUT_LIST', value: [{ keyword: keywordTrackingState.keywordInput, platform: keywordTrackingState.keywordTrackingData.platform, country, platformError: '' }]
    })

    keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: true })
  }

  return (
    <Container>
      {
        keywordTrackingState.keywordInputModalOpen &&
        <ResultsContainer>
          <KeywordInputModal isEditMode={true} />
        </ResultsContainer>
      }

      <EditWrap>
        <TrackingDiv>Tracking Keyword</TrackingDiv>
        <EditDiv onClick={togglePopup}>
          <img src={edit} />
        </EditDiv>
      </EditWrap>
      <ProductWrap>
        <ProductNameWrap>
          <ProductName>{keywordTrackingState.keywordInput}</ProductName>
          <UpdatedWrap>
            <RefreshWrap><img src={refresh} /></RefreshWrap>
            <UpdatedDiv>updated 20hrs ago</UpdatedDiv>
          </UpdatedWrap>
        </ProductNameWrap>
        <ProductDetailsWrap>
          <ProductDetailsDiv>
            <ProductDetails>Status:</ProductDetails>
            <ProductProgressIn>{keywordTrackingData.status ? "Tracking" : "Paused"}</ProductProgressIn>
          </ProductDetailsDiv>
          <ProductDetailsDiv>
            <ProductDetails>Platform:</ProductDetails>
            <ProductProgress><img src={keywordTrackingData.platform == "google" ? google : keywordTrackingData.platform == "bing" ? bing : ""} />{keywordTrackingData.platform}</ProductProgress>
          </ProductDetailsDiv>
          <ProductDetailsDiv>
            <ProductDetails>Country:</ProductDetails>
            <ProductProgress>{getFlagEmoji(keywordTrackingData.country)}{keywordTrackingData.country}</ProductProgress>
          </ProductDetailsDiv>
        </ProductDetailsWrap>
      </ProductWrap>
    </Container>
  )
}

export default Tracking