import React from "react";
import Layout from "./components/Layout"
import { Routes, Route } from "react-router-dom";

import { AccountProvider } from "./context/account";
import Profile from "./components/Profile";
import MySubscription from "./components/MySubscription";
import SearchHistory from "./components/SearchHistory";
import CrawlRequest from "./components/CrawlRequest"
import KeywordSummary from "./components/KeywordsSummary";
import NotFound from "../NotFoundPage";
import CancelPage from "./components/CancelPage";

const Account = () => {

    return <Routes>
                <Route path='*' element={
                        <NotFound/>
                } />
                <Route path="profile" element={
                    <Layout>
                        <Profile />
                    </Layout>
                } />
                <Route path="subscription" element={
                    <Layout>
                        <MySubscription />
                    </Layout>
                } />
                <Route path="search-history" element={
                    <Layout>
                        <SearchHistory />
                    </Layout>
                } />
                <Route path="crawl-requests" element={
                    <Layout>
                        <CrawlRequest />
                    </Layout>
                } />
                <Route path="keywords-summary" element={
                    <Layout>
                        <KeywordSummary />
                    </Layout>
                } />
                {/* <Route path="saved-ads/*" element={
                    <Layout>
                        <SavedAds />
                    </Layout>
                } /> */}
                <Route path="/subscription/cancel" element={
                    <Layout isSideNavDisabled={true}>
                        <CancelPage />
                    </Layout>
                } />
            </Routes>
}

const AccountProviderWrapper = () => {
    return <AccountProvider>
        <Account/>
    </AccountProvider>
}

export default AccountProviderWrapper
