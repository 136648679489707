import React, {useEffect, useRef} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'

// Styles
import {
    Container,
    ScrollableContainer,
    Breadcrumb,
    Header,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'

import {useBingSearch} from "../context/bing"
import {useAuth} from "./../../../../../../context/auth"
import {useSearch} from "../../context/search"
import { getSearchParams, urlPatternValidation,dateFormatter,formatWithCommas } from "../../../../../../utils/helper.js"

// Icons
import Results from './Results'

import { useBingInfo } from '../hooks/useBingInfo.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'

const BingSearchAds = () => {
    const {authState, authDispatch} = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()

    const {bingSearchState, bingSearchDispatch} = useBingSearch()
    const {searchState, searchDispatch} = useSearch()
    const location = useLocation()

    const {searched} = bingSearchState
    const useBingInfoHook = useBingInfo()

    const myRef = useRef(null);

    // set current platform
    useEffect(() => {
        if(location.pathname.includes("bing")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'bing'})
        }
    }, [])
    

    useEffect(() => {
            // console.log('search param updated')
            const searchParamFromState = getSearchParams(location.search)

            const {
                searchMode,
                keywords,
                phraseInput,
                domainUrlInput,
                keywordSearchType,
                urlSearchType,
                searchIn,
                minimumShouldMatch,
                excludedTerm,
                page,
                selectedCountries,
                startDate,
                endDate,
                sortType
            } = searchParamFromState

            bingSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: false})

            if(searchMode){

                if(searchMode == "keyword"){
                    authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})

                    if(keywordSearchType){
                        authDispatch({type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })

                        if(keywordSearchType == "phrase"){

                            if(phraseInput){
                                authDispatch({type: 'SET_PHRASE_INPUT', value: phraseInput})
                            }

                            if(searchIn && searchIn.length > 0){

                                let finalSearchIn = ["title","content"].map(si=>{
                                    if(searchIn.includes(si)){
                                        authDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }else{
                                        authDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }
                                })
                            }
                        }
                        if(keywordSearchType == "broad"){

                            if(keywords && keywords.length > 0){
                                authDispatch({type: 'SET_KEYWORDS', value: keywords.split(",")})
                            }

                            bingSearchDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 }})
                            
                            if(searchIn && searchIn.length > 0){

                                let finalSearchIn = ["title","content"].map(si=>{
                                    if(searchIn.includes(si)){
                                        authDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }else{
                                        authDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: false
                                        }})
                                    }
                                })
                            }
                        }
                        if(excludedTerm){
                            bingSearchDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm})
                        }
                    }
                }

                if (searchMode == "url"){
                    authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})

                    if(urlSearchType){
                        authDispatch({type: 'SET_URL_SEARCHTYPE', value: urlSearchType })

                        if(urlSearchType == "exact"){

                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "domain"){

                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "text"){

                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: domainUrlInput.length > 0}})
                            }
                        }
                    }
                }

                if(selectedCountries && selectedCountries.length > 0){
                    bingSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
                }else{
                    bingSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
                }

                if(startDate){
                    bingSearchDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
                }else{
                    bingSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
                }
    
                if(endDate){
                    bingSearchDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
                }else{
                    bingSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
                }
                
                if(sortType){
                    bingSearchDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                        name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                        type: sortType
                    }})
                }

                if(page){
                    bingSearchDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
                }
            }else{
                bingSearchDispatch({type: 'CLEAR_ALL'})
            }

            // on filter applied
            if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){

                if (searchMode == "keyword") {

                    if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){

                        if (keywordSearchType == "broad") {

                            let fields = searchIn.split(",")
                            let query = keywords.split(",").join(" ")
                            
                            const reqBody = {
                                query,
                                minimumShouldMatch: minimumShouldMatch,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }

                            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                            useBingInfoHook.keywordBoardFilterMutate(reqBody)

                        } else if (keywordSearchType == "phrase") {

                            let fields = searchIn.split(",")
                            
                            const reqBody = {
                                query: phraseInput,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }

                            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                            useBingInfoHook.keywordPhraseFilterMutate(reqBody)

                        }
                    }
                } else if (searchMode == "url") {

                    if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){

                        if (urlSearchType == "domain") {
                            
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }

                            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                            useBingInfoHook.urlDomainFilterMutate(reqBody)

                        } else if (urlSearchType == "text") {

                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }

                            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                            useBingInfoHook.urlTextFilterMutate(reqBody)

                        } else if (urlSearchType == "exact") {
                            
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }

                            bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                            useBingInfoHook.urlExactFilterMatchMutate(reqBody)

                        }
                    }
                }
            }else{
                // on no filter applied
                    if (searchMode == "keyword") {

                        if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){

                            if (keywordSearchType == "broad") {

                                let fields = searchIn.split(",")
                                let query = keywords.split(",").join(" ")
                        
                                const reqBody = {
                                    query,
                                    minimumShouldMatch: minimumShouldMatch,
                                    searchIn: fields,
                                    exclude: excludedTerm,
                                    page: page
                                }

                                bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                                useBingInfoHook.keywordBroadMutate(reqBody)

                            } else if (keywordSearchType == "phrase") {

                                let fields = searchIn.split(",")
                                
                                const reqBody = {
                                    query: phraseInput,
                                    searchIn: fields,
                                    exclude: excludedTerm,
                                    page: page
                                }

                                bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                                useBingInfoHook.keywordPhraseMutate(reqBody)

                            }
                        }
                    } else if (searchMode == "url") {

                        if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){

                            if (urlSearchType == "domain") {

                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page
                                }

                                bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                                useBingInfoHook.urlDomainMutate(reqBody)

                            } else if (urlSearchType == "text") {
                        
                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page
                                }

                                bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                                useBingInfoHook.urlTextMutate(reqBody)

                            } else if (urlSearchType == "exact") {

                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page
                                }

                                bingSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                                useBingInfoHook.urlExactMutate(reqBody)
                                
                            }
                        }
                    }
            }

    }, [location.search])


    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        bingSearchDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }

    // return <Container ref={myRef}>
    //         <SearchContainer>
    //             <ResultPageSearch/>
    //         </SearchContainer>
    //         <ScrollableContainer>
    //         {
    //         !searchState.limitExceeded && < Filters />
    //         }
    //         {
    //         bingSearchState.ads && bingSearchState.ads.length > 0 && (
    //         <AdsCount>
    //             <div><p>
    //                 <span>Bing Search ads{" "}</span>
    //                 - {(bingSearchState.currentPageNo - 1) * 20 != 0 ? (bingSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((bingSearchState.currentPageNo - 1) * 20) + 20) > bingSearchState.totalAds ? bingSearchState.totalAds : ((bingSearchState.currentPageNo - 1) * 20) + 20} of <span>{
    //                     bingSearchState.totalAds
    //                 }</span>
    //                     {" "}results 
    //             </p></div>
    //         </AdsCount>
    //             )
    //         }
    //             <Results/> {
    //         !searchState.limitExceeded && bingSearchState.ads && bingSearchState.ads.length > 0 && <Pagination/>
    //         } 
    // </ScrollableContainer>
    // </Container>
    return <Container ref={myRef}>
        {
                <>
                    <Breadcrumb>
                        {"Overview > "} <span>{authState.searchMode === "keyword" ? "Text Search in Ad library" : "Url Search in Ad library"}</span>
                    </Breadcrumb>
                    <Header>
                        <HeaderLeft>
                            <h2>Bing Search ads</h2>
                        </HeaderLeft>
                        <HeaderRight>
                            <TotalAdsFound>
                                <label>Total ads found :</label>
                                <span>
                                    {formatWithCommas(bingSearchState.totalAds)}
                                </span>
                            </TotalAdsFound>
                            <SortType />
                            <MajorFilters />
                        </HeaderRight>
                    </Header>
                    <AppliedFilters>
                        {bingSearchState.selectedCountries.length > 0 && <AppliedFilterItem>
                            <p>{bingSearchState.selectedCountries.length == 1 ? bingSearchState.selectedCountries[0] : bingSearchState.selectedCountries.length > 1 ? bingSearchState.selectedCountries[0] + " + " + (
                                bingSearchState.selectedCountries.length - 1
                            ) : "no country"}</p>
                            <Close onClick={()=>ClearCountry()}>
                                x 
                            </Close>
                        </AppliedFilterItem>}
                        {bingSearchState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                            <p>{bingSearchState.selectedSortType.name}</p>
                            <Close onClick={()=>ClearSortType()}>
                                x 
                            </Close>
                        </AppliedFilterItem>}
                        {bingSearchState.dateFilterType.length > 0 && <AppliedFilterItem>
                            <p>{bingSearchState.dateFilterType}</p>
                            <Close onClick={()=>ClearDateFilter()}>
                                x 
                            </Close>
                        </AppliedFilterItem>}
                        {/* {(bingSearchState.selectedCta?.length > 0 || bingSearchState.selectedTone?.length > 0 || bingSearchState.sentimentFilter?.length > 0 || bingSearchState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                            <p>Advanced filters</p>
                            <Close onClick={()=>OpenAdvancedFilter()}>
                                x 
                            </Close>
                        </AppliedFilterItem>} */}
                    </AppliedFilters>
                    <ScrollableContainer>
                        <Results />
                        <Footer>
                            {(!authDispatch.limitExceeded && bingSearchState.ads && !bingSearchState.loadingResults && bingSearchState.ads.length > 0) && <TotalResults>
                                <p>Showing {(bingSearchState.currentPageNo - 1) * 20 != 0 ? (bingSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((bingSearchState.currentPageNo - 1) * 20) + 20) > bingSearchState.totalAds ? bingSearchState.totalAds : ((bingSearchState.currentPageNo - 1) * 20) + 20} of <span>{
                                    bingSearchState.totalAds
                                }</span>
                                results</p>
                            </TotalResults>}
                            {!authDispatch.limitExceeded && bingSearchState.ads && !bingSearchState.loadingResults && bingSearchState.ads.length > 0 && <Pagination />}
                        </Footer>
                    </ScrollableContainer>
                </>
        }
    </Container>
}

export default BingSearchAds
