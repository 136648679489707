import { AddIcon, Country, CountryContainer, InputContainer, KeywordInput, LocationInputContainer, LocationSelectContainer, LocationSuggestionContainer, SearchIcon, SelectedLocationItem, SelectedLocationList, SelectedLocationsConatiner, SuggestionContainer, SuggestionElement, SuggestionLabel, TargetLocationContainer } from "./TargetLocation.styled";
import searchIcon from "../../assets/search.svg"
import add from "../../assets/add.svg"
import { useRef, useState } from "react";
import { useTextAdGenerator } from "../../context/textAdGeneration";
import closeIcon from "../../../../../../media/Dashboard/CloseDark.svg"
import { useMutation } from "@tanstack/react-query";
import { appClient } from "../../../../../../utils/axios-utils";

function TargetLocation() {
    const locationRef = useRef(null)
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [inputFocussed, setInputFocussed] = useState(false)

    const getLocationSuggestion = async (data) => {
        const config = {
            url: `/admanagement/get-location/${data.query}`,
            method: "GET"
        }

        return appClient(config)
    }
    const { mutate: getLocationSuggestionMutate, isLoading: isGetingLocationSuggestion } = useMutation(getLocationSuggestion, {
        onSuccess: (data) => {
          setSuggestions(data)
        },
        onError: (err) => {
            // setSuggestions(suggestionLocationData.slice(0,locationSearchInput.length < 6 ? locationSearchInput.length : 6))
        }
    })

    const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator()
    const countrySuggestion = () => {
      const countries = [{
          "name": "Germany",
          "criteriaId": "2276"
        },  {
          "name": "Canada",
          "criteriaId": "2124"
        },
      ]
      const handleCountrySelection = (country) => {
        const isCountrySelected = textAdGeneratorState.targetLocation.some(
            (location) => location.name === country.name
          );
        if(!isCountrySelected){
          setInputValue('');
          setSuggestions([]);
          textAdGeneratorDispatch({
            type: 'SET_TARGET_LOCATION',
            value: [...textAdGeneratorState.targetLocation, country],
          });
        }else{

        }
      } 
      console.log('targetlocation', textAdGeneratorState.targetLocation)
      return countries.map((country) => {
          return (
              <CountryContainer onClick={() => handleCountrySelection(country)} >
                  <Country>{country.name}</Country>
                  <AddIcon src={add} alt="add" />
              </CountryContainer>
          )
      })
    }
    
    const handleInputChange = (e) => {
        const value = e.target.value.toLowerCase();
        setInputValue(value);
    
        if (value.length >= 3) {
          // const filteredSuggestions = countryArray.filter((country) =>
          //   country.country.toLowerCase().includes(value)
          // );
          // setSuggestions(filteredSuggestions);
          getLocationSuggestionMutate({query: value})
        } else {
          setSuggestions([]);
        }
      };
    // const removeSelectedLocation = (location) => {
    //     let filteredLocationList = textAdGeneratorState.locationsList.filter((loc)=>{
    //         return loc != location
    //     })

    //     textAdGeneratorDispatch({type: "SET_LOCATIONS_LIST", value: filteredLocationList})
    // }
    const handleSuggestionClick = (country) => {
        const isAlreadySelected = textAdGeneratorState.targetLocation.some(
            (location) => location.name === country.name
          );
          if (!isAlreadySelected) {
            setInputValue('');
            setSuggestions([]);
            textAdGeneratorDispatch({
              type: 'SET_TARGET_LOCATION',
              value: [...textAdGeneratorState.targetLocation, country],
            });
          } else {
          }
      };
      const handleRemoveLocation = (location) => {
        const filteredList = textAdGeneratorState.targetLocation.filter(
          (loc) => loc !== location
        );
        textAdGeneratorDispatch({
          type: 'SET_TARGET_LOCATION',
          value: filteredList,
        });
      };
    // const onLocationSuggestionClick = (location) => {
    //     textAdGeneratorDispatch({type: "SET_OPENED_LOCATION_SUGGESTION", value: false})
    //     textAdGeneratorDispatch({type: "SET_LOCATIONS_LIST", value: [...textAdGeneratorState.locationsList, location]})
    // }
    
    return ( 
        <TargetLocationContainer>
            {/* <LocationInputContainer ref={locationRef}  placeholder="Search city, state or country" isActive={locationRef?.current?.value !== ""} onChange = {handleTargetLocationInputChange} value={textAdGeneratorState.targetLocation} />
            <SearchIcon src={search} alt="search" /> */}
            <LocationSelectContainer>
              <label>Location</label>
              <LocationInputContainer>
                <InputContainer isFocussed={inputFocussed} >
                    <SearchIcon src={searchIcon} alt="Search" />
                    <KeywordInput
                        ref={locationRef}
                        value={inputValue}
                        placeholder="Search city, state or country"
                        onChange={handleInputChange}
                        onFocus={() => setInputFocussed(true)}
                        onBlur={() => setInputFocussed(false)}
                    />
                </InputContainer>
                {inputValue.length > 0 && 
                  <LocationSuggestionContainer>
                    {inputValue.length < 3 
                      ? <SuggestionElement>Enter atleast 3 characters to show suggestion</SuggestionElement>
                      : suggestions.length !== 0
                        ?
                          suggestions.map((suggestion) => (
                            <SuggestionElement
                              key={suggestion.criteriaId}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion.name}
                            </SuggestionElement>
                          ))
                        : !isGetingLocationSuggestion && <SuggestionElement>No results found</SuggestionElement>
                    }
                  </LocationSuggestionContainer>
                }
              </LocationInputContainer>

              {textAdGeneratorState.targetLocation.length > 0 && (
                <SelectedLocationsConatiner>
                  <label>Selected locations:</label>
                  <SelectedLocationList>
                    {textAdGeneratorState.targetLocation.map((location) => (
                      <SelectedLocationItem key={location.criteriaId}>
                        {location.name}{' '}
                        <img
                          src={closeIcon}
                          onClick={() => handleRemoveLocation(location)}
                        />
                      </SelectedLocationItem>
                    ))}
                  </SelectedLocationList>
                </SelectedLocationsConatiner>
              )}
            </LocationSelectContainer>
            {!textAdGeneratorState.targetLocation.length && (
              <SuggestionContainer>
                <SuggestionLabel>Suggestions:</SuggestionLabel>
                {countrySuggestion()}
            </SuggestionContainer>
            )}
            
        </TargetLocationContainer>
     );
}

export default TargetLocation;