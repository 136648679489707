import React, { useState, useMemo, useEffect } from "react";
import { useAuth } from "../../../../../../context/auth";
import {
  Body,
  Completed,
  CompletedArrow,
  CompletedCircle,
  Container,
  Details,
  Greeting,
  Header,
  Info,
  Logo,
  Sno,
  Task,
  TaskDescription,
  TaskTitle,
  TaskWalkthrough,
  Text,
  Title,
} from "./index.styled";

import logo from "./assets/setup-title.svg";
import completedLine from "./assets/completed-line.svg";
import tick from "./assets/tick.svg";

import statics from "./statics";
import AddNew from "../AddNew";
import { useDashboard } from "../../../context/dashboard";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";

const DashboardSetup = () => {
  const {
    authState: { username },
  } = useAuth();
  const {
    title,
    tasks,
    keywordSelectingInstruction,
    competitorSelectingInstruction,
  } = statics;
  const [currentTask, setCurrentTask] = useState(tasks[1]);
  const {
    getKeywordChoicesMutate,
    getCompetitorChoicesMutate,
    addKeywordsForTrackingMutate,
    addCompetitorsForTrackingMutate,
  } = useDashboardInfo();
  const {
    dashboardState: {
      selectedCountry,
      keywordChoices,
      competitorChoices,
      isKeywordsSetupDone,
      isCompetitorsSetupDone,
      selectedProject,
    },
    dashboardDispatch,
  } = useDashboard();
  const handleSetup = (chosenChoicesIndices, userSelectedList) => {
    dashboardDispatch({
      type: "SET_SETUP_STATUS",
      value: true,
    });
    if (!isKeywordsSetupDone) {
      const keywordData = chosenChoicesIndices.map((index) => {
        return {
          keyword: keywordChoices[index].keyword,
          source: "suggested",
          country: selectedCountry,
        };
      });

      const userEnteredKeywordData = userSelectedList.map((data) => {
        return {
          keyword: data,
          source: "user",
          country: selectedCountry,
        };
      });

      addKeywordsForTrackingMutate({
        projectId: selectedProject.id,
        keywords: [...keywordData, ...userEnteredKeywordData],
      });
      return;
    }

    const competitorsData = chosenChoicesIndices.map((index) => {
      return {
        domain: competitorChoices[index].domainName,
        source: "suggested",
      };
    });
    const userEnteredCompetitorData = userSelectedList.map((data) => {
      return {
        domain: data,
        source: "user",
        country: selectedCountry,
      };
    });
    addCompetitorsForTrackingMutate({
      projectId: selectedProject.id,
      domains: [...competitorsData, ...userEnteredCompetitorData],
    });
  };
  const handleTaskSelection = (selectedTask) => {
    setCurrentTask(selectedTask);
  };

  useEffect(() => {
    if (selectedCountry) {
      const data = {
        projectId: selectedProject.id,
        country: selectedCountry,
      };
      getKeywordChoicesMutate(data);
      getCompetitorChoicesMutate(data);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!isKeywordsSetupDone) {
      setCurrentTask(tasks[1]);
      return;
    }
    setCurrentTask(tasks[2]);
  }, [isKeywordsSetupDone, isCompetitorsSetupDone]);

  return (
    <Container>
      <Header>
        <Greeting>{`Welcome ${username}!`}</Greeting>
        <Title>
          <Logo src={logo} draggable="false" />
          <Text>
            Setup your <span>{selectedProject.projectName}</span> Dashboard
          </Text>
        </Title>
      </Header>
      <Body>
        <TaskWalkthrough>
          {tasks.map((task) => {
            const { sno, title, description } = task;
            const isCompleted = task.sno < currentTask.sno;
            return (
              <Task
                key={sno}
                isFirst={
                  sno === 1
                } /* onClick={() => handleTaskSelection(task)} */
              >
                <Sno>{`Task ${sno}`}</Sno>
                <Details sno={task.sno} currentTaskSno={currentTask.sno}>
                  <CompletedCircle isCompleted={isCompleted}>
                    {isCompleted ? (
                      <Completed src={tick} draggable={false} />
                    ) : null}
                  </CompletedCircle>
                  <Info>
                    <TaskTitle>{title}</TaskTitle>
                    <TaskDescription>{description}</TaskDescription>
                  </Info>
                  <CompletedArrow
                    src={completedLine}
                    draggable={false}
                    isCompleted={isCompleted}
                  />
                </Details>
              </Task>
            );
          })}
        </TaskWalkthrough>
        {!isKeywordsSetupDone ? (
          <AddNew
            choices={keywordChoices}
            selectingInstruction={keywordSelectingInstruction}
            onSetup={handleSetup}
            task={tasks[1]}
          />
        ) : (
          <AddNew
            choices={competitorChoices}
            selectingInstruction={competitorSelectingInstruction}
            forKeyword={false}
            onSetup={handleSetup}
            task={tasks[2]}
          />
        )}
      </Body>
    </Container>
  );
};

export default DashboardSetup;
