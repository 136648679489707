import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { useGoogleEcomSearch } from "../context/googleecom";

const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: `/search/google-ecom/keyword/broad/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: `/search/google-ecom/keyword/phrase/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainFilterMatch = async (data) => {
    const config = {
        url: `/search/google-ecom/url/domain/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextFilterMatch = async (data) => {
    const config = {
        url: `/search/google-ecom/url/text/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    const config = {
        url: `/search/google-ecom/url/exact/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/google-ecom/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/google-ecom/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainMatch = async (data) => {
    const config = {
        url: "/search/google-ecom/url/domain",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactMatch = async (data) => {
    const config = {
        url: "/search/google-ecom/url/exact",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextMatch = async (data) => {
    const config = {
        url: "/search/google-ecom/url/text",
        method: "POST",
        data
    }

    return appClient(config)
}


export const useGoogleEcomInfo = () => {
    const {googleEcomSearchState, googleEcomSearchDispatch} = useGoogleEcomSearch()
    const {searchState, searchDispatch} = useSearch()

    const userInfoHook = useGetUserInfo()
    const { mutate: keywordBoardFilterMutate, isLoading: isKeywordBoardFilterLoading } = useMutation(keywordBoardFilterMatch, {
        onSuccess: (data) => {
            googleEcomSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleEcomSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: keywordPhraseFilterMutate, isLoading: isKeywordPhraseFilterLoading } = useMutation(keywordPhraseFilterMatch, {
        onSuccess: (data) => {
            googleEcomSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleEcomSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            googleEcomSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleEcomSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlTextFilterMutate, isLoading: isUrlTextFilterLoading } = useMutation(urlTextFilterMatch, {
        onSuccess: (data) => {
            googleEcomSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleEcomSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            googleEcomSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleEcomSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordBroadMutate, isLoading: isKeywordBroadLoading} = useMutation(keywordBroadMatch, {
        onSuccess: (data) => {
            googleEcomSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            googleEcomSearchDispatch({type: "SET_ADS", value: data.documents})
            googleEcomSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordPhraseMutate, isLoading: isKeywordPhraseLoading} = useMutation(keywordPhraseMatch, {
        onSuccess: (data) => {
            googleEcomSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            googleEcomSearchDispatch({type: "SET_ADS", value: data.documents})
            googleEcomSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }

            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: urlDomainMutate, isLoading: isUrlDomainLoading} = useMutation(urlDomainMatch, {
        onSuccess: (data) => {
            // googleEcomSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
    
            // Update search results
            googleEcomSearchDispatch({type: "SET_ADS", value: data.documents})
            googleEcomSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlExactMutate, isLoading: isUrlExactLoading} = useMutation(urlExactMatch, {
        onSuccess: (data) => {
            // googleEcomSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            googleEcomSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            googleEcomSearchDispatch({type: "SET_ADS", value: data.documents})
            googleEcomSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlTextMutate, isLoading: IsUrlTextLoading} = useMutation(urlTextMatch, {
        onSuccess: (data) => {
            // googleEcomSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            googleEcomSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            googleEcomSearchDispatch({type: "SET_ADS", value: data.documents})
            googleEcomSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleEcomSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleEcomSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleEcomSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleEcomSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

  return {
    keywordBoardFilterMutate,
    isKeywordBoardFilterLoading,
    keywordPhraseFilterMutate,
    isKeywordPhraseFilterLoading,
    keywordBroadMutate,
    isKeywordBroadLoading,
    keywordPhraseMutate,
    isKeywordPhraseLoading,
    urlDomainFilterMutate,
    isUrlDomainFilterLoading,
    urlTextFilterMutate,
    isUrlTextFilterLoading,
    urlExactFilterMatchMutate,
    isUrlExactFilterLoading,
    urlDomainMutate,
    isUrlDomainLoading,
    urlExactMutate,
    isUrlExactLoading,
    urlTextMutate,
    IsUrlTextLoading
  };
};
