import React, {useEffect} from 'react'

import {Navigate, Outlet, useLocation} from "react-router-dom"

import {useAuth} from '../../context/auth'

const ProtectedPlansLayout = () => {
    const {authState} = useAuth()
    const location = useLocation()

    // console.log("authState", authState)

    if(!authState.isAuthenticated){
        return <Navigate to={`/`} state={{ from: location }} replace/>
    }else if(authState.old && !authState.notified){
        return <Navigate to={`/welcome`} state={{ from: location }} replace/>
    }else if(!authState.emailVerified){
        return <Navigate to={'/confirmemail'} state={{ from: location }} replace />
    }else if(!authState.postloginSurvey){
        return <Navigate to={`/loginsurvey`} state={{ from: location }} replace/>
    }else{
        return <Outlet/>
    }
}

export default ProtectedPlansLayout

