import React from 'react'
import DoughnutChart from './DoughnutChart/index'
import { AdDistributionWrap,AdDistributionDiv, AdProgressName, AdProgressDiv, AdProgressWrap, AdProgressBar, AdDistributionContainer, DoughnutWrapper, DoughnutContainer, AdProgressPercentage, AdGradeWrap, GoogleProgress } from './index.styled'
import greenArrow from '../../../../../../media/Dashboard/upGreenArrow.svg'
import { useKeywordTracking } from '../../../context/keywordtracking';
import Skeleton from 'react-loading-skeleton';
import Tooltip from 'rc-tooltip';
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const AdDistribution = ({isTableDataLoading }) => {
    const {keywordTrackingState, keywordTrackingDispatch} = useKeywordTracking()
    // console.log(keywordTrackingState.adDistribution,'keywordTrackingState.adDistribution.adDistribution')
    const colorsArray = [
        "#1679db",
        "#e24593",
        "#2802fb",
        "#00879d",
        "#a158e0"
    ]

    return (
    <AdDistributionWrap>
        <AdDistributionDiv>Ad Distribution by Platform <Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Total ads found in each Platform</span>}>
                <img src={helpCircle}/>     
                    </Tooltip> </AdDistributionDiv>
        <AdDistributionContainer>
            <DoughnutWrapper>
            {isTableDataLoading ? 
                <Skeleton style={{
                    lineHeight: 13,
                    width: 360,
                }}/> :
                <DoughnutContainer>
                    <DoughnutChart />
                </DoughnutContainer>
}
            </DoughnutWrapper>
            <AdProgressWrap>
            {isTableDataLoading ? 
             <Skeleton style={{
                lineHeight: 13,
                width: 360,
              }}/>
                :
              keywordTrackingState?.adDistribution?.length > 0 &&
                keywordTrackingState?.adDistribution.map((item, idx)=>(
                <AdProgressDiv>
                    <AdProgressName>{item.key}</AdProgressName>
                    <AdProgressBar>
                    <GoogleProgress color={colorsArray[idx]} id="file" value={item?.percentage} max="100" ></GoogleProgress> 
                    <AdProgressPercentage>{item?.percentage}%</AdProgressPercentage>
                    {item?.increase > 0 && <AdGradeWrap><img src={greenArrow} />{Math.round(item?.increase)}</AdGradeWrap>}
                    </AdProgressBar>
                </AdProgressDiv>
            ))
            }
                {/* <AdProgressDiv>
                    <AdProgressName>Youtube</AdProgressName>
                        <AdProgressBar >
                        <YoutubeProgress id="file" value="19.5" max="100" ></YoutubeProgress> 
                        <AdProgressPercentage>19.5%</AdProgressPercentage>
                    </AdProgressBar>
                </AdProgressDiv>
                <AdProgressDiv>
                    <AdProgressName>Bing</AdProgressName>
                        <AdProgressBar >
                        <BingProgress id="file" value="60" max="100" ></BingProgress> 
                        <AdProgressPercentage>54%</AdProgressPercentage>
                    </AdProgressBar>
                </AdProgressDiv>
                <AdProgressDiv>
                    <AdProgressName>Google e-com</AdProgressName>
                        <AdProgressBar >
                        <GoogleEcomProgress id="file" value="70" max="100" ></GoogleEcomProgress> 
                        <AdProgressPercentage>87%</AdProgressPercentage>
                    </AdProgressBar>
                </AdProgressDiv>
                <AdProgressDiv>
                    <AdProgressName>Bing e-com</AdProgressName>
                        <AdProgressBar >
                        <BingEcomProgress id="file" value="50" max="100" ></BingEcomProgress> 
                        <AdProgressPercentage>37%</AdProgressPercentage>
                    </AdProgressBar>
                </AdProgressDiv>
                <AdProgressDiv>
                    <AdProgressName>Amazon</AdProgressName>
                        <AdProgressBar >
                        <AmazonProgress id="file" value="40" max="100" ></AmazonProgress> 
                        <AdProgressPercentage>64%</AdProgressPercentage>
                    </AdProgressBar>
                </AdProgressDiv> */}
            </AdProgressWrap>
        </AdDistributionContainer>
    </AdDistributionWrap>
  )
}

export default AdDistribution