import { useAuth } from "../../../../../../context/auth";
import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGoogleSearch } from "../context/google";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { LanguageFilter } from "../../../../../../utils/filterLang";

const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: "/search/google/keyword/broad/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: "/search/google/keyword/phrase/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainFilterMatch = async (data) => {
    const config = {
        url: "/search/google/url/domain/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextFilterMatch = async (data) => {
    const config = {
        url: "/search/google/url/text/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    const config = {
        url: "/search/google/url/exact/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/google/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/google/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainMatch = async (data) => {
    const config = {
        url: "/search/google/url/domain",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactMatch = async (data) => {
    const config = {
        url: "/search/google/url/exact",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextMatch = async (data) => {
    const config = {
        url: "/search/google/url/text",
        method: "POST",
        data
    }

    return appClient(config)
}

const getCrawledData = async (data) => {
    console.log('DBUG || getcrawleddata', data)
    const config = {
        url: "/search/google/url/domain/realtime",
        method: "POST",
        data
    }

    return appClient(config)
}

export const useGoogleInfo = () => {
    const { googleSearchState,googleSearchDispatch} = useGoogleSearch()
    const {searchState, searchDispatch} = useSearch()
    const { authState, authDispatch } = useAuth()

    const userInfoHook = useGetUserInfo()

    const { mutate: keywordBoardFilterMutate, isLoading: isKeywordBoardFilterLoading } = useMutation(keywordBoardFilterMatch, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }

            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }

            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: keywordPhraseFilterMutate, isLoading: isKeywordPhraseFilterLoading } = useMutation(keywordPhraseFilterMatch, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordBroadMutate, isLoading: isKeywordBroadLoading} = useMutation(keywordBroadMatch, {
        onSuccess: (data) => {
            googleSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            googleSearchDispatch({type: "SET_ADS", value: data.documents})
            googleSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
            // Update advanced filter data
            googleSearchDispatch({type: "SET_AVAILABLE_CTA", value: data.cta})
            googleSearchDispatch({type: "SET_AVAILABLE_TONE", value: data.linguisticTone})
            googleSearchDispatch({type: "SET_IS_ADVANCED_FILTER_AVAILABLE", value: data.advancedFilterPresent})
            googleSearchDispatch({type: "SET_AVAILABLE_LANGUAGE", value: LanguageFilter(data.languages)})



            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError:  ((e)=>{
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
        })
    })

    const {mutate: keywordPhraseMutate, isLoading: isKeywordPhraseLoading} = useMutation(keywordPhraseMatch, {
        onSuccess: (data) => {
            // console.log(data)
            googleSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            googleSearchDispatch({type: "SET_ADS", value: data.documents})
            googleSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            // Update advanced filter data
            googleSearchDispatch({type: "SET_AVAILABLE_CTA", value: data.cta})
            googleSearchDispatch({type: "SET_AVAILABLE_TONE", value: data.linguisticTone})
            googleSearchDispatch({type: "SET_IS_ADVANCED_FILTER_AVAILABLE", value: data.advancedFilterPresent})
            googleSearchDispatch({type: "SET_AVAILABLE_LANGUAGE", value: LanguageFilter(data.languages)})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        }
        ,
        onError:  ((e)=>{
            // googleSearchDispatch({type: 'UPDATE_LIMIT_EXCEEDED', value: true})
            // googleSearchDispatch({type: 'UPDATE_SEARCH_ERROR', value: getSearchErrCode(e)})

            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
        })
    })

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlTextFilterMutate, isLoading: isUrlTextFilterLoading } = useMutation(urlTextFilterMatch, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: "SET_ADS", value: data.documents })
            googleSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: urlDomainMutate, isLoading: isUrlDomainLoading} = useMutation(urlDomainMatch, {
        onSuccess: (data) => {
            // googleSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            
            // Update search results
            googleSearchDispatch({type: "SET_ADS", value: data.documents})
            googleSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

                        
            // Update advanced filter data
            googleSearchDispatch({type: "SET_AVAILABLE_CTA", value: data.cta})
            googleSearchDispatch({type: "SET_AVAILABLE_TONE", value: data.linguisticTone})
            googleSearchDispatch({type: "SET_IS_ADVANCED_FILTER_AVAILABLE", value: data.advancedFilterPresent})
            googleSearchDispatch({type: "SET_AVAILABLE_LANGUAGE", value: LanguageFilter(data.languages)})

            
            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: urlExactMutate, isLoading: isUrlExactLoading} = useMutation(urlExactMatch, {
        onSuccess: (data) => {
            // googleSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            googleSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            googleSearchDispatch({type: "SET_ADS", value: data.documents})
            googleSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
            
            // Update advanced filter data
            googleSearchDispatch({type: "SET_AVAILABLE_CTA", value: data.cta})
            googleSearchDispatch({type: "SET_AVAILABLE_TONE", value: data.linguisticTone})
            googleSearchDispatch({type: "SET_IS_ADVANCED_FILTER_AVAILABLE", value: data.advancedFilterPresent})
            googleSearchDispatch({type: "SET_AVAILABLE_LANGUAGE", value: LanguageFilter(data.languages)})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: urlTextMutate, isLoading: IsUrlTextLoading} = useMutation(urlTextMatch, {
        onSuccess: (data) => {
            // googleSearchDispatch({type: "CLEAR_FILTERS"}) // Clear filter before main search data updated
            googleSearchDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            googleSearchDispatch({type: "SET_ADS", value: data.documents})
            googleSearchDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
            
            // Update advanced filter data
            googleSearchDispatch({type: "SET_AVAILABLE_CTA", value: data.cta})
            googleSearchDispatch({type: "SET_AVAILABLE_TONE", value: data.linguisticTone})
            googleSearchDispatch({type: "SET_IS_ADVANCED_FILTER_AVAILABLE", value: data.advancedFilterPresent})
            googleSearchDispatch({type: "SET_AVAILABLE_LANGUAGE", value: LanguageFilter(data.languages)})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})


        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: getCrawledDataMutate, isLoading: isGetCrawledDataLoading} = useMutation(getCrawledData, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: "SET_ADS", value: data.documents})
            googleSearchDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            googleSearchDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                googleSearchDispatch({ type: "UPDATE_SEARCHED", value: false })
            }

            if(data.totalNumberOfDocuments == 0){
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                googleSearchDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                googleSearchDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }

            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            authDispatch({type: 'SET_IS_CRAWL_REQUEST_PAGE', value: true})

        },
        onError: (err) => {
            googleSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

  return {
    keywordBoardFilterMutate,
    isKeywordBoardFilterLoading,
    keywordPhraseFilterMutate,
    isKeywordPhraseFilterLoading,
    keywordBroadMutate,
    isKeywordBroadLoading,
    keywordPhraseMutate,
    isKeywordPhraseLoading,
    urlDomainFilterMutate,
    isUrlDomainFilterLoading,
    urlTextFilterMutate,
    isUrlTextFilterLoading,
    urlExactFilterMatchMutate,
    isUrlExactFilterLoading,
    urlDomainMutate,
    isUrlDomainLoading,
    urlExactMutate,
    isUrlExactLoading,
    urlTextMutate,
    IsUrlTextLoading,
    getCrawledDataMutate,
    isGetCrawledDataLoading
  };
};
