import React from "react";
import {
  AddProject,
  DashboardHeadWrap,
  ProjectCreationWrap,
  ProjectName,
  ProjectNameWrap,
  TotalProject,
} from "./index.styled";
import analysisImg from "../../../../assests/analysis.svg";
import whitePlus from "../../../../assests/whitePlus.svg";
import Statics from "../../_static";
import { useDashboard } from "../../../../context/dashboard";

const DashboardHeader = () => {
  const { project, totalProjectCreated } = Statics;
  const {
    dashboardState: { projectList },
    dashboardDispatch,
  } = useDashboard();
  return (
    <DashboardHeadWrap>
      <ProjectNameWrap>
        <ProjectName>
          <img src={analysisImg} />
          <h2>{project} </h2>
        </ProjectName>
        <p>View a comprehensive list of all your ongoing projects</p>
      </ProjectNameWrap>
      <ProjectCreationWrap>
        <TotalProject>
          <p>
            Total projects created -{" "}
            <span>{projectList && projectList.length}</span>
          </p>
        </TotalProject>
        <AddProject
          onClick={() =>
            dashboardDispatch({
              type: "SET_POPUP_ACTIVE",
              value: true,
            })
          }
        >
          <img src={whitePlus} />
          <p>Add a Project</p>
        </AddProject>
      </ProjectCreationWrap>
    </DashboardHeadWrap>
  );
};

export default DashboardHeader;
