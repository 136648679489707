import React, { useState, useMemo, useEffect } from "react";
import PlusWhiteIcon from "../../../../../../media/Dashboard/plus-white.svg";
import TrashIcon from "../../../../../../media/Dashboard/trash-black.svg";
import DownArrowIcon from "../../../../../../media/Dashboard/arrow-down.svg"
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
} from "@tanstack/react-table";
import ArrowUpGreen from "../../../../../../media/Dashboard/arrow-up-green.svg"
import ArrowDownRed from "../../../../../../media/Dashboard/arrow-down-red.svg"
import {
    KeywordsWrapper,
    Header,
    CtaContainer,
    DeleteButton,
    AddNewButton,
    TableWrap,
    TableBody,
    Row,
    TableHeader,
    KeyHeader,
    KeywordHeader,
    CampaignCountHeader,
    AdsCountHeader,
    AvgMonthlySearchHeader,
    QuaterlyChangesHeader,
    AvgCpcHeader,
    TrendHeader,
} from "./index.styled";
import { useDashboard } from "../../../context/dashboard";
import AddNewPopup from "../Popup";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";
import {formatNumber} from "../../../../../../utils/formatTotal";
import Trend from 'react-trend';

const KeywordsTable = () => {
    const [selectedItems, setSelectedItems] = useState([])
    const [sorting, setSorting] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddNewPopupOpened, setAddNewPopupOpened] = useState(false)
    const { dashboardState: {keywordChoices, selectedCountry, selectedProject, trackingKeywords}, dashboardDispatch } = useDashboard()
    const columnHelper = createColumnHelper();
    const useDashboardInfoHook = useDashboardInfo()

    const columns = useMemo(() => [
        columnHelper.accessor("no", {
            id: "no",
            header: () => "No",
            cell: (info) => <label class="checkbox-container" >
            <input type="checkbox" checked={selectedItems.includes(info.row.original.userKeywordRefId) ? "checked" : ""} />
            <span class="checkmark" onClick={() => {
                toggleCheckBox(info.row.original.userKeywordRefId)
            }}></span>
        </label>,
        }),
        columnHelper.accessor("keyword", {
            id: "keyword",
            header: () => "Keyword",
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("activeCampaigns", {
            id: "activeCampaigns",
            header: () => "Active Campaigns",
            cell: (info) => {return info.getValue() ? formatNumber(info.getValue()) : "-"},
        }),
        columnHelper.accessor("totalAds", {
            id: "totalAds",
            header: () => "Total Ads",
            cell: (info) => {return info.getValue() ? formatNumber(info.getValue()) : "-"},
        }),
        columnHelper.accessor("searchVolume", {
            id: "searchVolume",
            header: () => "Avg Monthly Searches",
            cell: (info) => {return info.getValue() ? formatNumber(info.getValue()) : "-"},
        }),
        columnHelper.accessor("threeMonthsChange", {
            id: "threeMonthsChange",
            header: () => "3 Month Change",
            cell: (info) => info.row.original.threeMonthsChange && info.row.original?.threeMonthsChange != "0%" ? <>{info.row.original.threeMonthsChange}<img src={info.row.original.threeMonthsChange.includes("-") ? ArrowDownRed :  ArrowUpGreen}/></> : <span className="no-change">no changes</span>,
        }),
        columnHelper.accessor("avgCpc", {
            id: "avgCpc",
            header: () => "Avg CPC",
            cell: (info) => {return (info.getValue() ? ("$" + info.getValue()) : "-")},
        }),
        columnHelper.accessor("trend", {
            id: "trend",
            header: () => "Trend",
            cell: (info) =>   
                {
                    (info.row.original?.oneYearChart) && (info.row.original?.oneYearChart.length > 0) &&  <Trend data={info.row.original.oneYearChart.map(item => Number(item.monthlySearches))} 
                    stroke={"#028af5"}
                    autoDraw
                    autoDrawDuration={3000}
                    strokeWidth={3.1}
                    autoDrawEasing="ease-in"
                    smooth
                 />
                }
            ,
        }),
    ], [columnHelper]);

    const columnWidth =
    {
        no: { width: "2", minWidth: "15px" },
        keyword: { width: "20", minWidth: "230px" },
        activeCampaigns: { width: "10", minWidth: "110px" },
        totalAds: { width: "10", minWidth: "110px" },
        searchVolume: { width: "20", minWidth: "130px" },
        threeMonthsChange: { width: "10", minWidth: "110px" },
        avgCpc: { width: "10", minWidth: "110px" },
        trend: { width: "10", minWidth: "110px" }
    }

    const table = useReactTable({
        data: trackingKeywords,
        columns,
        state: {
            sorting,
            sortBy: [{ id: "datetime", desc: false }],
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // debugTable: true,
    });

    const toggleCheckBox = (userKeywordRefId) => {
        if (selectedItems.includes(userKeywordRefId)) {
            let finteredItems = selectedItems.filter(d => {
                return d != userKeywordRefId
            })

            setSelectedItems(finteredItems)
        } else {
            setSelectedItems([...selectedItems, userKeywordRefId])
        }
    }

    const toggleAllItems = () => {
        if (trackingKeywords.length === selectedItems.length) {
            setSelectedItems([])
        } else {
            let finalSelectedItems = trackingKeywords.map(d => {
                return d.userKeywordRefId
            })

            setSelectedItems(finalSelectedItems)
        }
    }

    const openAddNewPopup = () => {
        // dashboardDispatch({
        //     type: "SET_ADD_NEW_POPUP_OPENED",
        //     value: true
        // })
        setAddNewPopupOpened(true)
    }

    const closeAddNewPopup = () => {
        // dashboardDispatch({
        //     type: "SET_ADD_NEW_POPUP_OPENED",
        //     value: false
        // })
        setAddNewPopupOpened(false)
    }

    const deleteKeywords = () => {
        useDashboardInfoHook.deleteTrackingKeywords({userKeywordRefIds: selectedItems})
        setSelectedItems([])
    }

    useEffect(() => {
        if(selectedProject){
            useDashboardInfoHook.getTrackingKeywordData(selectedProject.id)
            setSelectedItems([])
        }
    }, [])

    const handleSetup = (chosenChoicesIndices, userSelectedList) => { 
        const keywordData = chosenChoicesIndices.map((index) => {
            return{
            keyword: keywordChoices[index].keyword,
            source: "suggested",
            country: selectedCountry
            }
        })

        const userEnteredKeywordData = userSelectedList.map((data) => {
            return{
            keyword: data,
            source: "user",
            country: selectedCountry
            }
        })
        useDashboardInfoHook.addKeywordsForTrackingMutate({
            projectId: selectedProject.id,
            keywords: [...keywordData,...userEnteredKeywordData]
        })
        closeAddNewPopup()
    }

    return (trackingKeywords.length > 0) && <KeywordsWrapper>
            <Header>
                <h2>Keyword Tracking</h2>
                <CtaContainer>
                    <DeleteButton disabled={selectedItems.length == 0} onClick={deleteKeywords}>
                        <img src={TrashIcon} alt="Delete" />
                        Delete
                    </DeleteButton>
                    <AddNewButton onClick={openAddNewPopup} >
                        <img src={PlusWhiteIcon} alt="Add" />
                        Add new keyword
                    </AddNewButton>
                </CtaContainer>
            </Header>
            <TableWrap>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Row key={headerGroup.id} >
                            {headerGroup.headers.map((header) => (
                                <div
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={{ width: columnWidth[header.column.id].width + "%", 
                                        // minWidth: columnWidth[header.column.id].minWidth
                                     }}
                                    className="table-header"
                                >
                                    {
                                        (header.column.id == "no") ?
                                            <label class="checkbox-container">
                                                <input type="checkbox" checked={(trackingKeywords.length === selectedItems.length) ? "checked" : ""} />
                                                <span class="checkmark" onClick={() => toggleAllItems()}></span>
                                            </label> :
                                            <>{flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                                {{
                                                    asc: <img src={DownArrowIcon} style={{ rotate: "180deg"}}/>,
                                                    desc: <img src={DownArrowIcon} />,
                                                }[header.column.getIsSorted()] ?? null}
                                            </>
                                    }

                                </div>
                            ))}
                        </Row>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <Row key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <div
                                    className={cell.column.id}
                                    style={{ width: columnWidth[cell.column.id].width + "%", 
                                        // minWidth: columnWidth[cell.column.id].minWidth
                                     }}
                                    key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>
                            ))}
                        </Row>
                    ))}
                </TableBody>
            </TableWrap>
            { isAddNewPopupOpened && <AddNewPopup handleSetup={handleSetup} handleClose={closeAddNewPopup} />}
        </KeywordsWrapper>
};

export default KeywordsTable;






