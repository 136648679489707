import React, {useEffect, useState} from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"

import {
    Container,
    SearchContainer,
    ResultsContainer,
    Wrapper,
    HeadingWrapper,
    DomainInput,
    TrackButton,
    InputContainer,
    ClearButton,
    HeadingLeft,
    HeadLeftWrap,
    HowItWork,
    HeaderRight,
    ErrorBox,
    RightIcons,
    ErrorIcon,
    HttpsLabel,
    PlaceholderWithInput
} from "./index.styled"

import PPCRestrictionPopup from '../../../components/PPCRestrictionPopup';
import CloseIcon from "../../../../media/Dashboard/detailsPaneClose.svg"
import { useAuth } from '../../../../context/auth';

import Tooltip from 'rc-tooltip';

import { useDomainOverview } from '../context/domainoverview';
import CountryDropDown from './components/CountryDropDown';
import ValidateAndAddHttps from '../../../../utils/urlValidate';
import overview from "../assets/overview.png"
import errorIcon from "../assets/errorIcon.svg"

function isValidDomain(url) {
    const domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

    return domainAndUrlRegEx.test(url);
}

const DomainOverview = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()

    const {authState, authDispatch} = useAuth()

    const [error, setError] = useState(false)
    const [isValidUrl, setIsValidUrl] = useState(false)
    const [inputFocussed, setInputFocussed] = useState(false)
    const navigate = useNavigate()
    
    const onExploreClick = () => {
        console.log('valid url', isValidDomain(domainOverviewState.domainInput) )
        // if(domainOverviewState.domainInput.length > 0 && isValidDomain(domainOverviewState.domainInput)){
        //     const validUrl = ValidateAndAddHttps(domainOverviewState.domainInput);

        //     domainOverviewDispatch({type: 'SET_DOMAIN_ERROR', value: ''})
        //     navigate(`/domain-overview/details?domain=${validUrl}&country=${domainOverviewState.domainCountry?.country ? domainOverviewState.domainCountry.country : "global"}`)
        // }else{
        //     // domainOverviewDispatch({type: 'SET_DOMAIN_ERROR', value: 'Enter a valid URL'})
        //     setError(true)
        // }
        if(domainOverviewState.domainInput.length > 0 && !error){
            console.log('isvalid',domainOverviewState.domainInput)
            const validUrl = ValidateAndAddHttps(domainOverviewState.domainInput);
            navigate(`/domain-overview/details?domain=${validUrl}&country=${domainOverviewState.domainCountry?.code ? domainOverviewState.domainCountry.name.common : "global"}`)
        }
    }
    
    const handleInputChange = (e) => {
        let { value } = e.target
        if(!value.startsWith("https://")) value = `https://${value}`
        console.log('url is ', value ,'valid url', isValidDomain(value) )
        domainOverviewDispatch({type: 'SET_DOMAIN_INPUT', value})
        if(domainOverviewState.domainInput.length > 0 )
            setError(!isValidDomain(e.target.value))
    }

    const eventEnterHandle = (e) =>{
        if (e.key === 'Enter') {
            onExploreClick()
        }
    }

    useEffect(() => {
        domainOverviewDispatch({type: 'SET_DOMAIN_INPUT', value: ""})
    }, [])

    const headingText = "Domain Overview "
    const descriptionText = "Leverage the Domain Overview to refine your strategies and schedule campaigns more effectively. By analysing performance trends, this tool allows you to strategically align your initiatives, ensuring optimal timing and execution for maximum impact."


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>In the search bar, enter your chosen domain. Select the country from the dropdown and simply click on the “Explore” button. </li>
                    <li  style={{marginBottom: '16px'}}>Get campaign and platform level analysis on the domain, along with similar domains of the same niche. Also get information on related keywords with metrics such as search volume, competition and average cost-per-click (CPC) for your domain. </li>
                    <li  style={{marginBottom: '16px'}}>Use Sort option to sort the ad copies by date or relevance. You can also use filters to view ad copies based on country and date range.</li>
                    <li style={{marginBottom: '16px'}}>To change the target country, simply select the country from the dropdown at the top right corner of the workspace. </li>
                    <li style={{marginBottom: '16px'}}>To start tracking the domain, click on the “Track Domain” button on the top right corner of the workspace. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };
  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    }; 

    return <Container>
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
               <Wrapper isNavCollapsed={authState.isNavCollapsed}>
                    <HeadingWrapper>
                        <HeadingLeft>
                            <HeadLeftWrap>
                            <h2>{headingText}</h2>
                            <Tooltip 
                                trigger={['hover']}  
                                style={tooltipStyle}
                                overlayStyle={overlayStyle}
                                overlayInnerStyle={overlayInnerStyle} 
                                showArrow={false}
                                arrowContent={null}
                                overlay={toolTipFunction()}
                            >
                                <HowItWork>Quick guide</HowItWork>
                            </Tooltip>
                            </HeadLeftWrap>
                            <p>{descriptionText}</p>
                        </HeadingLeft>
                        <HeaderRight>
                            <img src={overview} />
                        </HeaderRight>
                    </HeadingWrapper>
                    <SearchContainer isNavCollapsed={authState.isNavCollapsed}>
                        <PlaceholderWithInput>
                            <HttpsLabel>https://</HttpsLabel>
                            <InputContainer isFocussed={inputFocussed} hasError={error} >
                                <DomainInput
                                    value={domainOverviewState.domainInput.startsWith("https://") ? domainOverviewState.domainInput.split("https://")[1] : domainOverviewState.domainInput}
                                    placeholder='Enter a specific domain' 
                                    onChange={handleInputChange}    
                                    onKeyDown={(e) => {
                                        eventEnterHandle(e) 
                                    }} 
                                    onFocus={() => setInputFocussed(true)}
                                    onBlur={() => setInputFocussed(false)}
                                />
                                <RightIcons>
                                    {domainOverviewState.domainInput.length > 0 && <ClearButton  onClick={()=>{domainOverviewDispatch({type: 'SET_DOMAIN_INPUT', value: ""})}} src={CloseIcon} alt=""/>}
                                    {error && <ErrorIcon src={errorIcon} alt=""/>}
                                </RightIcons>
                            </InputContainer>
                        </PlaceholderWithInput>
                        <CountryDropDown/>
 
                        <TrackButton onClick={onExploreClick}>
                            Explore
                        </TrackButton>
                    </SearchContainer>
                    {error && <ErrorBox>Enter a valid domain</ErrorBox>}
                </Wrapper>
            </Container>
};
    

export default DomainOverview