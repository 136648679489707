import { useEffect } from "react";
import { useDashboard } from "../../../context/dashboard";
import AddNew from "../AddNew";
import statics from "../DashboardSetup/statics";
import { PopupBackground } from "./index.styled";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";

function AddNewPopup({handleSetup, handleClose, forKeyword = true}) {
    const { keywordSelectingInstruction, competitorSelectingInstruction } = statics;
    const { dashboardState: {keywordChoices, competitorChoices, selectedCountry, selectedProject} } = useDashboard()
    const { getKeywordChoicesMutate, getCompetitorChoicesMutate } = useDashboardInfo()
    useEffect(() => {
        const data = {
            projectId: selectedProject.id,
            country: selectedCountry
        }
        if(forKeyword){
            if(!keywordChoices){
                getKeywordChoicesMutate(data)
            }
            return
        }
        if(!competitorChoices){
            getCompetitorChoicesMutate(data)
        }
    }, [])
    
    return ( 
        <PopupBackground>
            <AddNew 
                choices={ forKeyword ? keywordChoices : competitorChoices}
                selectingInstruction={ forKeyword ? keywordSelectingInstruction : competitorSelectingInstruction}
                forKeyword={forKeyword}
                onSetup={handleSetup}
                forPopup={true}
                handleClose={handleClose}
            />
        </PopupBackground>
     );
}

export default AddNewPopup;