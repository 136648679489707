import React, {useEffect, useRef} from 'react'
import { useLocation, useSearchParams} from 'react-router-dom'

// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'

import {useAmazonSearch} from "../context/amazon"
import {useSearch} from "../../../Adspy/context/search"
import { getSearchParams, urlPatternValidation, dateFormatter ,formatWithCommas } from "../../../../../../utils/helper.js"

// Icons
import Results from './Results'
import { useAuth } from '../../../../../../context/auth.js'
import { useAmazonInfo } from '../hooks/useAmazonInfo.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'

const GoogleSearchAds = () => {
    const {amazonSearchState, amazonSearchDispatch} = useAmazonSearch()
    const [searchParams, setSearchParams] = useSearchParams()

    const {authState, authDispatch} = useAuth()

    const {searchState,searchDispatch} = useSearch()
    const location = useLocation()

    const useAmazonInfoHook = useAmazonInfo()

    const myRef = useRef(null);


    useEffect(() => {
        if(location.pathname.includes("amazon")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'amazon'})
        }
    }, [])

    useEffect(() => {
        // console.log('search param updated')
        const searchParamFromState = getSearchParams(location.search)

        const {
            searchMode,
            keywords,
            phraseInput,
            domainUrlInput,
            keywordSearchType,
            urlSearchType,
            searchIn,
            minimumShouldMatch,
            excludedTerm,
            page,
            selectedCountries,
            startDate,
            endDate,
            sortType
        } = searchParamFromState

        amazonSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: false})

        if(searchMode){
            if(searchMode == "keyword"){
                authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                if(keywordSearchType){
                    authDispatch({type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })
                    if(keywordSearchType == "phrase"){
                        if(phraseInput){
                            authDispatch({type: 'SET_PHRASE_INPUT', value: phraseInput})
                        }
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    amazonSearchDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    amazonSearchDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }
                            })
                        }
                    }
                    if(keywordSearchType == "broad"){
                        if(keywords && keywords.length > 0){
                            authDispatch({type: 'SET_KEYWORDS', value: keywords.split(",")})
                        }

                        amazonSearchDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 }})
                        
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    amazonSearchDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    amazonSearchDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: false
                                    }})
                                }
                            })
                        }
                    }
                    if(excludedTerm){
                        amazonSearchDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm})
                    }
                }
            }

            if (searchMode == "url"){
                authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                if(urlSearchType){
                    authDispatch({type: 'SET_URL_SEARCHTYPE', value: urlSearchType })
                    if(urlSearchType == "exact"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                        }
                    }
                    if(urlSearchType == "domain"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                        }
                    }
                    if(urlSearchType == "text"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: domainUrlInput.length > 0}})
                        }
                    }
                }
            }

            if(selectedCountries && selectedCountries.length > 0){
                amazonSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
            }else{
                amazonSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
            }

            if(startDate){
                amazonSearchDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
            }else{
                amazonSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(endDate){
                amazonSearchDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
            }else{
                amazonSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
            }


            if(sortType){
                amazonSearchDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                    name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                    type: sortType
                }})
            }

            if(page){
                amazonSearchDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
            }
        }else{
            amazonSearchDispatch({type: 'CLEAR_ALL'})
        }

        // on filter applied
        if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){
            // console.log("inside filter")
            if (searchMode == "keyword") {
                if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                    if (keywordSearchType == "broad") {
                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")
                        

                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useAmazonInfoHook.keywordBoardFilterMutate(reqBody)
                    } else if (keywordSearchType == "phrase") {
                        // console.log("innnnnnnnnn")
                        let fields = searchIn.split(",")
                        

                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useAmazonInfoHook.keywordPhraseFilterMutate(reqBody)
                    }
                }
            } else if (searchMode == "url") {
                if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                    if (urlSearchType == "domain") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useAmazonInfoHook.urlDomainFilterMutate(reqBody)
                    } else if (urlSearchType == "text") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useAmazonInfoHook.urlTextFilterMutate(reqBody)
                    } else if (urlSearchType == "exact") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useAmazonInfoHook.urlExactFilterMatchMutate(reqBody)
                    }
                }
            }
        }else{
            // console.log("without filter")
            // on not filter applied
                if (searchMode == "keyword") {
                    if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                        if (keywordSearchType == "broad") {
                            let fields = searchIn.split(",")
                            let query = keywords.split(",").join(" ")
                    
                            const reqBody = {
                                query,
                                minimumShouldMatch: minimumShouldMatch,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page
                            }
                            amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useAmazonInfoHook.keywordBroadMutate(reqBody)
                        } else if (keywordSearchType == "phrase") {
                            let fields = searchIn.split(",")
                            
                            const reqBody = {
                                query: phraseInput,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page
                            }
                            amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useAmazonInfoHook.keywordPhraseMutate(reqBody)
                        }
                    }
                } else if (searchMode == "url") {
                    if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                        if (urlSearchType == "domain") {
                            const reqBody = {
                                query: domainUrlInput,
                                page: page
                            }
                            amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useAmazonInfoHook.urlDomainMutate(reqBody)
                        } else if (urlSearchType == "text") {
                    
                            const reqBody = {
                                query: domainUrlInput,
                                page: page
                            }
                            amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useAmazonInfoHook.urlTextMutate(reqBody)
                        } else if (urlSearchType == "exact") {
                            const reqBody = {
                                query: domainUrlInput,
                                page: page
                            }
                            amazonSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useAmazonInfoHook.urlExactMutate(reqBody)
                        }
                    }
                }
        }

    }, [location.search])


    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        amazonSearchState({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }


    // return <Container ref={myRef}>
    //     <SearchContainer>
    //         <ResultPageSearch/>
    //     </SearchContainer>
    //     <ScrollableContainer>
    //     {
    //         !searchState.limitExceeded && <Filters />
    //     }
    //     {
    //     amazonSearchState.ads && amazonSearchState.ads.length > 0 && (
    //         <AdsCount>
    //             <div><p>
    //                 <span>amazon Search ads{" "}</span>
    //                     - {(amazonSearchState.currentPageNo - 1) * 20 != 0 ? (amazonSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((amazonSearchState.currentPageNo - 1) * 20) + 20) > amazonSearchState.totalAds ? amazonSearchState.totalAds : ((amazonSearchState.currentPageNo - 1) * 20) + 20} of <span>{
    //                     amazonSearchState.totalAds
    //                 }</span>
    //                     {" "}results
    //             </p></div>
    //         </AdsCount>
    //     )
    // }
    //     <Results/> {
    //     !searchState.limitExceeded && amazonSearchState.ads && amazonSearchState.ads.length > 0 && <Pagination/>
    // } 
    // </ScrollableContainer>
    // </Container>

    return <Container ref={myRef}>
        {
            <>
                <Breadcrumb>
                    {"Overview > "} <span>{authState.searchMode === "keyword" ? "Text Search in Ad library" : "Url Search in Ad library"}</span>
                </Breadcrumb>
                <Header>
                    <HeaderLeft>
                        <h2>Amazon Search ads</h2>
                    </HeaderLeft>
                    <HeaderRight>
                        <TotalAdsFound>
                            <label>Total ads found :</label>
                            <span>
                                {formatWithCommas(amazonSearchState.totalAds)}
                            </span>
                        </TotalAdsFound>
                        <SortType />
                        <MajorFilters />
                    </HeaderRight>
                </Header>
                <AppliedFilters>
                    {amazonSearchState.selectedCountries.length > 0 && <AppliedFilterItem>
                        <p>{amazonSearchState.selectedCountries.length == 1 ? amazonSearchState.selectedCountries[0] : amazonSearchState.selectedCountries.length > 1 ? amazonSearchState.selectedCountries[0] + " + " + (
                            amazonSearchState.selectedCountries.length - 1
                        ) : "no country"}</p>
                        <Close onClick={()=>ClearCountry()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {amazonSearchState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                        <p>{amazonSearchState.selectedSortType.name}</p>
                        <Close onClick={()=>ClearSortType()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {amazonSearchState.dateFilterType.length > 0 && <AppliedFilterItem>
                        <p>{amazonSearchState.dateFilterType}</p>
                        <Close onClick={()=>ClearDateFilter()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {/* {(amazonSearchState.selectedCta?.length > 0 || amazonSearchState.selectedTone?.length > 0 || amazonSearchState.sentimentFilter?.length > 0 || amazonSearchState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                        <p>Advanced filters</p>
                        <Close onClick={()=>OpenAdvancedFilter()}>
                            x 
                        </Close>
                    </AppliedFilterItem>} */}
                </AppliedFilters>
                <ScrollableContainer>
                    <Results />
                    <Footer>
                        {(!authDispatch.limitExceeded && amazonSearchState.ads && !amazonSearchState.loadingResults && amazonSearchState.ads.length > 0) && <TotalResults>
                            <p>Showing {(amazonSearchState.currentPageNo - 1) * 20 != 0 ? (amazonSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((amazonSearchState.currentPageNo - 1) * 20) + 20) > amazonSearchState.totalAds ? amazonSearchState.totalAds : ((amazonSearchState.currentPageNo - 1) * 20) + 20} of <span>{
                                amazonSearchState.totalAds
                            }</span>
                            results</p>
                        </TotalResults>}
                        {!authDispatch.limitExceeded && amazonSearchState.ads && !amazonSearchState.loadingResults && amazonSearchState.ads.length > 0 && <Pagination />}
                    </Footer>
                </ScrollableContainer>
            </>
        }
    </Container>
}

export default GoogleSearchAds
