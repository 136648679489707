import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { useReddit } from "../context/reddit";


const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: `/search/reddit/keyword/broad/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: `/search/reddit/keyword/phrase/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainFilterMatch = async (data) => {
    const config = {
        url: `/search/reddit/url/domain/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextFilterMatch = async (data) => {
    const config = {
        url: `/search/reddit/url/text/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    const config = {
        url: `/search/reddit/url/exact/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/reddit/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/reddit/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainMatch = async (data) => {
    const config = {
        url: "/search/reddit/url/domain",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactMatch = async (data) => {
    const config = {
        url: "/search/reddit/url/exact",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextMatch = async (data) => {
    const config = {
        url: "/search/reddit/url/text",
        method: "POST",
        data
    }

    return appClient(config)
}


export const useRedditInfo = () => {
    const { redditState, redditDispatch } = useReddit()
    const {searchState, searchDispatch} = useSearch()

    const userInfoHook = useGetUserInfo()


    const { mutate: keywordBoardFilterMutate, isLoading: isKeywordBoardFilterLoading } = useMutation(keywordBoardFilterMatch, {
        onSuccess: (data) => {
            redditDispatch({ type: "SET_ADS", value: data.documents })
            redditDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: keywordPhraseFilterMutate, isLoading: isKeywordPhraseFilterLoading } = useMutation(keywordPhraseFilterMatch, {
        onSuccess: (data) => {
            redditDispatch({ type: "SET_ADS", value: data.documents })
            redditDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlDomainFilterMutate, isLoading: isUrlDomainFilterLoading } = useMutation(urlDomainFilterMatch, {
        onSuccess: (data) => {
            redditDispatch({ type: "SET_ADS", value: data.documents })
            redditDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlTextFilterMutate, isLoading: isUrlTextFilterLoading } = useMutation(urlTextFilterMatch, {
        onSuccess: (data) => {
            redditDispatch({ type: "SET_ADS", value: data.documents })
            redditDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: urlExactFilterMatchMutate, isLoading: isUrlExactFilterLoading } = useMutation(urlExactFilterMatch, {
        onSuccess: (data) => {
            redditDispatch({ type: "SET_ADS", value: data.documents })
            redditDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordBroadMutate, isLoading: isKeywordBroadLoading} = useMutation(keywordBroadMatch, {
        onSuccess: (data) => {
            redditDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            redditDispatch({type: "SET_ADS", value: data.documents})
            redditDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordPhraseMutate, isLoading: isKeywordPhraseLoading} = useMutation(keywordPhraseMatch, {
        onSuccess: (data) => {
            // console.log(data)
            redditDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            // Update search results
            redditDispatch({type: "SET_ADS", value: data.documents})
            redditDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: urlDomainMutate} = useMutation(urlDomainMatch, {
        onSuccess: (data) => {
            redditDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            redditDispatch({type: "SET_ADS", value: data.documents})
            redditDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlExactMutate} = useMutation(urlExactMatch, {
        onSuccess: (data) => {
            redditDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
            // Update search results
            redditDispatch({type: "SET_ADS", value: data.documents})
            redditDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })
    
    const {mutate: urlTextMutate} = useMutation(urlTextMatch, {
        onSuccess: (data) => {
            redditDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
    
            // Update search results
            redditDispatch({type: "SET_ADS", value: data.documents})
            redditDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            redditDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
    
            if(data.documents && data.documents.length > 0){
                redditDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                redditDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                redditDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                redditDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getUserPlanData()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            redditDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
        }
    })

    return {
        keywordBoardFilterMutate,
        isKeywordBoardFilterLoading,
        keywordPhraseFilterMutate,
        isKeywordPhraseFilterLoading,
        keywordBroadMutate,
        isKeywordBroadLoading,
        keywordPhraseMutate,
        isKeywordPhraseLoading
    };
};
    