import { useEffect } from "react";
import { useDashboard } from "../context/dashboard";
import { Container, Wrapper } from "./index.styled";
import ProjectList from "./ProjectList";
import ProjectOverview from "./ProjectOverview";
import AddNewProject from "./AddProject";
import { useDashboardInfo } from "../hooks/useDashboardInfo";
function Root() {
  const {
    dashboardState: { selectedProject, popupActive ,projectList},
  } = useDashboard();
  const useDashboardInfoHook = useDashboardInfo();
  return (
    <Wrapper>
      <Container paddingProps={selectedProject && projectList.length != 1 ? true : false}>
        {selectedProject ? <ProjectOverview /> : <ProjectList />}
      </Container>
      {popupActive && <AddNewProject />}
    </Wrapper>
  );
}

export default Root;
