import React, { useState } from "react";
import { Container, SearchContainer, Header, ClearAll, Content, RegionSection, RegionSectionHeader, Checkbox, SubCheckbox, RegionSectionContent, CheckBoxContainer, LeftLine, RegionSectionContentWrapper } from "./index.styled"
import SearchIcon from "../../../../../../../../media/Dashboard/SearchIconAdGen.svg"
import { useBingEcomSearch } from "../../../context/bingecom";
import DropDownIcon from "../../../../../../../../media/Dashboard/drop-down-arrow.svg"

const CountryFilter = () => {

    const { bingEcomSearchState, bingEcomSearchDispatch } = useBingEcomSearch()
    const [selectedRegion, setSelectedRegion] = useState(0)

    const handleCountrySearch = (e) => {
        let searchTerm = e.target.value;
        let filteredCountriesList = [];
        
        bingEcomSearchState.availableCountries.map((region, idx) => {
            let tempRegionCountry = region.countries.filter((country) => {
                return country.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || country.toLocaleLowerCase() === searchTerm.toLocaleLowerCase()
            })

            if (tempRegionCountry.length > 0) {
                filteredCountriesList.push({tag: region.tag, countries: tempRegionCountry})
            }
        })

        bingEcomSearchDispatch({type: 'UPDATE_FILTERED_COUNTRIES', value: filteredCountriesList})
    }

    const handleCountrySelect = (e) => {
        if (bingEcomSearchState.selectedCountries.includes(e)) { // If already selected
            let arr = bingEcomSearchState.selectedCountries.filter(item => item !== e)
            bingEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: arr})
        } else {
            let finalArr = [
                ...bingEcomSearchState.selectedCountries,
                e
            ]

            bingEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: finalArr})
        }
    }

    const handleRegionSelect = (e) => {
        if (bingEcomSearchState.selectedRegion.includes(e)) { // If already selected
            let arr = bingEcomSearchState.selectedRegion.filter(item => item !== e)
            bingEcomSearchDispatch({type: 'UPDATE_SELECTED_REGION', value: arr})

            let toRemoveCountries = bingEcomSearchState.availableCountries.filter((countriesR) => {
                return countriesR.tag == e
            })

            let finalSelectedCountry = bingEcomSearchState.selectedCountries.filter((el) => ! toRemoveCountries[0].countries.includes(el));

            bingEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: finalSelectedCountry})
        } else {
            let finalSelectedRegion = [
                ...bingEcomSearchState.selectedRegion,
                e
            ]
            bingEcomSearchDispatch({type: 'UPDATE_SELECTED_REGION', value: finalSelectedRegion})

            let toAddCountries = bingEcomSearchState.availableCountries.filter((countriesR) => {
                return countriesR.tag == e
            })

            let finalArr = [
                ... toAddCountries[0].countries,
                ...bingEcomSearchState.selectedCountries
            ]
            bingEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: finalArr})
        }
    }

    const countriesArray = (bingEcomSearchState.filteredCountries.length > 0) ? bingEcomSearchState.filteredCountries : bingEcomSearchState.availableCountries

    return <Container>
        <Header>
            <SearchContainer>
                <img src={SearchIcon} />
                <input
                    placeholder="Search"
                    onChange={handleCountrySearch}
                />
            </SearchContainer>
            <ClearAll
                onClick={
                    () => {
                        // Reset Page Number
                        bingEcomSearchDispatch({ type: 'UPDATE_FILTER_STATUS', value: 'loading' })
                        bingEcomSearchDispatch({ type: 'SET_CURRENT_PAGE_NO', value: 1 })
                        bingEcomSearchDispatch({ type: 'UPDATE_FILTER_STATUS', value: 'loaded' })

                        bingEcomSearchDispatch({ type: 'UPDATE_SELECTED_COUNTRIES', value: [] })
                        bingEcomSearchDispatch({ type: 'UPDATE_FILTERED_COUNTRIES', value: [] })
                        bingEcomSearchDispatch({ type: 'UPDATE_SELECTED_REGION', value: [] })
                        bingEcomSearchDispatch({ type: 'UPDATE_IS_COUNTRY_SELECTED', value: false })
                    }
                }
            >
                Clear({bingEcomSearchState.selectedCountries.length})
            </ClearAll>
        </Header>
        <Content>
            {countriesArray &&
                countriesArray.map((region, idx) => {
                    return <RegionSection>
                        <RegionSectionHeader
                            onClick={() => {
                                if (selectedRegion == idx) {
                                    setSelectedRegion(null)
                                } else {
                                    setSelectedRegion(idx)
                                }
                            }}
                            isActive={selectedRegion == idx}>
                            <p onClick={() => {
                                        handleRegionSelect(region.tag)
                                    }}>
                                <Checkbox
                                    checked={
                                        bingEcomSearchState.selectedRegion.includes(region.tag)
                                    }
                                    type="checkbox"
                                    className='region-name'
                                    // onChange={() => {
                                    //     handleRegionSelect(region.tag)
                                    // }}
                                />{
                                    region.tag
                                }
                            </p>
                            <img src={DropDownIcon} />
                        </RegionSectionHeader>

                        {selectedRegion == idx && <RegionSectionContent>
                            <LeftLine />
                            <RegionSectionContentWrapper>
                                {region.countries.map((country) => {
                                    return <CheckBoxContainer checked={
                                        bingEcomSearchState.selectedCountries.includes(country)
                                    }>
                                        <p onClick={
                                                    () => {
                                                        handleCountrySelect(country)
                                                    }
                                                }>
                                            <SubCheckbox type="checkbox"
                                                checked={
                                                    bingEcomSearchState.selectedCountries.includes(country)
                                                }
                                                // onChange={
                                                //     () => {
                                                //         handleCountrySelect(country)
                                                //     }
                                                // }
                                            />
                                            {country}
                                        </p>
                                    </CheckBoxContainer>
                                })}
                            </RegionSectionContentWrapper>
                        </RegionSectionContent>}
                    </RegionSection>
                })
            }
        </Content>
    </Container>
}

export default CountryFilter