import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

import { GraphContainer, Header } from "./index.styled";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDashboard } from "../../../context/dashboard";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const KeywordVolumeChart = () => {

  const { getKeywordVolumeChartDataMutate, isGetKeywordVolumeChartDataLoading } = useDashboardInfo()
  const { dashboardState: { selectedProject: {id}, keywordChartData, trackingKeywords } } = useDashboard()

    const [data, setData] = useState(null)
    const options = {
      borderRadius: 6,
      barPercentage: 0.45,
      categoryPercentage: 0.8,
      backgroundColor: "#8571F4",
      hoverBackgroundColor: "#634ed5",
      responsive: true,
      plugins: {
        legend: {
          position: "none",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        }
      },
    };


    useEffect(() => {
        getKeywordVolumeChartDataMutate({
        projectId: id
    })}, [trackingKeywords])

    useEffect(() => {
      if(keywordChartData){
        const labels = keywordChartData.map((data) => {
          const month = data.month.slice(0, 3).toLowerCase()
          return `${month.charAt(0).toUpperCase() + month.slice(1)} ${data.year}`
        })
  
        const dataSet = keywordChartData.map((data) => {
          return data.totalMonthlySearches
        })
  
        setData({
          labels,
          datasets: [
            {
              data: dataSet
            }
          ]
        })
      }
        
    }, [keywordChartData])

  return (trackingKeywords.length > 0) && 
    <GraphContainer>
      <Header>
        <h2>Keyword Volume</h2>
        <p>Average Monthly searches</p>
      </Header>
      {data && <Bar data={data} options={options} />}
    </GraphContainer>
};

export default KeywordVolumeChart;
