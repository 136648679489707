import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import {
    useMutation
} from "@tanstack/react-query"

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    ColumnDef,
    getSortedRowModel
  } from '@tanstack/react-table'
  
import { Container, Wrapper, SearchContainer, ToolTipImageContainer, ToolTipTextContainer, ToolTip, InputContainer, Input, Button,  TableWrapperMain, TableWrapperSub, Header, TableContainer, TableHead, KeywordLabel, VolumeLabel, KcsLabel, CpcLabel, TableBody, Row, KeywordData, VolumeData, KcsData, CpcData, GoogleLabel, BingLabel, GoogleData, BingData, EmptyKeywordContainer, ClearSimilarButton, HeaderText, CurrentSelection, SeletedKeyword, ViewDomains, DropdownButton, DropDownImage, DomainsDropdown, DomainName, RowWrapper, SimilarKeywordData, SimilarKeywordDataLoader, InnerDomainsDropdown, DomainAnalysisButtons, AnalyseDomain, ResultsContainer, ModeContainer, KeywordButtonContainer, NoResult } from "./index.styled";

import { formatNumber } from "../../../../utils/formatTotal";
import SimilarSearch from '../../../../media/Dashboard/SimilarSearchBlue.svg'

import ToolTipLogo from "../../../../media/Dashboard/tooltip.svg"
import GlobeBlack from "../../../../media/Dashboard/globe-black.svg"
import PPCRestrictionPopup from '../../../components/PPCRestrictionPopup'

import {appClient} from '../../../../utils/axios-utils'

import { useKeywordAnalysis } from './../context/keywordanalysis'
import Skeleton from 'react-loading-skeleton'

import FilterIcon from "../../../../media/Dashboard/filter.png"

import UpArrow from "../../../../media/Dashboard/uparrow.png"
import DownArrow from "../../../../media/Dashboard/downarrow.png"

import GoogleLogo from '../../../../media/Dashboard/Google16.svg'
import BingLogo from '../../../../media/Dashboard/Bing16.svg'
import {useAuth} from "../../../../context/auth"
import {Helmet} from "react-helmet"

import PpcResults from "./component/PpcResults";
import SearchLoader from "../../../HOC/SearchLoader";
import { getSearchParams, getDomainName, urlPatternValidation } from "../../../../utils/helper.js"

const domainAnalysis = async (data) => {
    const config = {
        url: "/keyword/domain/analysis",
        method: "POST",
        data
    }

    return appClient(config)
}

const ppcKeywordAnalysis = async (data) => {
    const config = {
        url: "/keyword/ads",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordAnalysis = async (data) => {
    const config = {
        url: "/keyword/analysis",
        method: "POST",
        data
    }

    return appClient(config)
}

const RelatedkeywordAnalysis = async (data) => {
    const config = {
        url: "/keyword/related",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordDomainsAnalysis = async (data) => {
    const config = {
        url: "/keyword/domains",
        method: "POST",
        data
    }

    return appClient(config)
}

const KeywordAnalysisDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { authState, authDispatch } = useAuth() 
    const [localSearchMode, setLocalSearchMode] = useState('domain')

    const [show, setShow] = useState(false)
    const [openedRelativeKeyword, setOpenedRelativeKeyword] = useState('');
    const [sorting, setSorting] = useState([]);
    const {keywordAnalysisState, keywordAnalysisDispatch} = useKeywordAnalysis()

    //React table
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('keyword', {
            id: 'keyword',
            header: () => 'Keyword',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('vol', {
            id: 'volume',
            header: () => 'Volume',
            cell: info => formatNumber(info.getValue()),
        }),
        columnHelper.accessor('competition', {
            id: 'competition',
            header: () => 'Competition',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('cpc', {
            id: 'cpc',
            header: () => 'CPC',
            cell: info => info.row.original.cpc.currency + info.row.original.cpc.value,
        })
    ];

    const table = useReactTable({
        data: (keywordAnalysisState.domainKeywords && keywordAnalysisState.domainKeywords[0]) ? keywordAnalysisState.domainKeywords[0].cpc ? keywordAnalysisState.domainKeywords : [] : [],
        columns,
        state: {
            sorting,
          },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    const { mutate: getKeywordDomains, isLoading: isKeywordDomainsLoading } = useMutation(keywordDomainsAnalysis, {
        onSuccess: (data) => {
            keywordAnalysisDispatch({ type: 'SET_KEYWORD_DOMAINS', value: data})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getRelatedKeywords, isLoading: isRelatedKeywordsLoading } = useMutation(RelatedkeywordAnalysis, {
        onSuccess: (data) => {
            // console.log(keywordAnalysisState)
            let obj = {...keywordAnalysisState.relatedKeywords}
            obj[keywordAnalysisState.selectedKeyword] = data;
            keywordAnalysisDispatch({type: 'SET_RELATED_KEYWORDS', value: obj})  
            // keywordAnalysisDispatch({type: 'SET_SHOW_SIMILAR_KEYWORDS', value: true})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getKeywordsDetails, isLoading: isKeywordsDetailsLoading } = useMutation(keywordAnalysis, {
        onSuccess: (data) => {
            // console.log(data)
            let filteredArray = data.filter(item => item !== null);

            keywordAnalysisDispatch({type: 'SET_DOMAIN_KEYWORDS', value: filteredArray})
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getDomainKeywords, isLoading: isDomainKeywordsLoading } = useMutation(domainAnalysis, {
        onSuccess: (data) => {
            // console.log(data, getDomainName(keywordAnalysisState.query))
            keywordAnalysisDispatch({type: 'SET_CURRENT_RESULTS_FOR', value: 'domain'})
            if(data && data.length > 0){
                keywordAnalysisDispatch({type: 'SET_DOMAIN_KEYWORDS', value: data})
                getKeywordsDetails({keywords: data})
            }else{
                keywordAnalysisDispatch({type: 'SET_DOMAIN_KEYWORDS', value: []})
            }
        },
        onError: (err) => {
            
        }
    })

    const { mutate: getPpcKeyword, isLoading: isPpcKeywordAdsLoading } = useMutation(ppcKeywordAnalysis, {
        onSuccess: (data) => {
            // console.log(data, getDomainName(keywordAnalysisState.query))
            keywordAnalysisDispatch({type: 'SET_CURRENT_RESULTS_FOR', value: 'keyword'})
            keywordAnalysisDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})

            keywordAnalysisDispatch({type: "SET_ADS", value: data.documents})
            keywordAnalysisDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            keywordAnalysisDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if (data.documents && data.documents.length > 0 && !keywordAnalysisState.searched) {
                keywordAnalysisDispatch({type: "UPDATE_SEARCHED", value: true})
            }
            if(data.totalNumberOfDocuments == 0){
                keywordAnalysisDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                keywordAnalysisDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                keywordAnalysisDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                keywordAnalysisDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            keywordAnalysisDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            // userInfoHook.getUserPlanData()
        },
        onError: (err) => {
            
        }
    })

    const cleanData = (data) => {
        return data.map((item) => ({
            ...item,
            cpc: item.cpc.currency + item.cpc.value
          }));
    }

    const exportJSONToCSV = (objArray, name) => {
        let cleanedData = cleanData(objArray)
        // console.log(cleanedData)
        var arr =
          typeof cleanedData !== "object" ? JSON.parse(cleanedData) : cleanedData;
        var str =
          `${Object.keys(arr[0])
            .map((value) => `"${value}"`)
            .join(",")}` + "\r\n";
        var csvContent = arr.reduce((st, next) => {
          st +=
            `${Object.values(next)
              .map((value) => `"${value}"`)
              .join(",")}` + "\r\n";
          return st;
        }, str);
    
        var element = document.createElement("a");
        element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
        element.target = "_blank";
        element.download = `${name}.csv`;
        element.click();
    }

    const onKeywordClick = (keyword) => {
        if(keywordAnalysisState.selectedKeyword != keyword){
            keywordAnalysisDispatch({type: 'SET_SELECTED_KEYWORD', value: keyword})
            if(!keywordAnalysisState.relatedKeywords[keyword]){
                getRelatedKeywords({keyword})
            }
        }else{
            // keywordAnalysisDispatch({type: 'SET_SHOW_SIMILAR_KEYWORDS', value: true})
        }
    }

    const onClearClick = () => {
        // keywordAnalysisDispatch({type: 'SET_SHOW_SIMILAR_KEYWORDS', value: false})
        keywordAnalysisDispatch({type: 'SET_RELATED_KEYWORDS', value: []}) 
    }

    const onViewDomainsClick = (keyword) => {
        if(keywordAnalysisState.selectedSimilarKeyword == keyword){
            keywordAnalysisDispatch({type:'SET_SELECTED_SIMILAR_KEYWORD', value: ''})
        }else{
            keywordAnalysisDispatch({type:'SET_SELECTED_SIMILAR_KEYWORD', value: keyword})
            getKeywordDomains({keyword: keyword})
        }
        keywordAnalysisDispatch({type: 'SET_SHOW_DOMAINS_LIST', value: !keywordAnalysisState.showDomainsList})
    }

    const handleSearch = () => {
        if(localSearchMode == "domain"){
            if(keywordAnalysisState.isValidUrl){
                if(!authState.features?.isKeywordAnalysisAllowed){
                    authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                }else{
                    searchParams.set('query',keywordAnalysisState.query)
                    searchParams.set('page',1)
                    searchParams.set('type','domain')
                    navigate(`/keyword-analysis/details?${searchParams.toString()}`)
                }
            }else{
                keywordAnalysisDispatch({type: 'SET_ERROR', value: true})
            }
        }else{
            if(!authState.features?.isKeywordAnalysisAllowed){
                authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
            }else{
                keywordAnalysisDispatch({type: 'SET_ERROR', value: false})
                searchParams.set('query',keywordAnalysisState.query)
                searchParams.set('page',1)
                searchParams.set('type','keyword')
                navigate(`/keyword-analysis/details?${searchParams.toString()}`)
            }
        }
    }

    const handleOnEnterSearch = (event) => {
        if (event.key === 'Enter') {
            if(localSearchMode == "domain"){
                if(keywordAnalysisState.isValidUrl){
                    if(!authState.features?.isKeywordAnalysisAllowed){
                        authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                    }else{
                        searchParams.set('query',keywordAnalysisState.query)
                        searchParams.set('page',1)
                        searchParams.set('type','domain')
                        navigate(`/keyword-analysis/details?${searchParams.toString()}`)
                    }
                }else{
                    keywordAnalysisDispatch({type: 'SET_ERROR', value: true})
                }
            }else{
                if(!authState.features?.isKeywordAnalysisAllowed){
                    authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: true})
                }else{
                    keywordAnalysisDispatch({type: 'SET_ERROR', value: false})
                    searchParams.set('query',keywordAnalysisState.query)
                    searchParams.set('page',1)
                    searchParams.set('type','keyword')
                    navigate(`/keyword-analysis/details?${searchParams.toString()}`)
                }
            }
        }   
    }


    useEffect(() => {
        const searchParamFromState = getSearchParams(location.search)
        const {
            query,
            page,
            type,
            selectedCountries,
            startDate,
            endDate,
            sortType
        } = searchParamFromState

        if(query){
            if(!authState.features?.isKeywordAnalysisAllowed){
                authDispatch({type: 'UPDATE_IS_RESTRICTED', value: true})
            }else{
                keywordAnalysisDispatch({type: 'SET_SEARCH_QUERY', value: query})
                keywordAnalysisDispatch({ type: 'SET_DOMAIN_KEYWORD_PAGENO', value: page})
                setLocalSearchMode(type)

                if(type == 'domain'){
                    keywordAnalysisDispatch({type: 'SET_DOMAIN_NAME', value: getDomainName(query)})
                    getDomainKeywords({query: query, page})
                }else if(type == 'keyword'){
                    const reqBody = {
                        keyword: query,
                        // query: query,
                        page: page,
                        // countries: selectedCountries ? selectedCountries.split(","): [],
                        // startDate: dateFormatter(startDate),
                        // endDate: dateFormatter(endDate),
                        // sort: sortType ? sortType : "relevent"
                    }
                    keywordAnalysisDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})
                    getPpcKeyword(reqBody)
                }
            }
        }
    }, [location.search])
    
    
    return <Container>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{keywordAnalysisState.query}: PPC Keyword Analysis | AdSpyder</title>
                <meta name="title" content={`${keywordAnalysisState.query} - PPC Keyword Analysis | AdSpyder`} />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
            <SearchContainer>
                <Wrapper style={{gap: 0}}>
                    <ModeContainer>
                        <KeywordButtonContainer 
                            active={localSearchMode === "keyword"} 
                            onClick={() => setLocalSearchMode('keyword')}>
                            <input type="radio" checked={localSearchMode === "keyword" && "checked"} id="keyword" name="mode"/>
                            <label for="keyword">keyword</label>
                        </KeywordButtonContainer>
                        <KeywordButtonContainer 
                            active={localSearchMode === "domain"} 
                            onClick={() =>  setLocalSearchMode('domain')}>
                            <input type="radio" checked={localSearchMode === "domain" && "checked"} id="domain" name="mode"/>
                            <label for="domain">domain</label>
                        </KeywordButtonContainer>
                    </ModeContainer>
                    <InputContainer>
                            <Input
                                onKeyDown={handleOnEnterSearch}
                                error={keywordAnalysisState.error}
                                name="domain-url-input"
                                type="text"
                                placeholder="Enter an URL" 
                                autoComplete="off"
                                value={keywordAnalysisState.query}
                                onChange={(e) => { 
                                    const isTrueVal = urlPatternValidation(e.target.value)

                                    keywordAnalysisDispatch({type: 'SET_ERROR', value: false})

                                    keywordAnalysisDispatch({type: 'SET_SEARCH_QUERY', value: e.target.value})

                                    keywordAnalysisDispatch({type: 'SET_IS_VALID_URL', value: isTrueVal})
                                }}
                            /> 
                            <ToolTipImageContainer
                                error={keywordAnalysisState.error} className="tooltip-container"
                                >
                                <img src={ToolTipLogo}/>
                                <ToolTip>
                                    Please enter a url
                                </ToolTip>
                            </ToolTipImageContainer>
                    </InputContainer>
                </Wrapper>
                <Button onClick={handleSearch}>Search</Button>
            </SearchContainer>
            <h1>Paid Keyword Analysis: <span>{keywordAnalysisState.currentResusltsFor == "domain" ? keywordAnalysisState.domain : keywordAnalysisState.query}</span></h1>
            {isDomainKeywordsLoading ? <div className='loader'>
                <SearchLoader/>
            </div> : keywordAnalysisState.currentResusltsFor == "domain" ? <Wrapper>
                <TableWrapperMain>
                    {/* <h2>Keyword Analysis</h2> */}
                    {/* <Header>
                        <Count>
                            <label>All keyword<span>54</span></label>
                            <label>Total Volume<span>1.2M</span></label>
                        </Count>
                        <ExportButton onClick={()=>exportJSONToCSV(keywordAnalysisState.domainKeywords, "domain-keywords")}>
                            Export
                        </ExportButton>
                    </Header> */}
                    {isKeywordsDetailsLoading ? <TableContainer>
                        <TableHead>
                            <KeywordLabel>
                                Keyword
                            </KeywordLabel>
                            <VolumeLabel>
                                Volume
                            </VolumeLabel>
                            <KcsLabel>
                                Competition
                            </KcsLabel>
                            <CpcLabel>
                                CPC (USD)
                            </CpcLabel>
                        </TableHead>
                        <TableBody>
                            {
                                keywordAnalysisState.domainKeywords.map((data, idx)=>{
                                    if(data)
                                    return <Row key={idx}>
                                        <KeywordData>
                                            <ToolTipTextContainer
                                                className="tooltip-container">
                                                <p>{data.keyword ? data.keyword : data}</p> 
                                                <ToolTip>
                                                    {data.keyword ? data.keyword : data} 
                                                </ToolTip>
                                            </ToolTipTextContainer>
                                            {isKeywordsDetailsLoading ? <Skeleton /> : <img onClick={()=>onKeywordClick(data.keyword)} src={SimilarSearch}/>}
                                        </KeywordData>
                                        <VolumeData>
                                            {isKeywordsDetailsLoading ? <Skeleton />  : formatNumber(data.vol)}
                                        </VolumeData>
                                        <KcsData>
                                            {isKeywordsDetailsLoading ? <Skeleton />  : data.competition}
                                        </KcsData>
                                        <CpcData>
                                            {isKeywordsDetailsLoading ? <Skeleton />  : data.cpc.currency + data.cpc.value}
                                        </CpcData>
                                    </Row>
                                })
                            }
                        </TableBody>
                    </TableContainer> : <TableContainer>
                        {table.getHeaderGroups().map(headerGroup => {
                            return <TableHead>
                                        <KeywordLabel onClick={headerGroup.headers[0].column.getToggleSortingHandler()}>
                                            Keyword <img src={headerGroup.headers[0].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[0].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                                        </KeywordLabel>
                                        <VolumeLabel onClick={headerGroup.headers[1].column.getToggleSortingHandler()}>
                                            Volume <img src={headerGroup.headers[1].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[1].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                                        </VolumeLabel>
                                        <KcsLabel onClick={headerGroup.headers[2].column.getToggleSortingHandler()} >
                                            Competition <img src={headerGroup.headers[2].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[2].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                                        </KcsLabel>
                                        <CpcLabel onClick={headerGroup.headers[3].column.getToggleSortingHandler()}>
                                            CPC (USD) <img src={headerGroup.headers[3].column.getIsSorted() == 'asc' ? UpArrow : headerGroup.headers[3].column.getIsSorted() == 'desc' ? DownArrow : FilterIcon}/>
                                        </CpcLabel>
                                    </TableHead>
                                })}
                        <TableBody>

                            {table
                                .getRowModel()
                                .rows.length > 0 ? table
                                .getRowModel()
                                .rows
                                .map(row => {
                                    if(row.original){                                        
                                        return (<Row key={row.idx}>
                                            <KeywordData>
                                                <ToolTipTextContainer
                                                    className="tooltip-container">
                                                    <p>{row?.original?.keyword ? row.original.keyword : row}</p> 
                                                    <ToolTip>
                                                        {row?.original?.keyword ? row.original.keyword : row} 
                                                    </ToolTip>
                                                </ToolTipTextContainer>
                                                {isKeywordsDetailsLoading ? <Skeleton /> : <img onClick={()=>onKeywordClick(row?.original?.keyword)} src={SimilarSearch}/>}
                                            </KeywordData>
                                            <VolumeData>
                                                {isKeywordsDetailsLoading ? <Skeleton />  : formatNumber(row?.original?.vol)}
                                            </VolumeData>
                                            <KcsData>
                                                {isKeywordsDetailsLoading ? <Skeleton />  : row?.original?.competition}
                                            </KcsData>
                                            <CpcData>
                                                {isKeywordsDetailsLoading ? <Skeleton />  : row?.original?.cpc?.currency + row?.original?.cpc?.value}
                                            </CpcData>
                                        </Row>
                                        )
                                    }
                                }) : <Row>
                                    <NoResult>No data available</NoResult>
                                </Row> }
                        </TableBody>
                    </TableContainer>}
                    {/* {keywordAnalysisState.domainKeywords[0]?.vol  &&
                    } */}
                </TableWrapperMain>
                {keywordAnalysisState.selectedKeyword ? <TableWrapperSub>
                    {/* <h2>Ad copies for keyword based platforms</h2> */}
                    <Header>
                        <HeaderText>
                            <p>Ad Copies Distribution and Similar Keywords</p>
                            <ClearSimilarButton onClick={onClearClick}>
                                Clear data
                            </ClearSimilarButton>
                        </HeaderText>
                        <CurrentSelection>
                            <SeletedKeyword>
                                {keywordAnalysisState.selectedKeyword}
                            </SeletedKeyword>
                            <ViewDomains>
                                <DropdownButton onClick={() => {
                                    if(!show){
                                        keywordAnalysisDispatch({type:'SET_SELECTED_SIMILAR_KEYWORD', value: ''})
                                        getKeywordDomains({keyword: keywordAnalysisState.selectedKeyword})
                                    }
                                    setShow(prev => !prev)
                                }}
                                    >
                                    View domains <img src={GlobeBlack}/>
                                    {
                                        <DropDownImage><img src={
                                            show ? UpArrow : DownArrow
                                        }/></DropDownImage>
                                    }
                                </DropdownButton>
                            </ViewDomains>
                        </CurrentSelection>
                        {/* <CountContainer>
                            <PlatformCount>
                                <img src={GoogleLogo}/> G Search ads: <span>{isRelatedKeywordsLoading ? <Skeleton />  :keywordAnalysisState.relatedKeywords[0].google}</span>
                            </PlatformCount>
                            <PlatformCount>
                                <img src={BingLogo}/> B Search ads: <span>{isRelatedKeywordsLoading ? <Skeleton />  : keywordAnalysisState.relatedKeywords[0].bing}</span>
                            </PlatformCount>
                        </CountContainer> */}
                        {show && <DomainsDropdown>
                            <h2>Top 5 keyword domain</h2>
                                {
                                    isKeywordDomainsLoading ? [...Array(5)].map((data,idx)=>{
                                    return <RowWrapper>
                                            <Row key={idx}>               
                                            <SimilarKeywordDataLoader>
                                                <Skeleton />
                                            </SimilarKeywordDataLoader>
                                            </Row>                
                                    </RowWrapper>
                                })
                            : 
                                    keywordAnalysisState.keywordDomains.map((domain,idx)=>{
                                        return <DomainName>
                                            {domain}
                                            <DomainAnalysisButtons className="domainanalysis-btn">
                                                {/* <TrackDomain>
                                                    Track Domain
                                                </TrackDomain>
                                                <AnalyseDomain>
                                                    Analyze Domain
                                                </AnalyseDomain> */}
                                            </DomainAnalysisButtons>
                                        </DomainName>
                                    })
                                }
                        </DomainsDropdown>}
                    </Header>
                    <TableContainer>
                        <TableHead>
                            <KeywordLabel>
                                Keyword
                            </KeywordLabel>
                            <GoogleLabel>
                                <img src={GoogleLogo}/>
                            </GoogleLabel>
                            <BingLabel>
                                <img src={BingLogo}/>
                            </BingLabel>
                        </TableHead>
                        <TableBody>
                                {
                                    isRelatedKeywordsLoading || !keywordAnalysisState.relatedKeywords[keywordAnalysisState.selectedKeyword] ? [...Array(10)].map((data,idx)=>{
                                    return <RowWrapper key={idx}>
                                            <Row key={idx}>               
                                            <SimilarKeywordDataLoader>
                                                <Skeleton />
                                            </SimilarKeywordDataLoader>
                                            <GoogleData>
                                                <Skeleton />
                                            </GoogleData>
                                            <BingData>
                                                <Skeleton />
                                            </BingData>
                                            </Row>                
                                    </RowWrapper>
                                })
                            :
                                keywordAnalysisState.relatedKeywords[keywordAnalysisState.selectedKeyword].map((data, idx)=>{
                                    if(data.keyword)
                                    return <RowWrapper>
                                            <Row key={idx}>               
                                                <SimilarKeywordData>
                                                    
                                                    {isRelatedKeywordsLoading ? <Skeleton />  : <p>{data.keyword ? data.keyword : data}</p>}
                                                    <DropdownButton onClick={()=> {
                                                            onViewDomainsClick(data.keyword)
                                                        }
                                                        
                                                        }
                                                        >
                                                        <img src={GlobeBlack}/>
                                                        {
                                                            <DropDownImage><img src={
                                                                keywordAnalysisState.selectedSimilarKeyword == data.keyword  ? UpArrow : DownArrow
                                                            }/></DropDownImage>
                                                        }
                                                    </DropdownButton>
                                                </SimilarKeywordData>
                                                <GoogleData>
                                                    {isRelatedKeywordsLoading ? <Skeleton />  : formatNumber(data.google)}
                                                </GoogleData>
                                                <BingData>
                                                    {isRelatedKeywordsLoading ? <Skeleton />  : formatNumber(data.bing)}
                                                </BingData>
                                            </Row>
                                            {(keywordAnalysisState.selectedSimilarKeyword == data.keyword) && <InnerDomainsDropdown>
                                                <h2>Top 5 keyword domain</h2>
                                                {
                                                    isKeywordDomainsLoading ? [...Array(5)].map((data,idx)=>{
                                                        return <RowWrapper>
                                                                <Row key={idx}>               
                                                                <SimilarKeywordDataLoader>
                                                                    <Skeleton />
                                                                </SimilarKeywordDataLoader>
                                                                </Row>                
                                                        </RowWrapper>
                                                    })
                                                :
                                                    keywordAnalysisState.keywordDomains.map((domain,idx)=>{
                                                        return <DomainName>
                                                            {domain}
                                                            <DomainAnalysisButtons className="domainanalysis-btn">
                                                                {/* <TrackDomain>
                                                                    Track Domain
                                                                </TrackDomain> */}
                                                                <AnalyseDomain onClick={()=>navigate(`/url-analysis/details?url=${domain}&searchMode=domain`)}>
                                                                    Analyze Domain
                                                                </AnalyseDomain>
                                                            </DomainAnalysisButtons>
                                                        </DomainName>
                                                    })
                                            }
                                            </InnerDomainsDropdown>}                 
                                    </RowWrapper>
                                })
                            }
                        </TableBody>
                    </TableContainer>
                </TableWrapperSub> : table
                                .getRowModel()
                                .rows.length > 0 && <EmptyKeywordContainer>
                            <p>Click the <img src={SimilarSearch}/> button next to any keyword to view</p>
                            <p> the Ad Copies Distribution and Similar Keywords</p>
                </EmptyKeywordContainer>
                }
            </Wrapper> : <PpcResults/>}
        </Container>
}

export default KeywordAnalysisDetails