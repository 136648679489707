// import React, {useState, useEffect} from 'react'
// import {appClient} from '../../../../utils/axios-utils'
// import produce from 'immer'
// import {Helmet} from "react-helmet"

// import Layout from '../Components/LoginLayout/layout'
// import {useNavigate} from 'react-router-dom'
// import {useForm} from "react-hook-form"
// import {useMutation, useQuery} from "@tanstack/react-query"
// import {
//     LoginHeader,
//     FooterContainer,
//     FormContainer,
//     Divider,
//     DividerWrapper,
//     EmailInputContainer,
//     EmailInput,
//     PasswordInputContainer,
//     PasswordContainer,
//     PasswordInput,
//     CtaContainer,
//     ForgetPassword,
//     LoginButton,
//     Error,
//     LoginError
// } from './index.styled'
// import toast from "react-hot-toast"

// import {useAuth} from "../../../../context/auth"

// import GoogleIcon from '../../../../media/Images/Google.svg'
// import WarningIcon from '../../../../media/Dashboard/warningicon.svg'
// import SocialLogin from '../SocialLogin'
// import _static from '../../../../config/static'
// import { useGetUserInfo } from "../../../hooks/useGetUserInfo"

// const { baseUrl } = _static

// const emailSigninUser = (data) => {
//     const config = {
//         url: "/auth/local/login",
//         method: "POST",
//         data
//     }

//     return appClient(config)
// }

// const getVerificationCode = (email) => {
//     const config = {
//         url: "/auth/local/email-verification-code",
//         method: "GET",
//         data:{email}
//     }

//     return appClient(config)
// }

// const LoginPage = () => {
//     const useGetUserInfoHook = useGetUserInfo()
//     const [passwordType, setPasswordType] = useState('password')
//     const [passwordEntered, setPasswordEntered] = useState('')
//     const [loginError, setLoginError] = useState(false)
//     const [errorMessage, setErrorMessage] = useState('')

//     const {register, handleSubmit, formState: {
//             errors
//         }} = useForm()

//     const navigate = useNavigate()
//     const { authState, authDispatch} = useAuth()
//     let toastId;

//     const {mutate: onSubmit} = useMutation(emailSigninUser, {
//         onSuccess: (data) => {
//             // console.log(data)
//             // if(data){
//             //     authDispatch({
//             //         type: 'CURRENT_USER_UPDATE',
//             //         data:data.user,
//             //         isAuthenticated: true
//             //     })
//             // }
//             if(data){
//                 authDispatch({
//                     type: 'SET_IS_AUTHENTICATED',
//                     value: true
//                 })
//             }

//             setLoginError(false)
//             toast.success("Successfully Signed in")
//             toast.dismiss(toastId)
//             if (data ?. user ?. emailVerified) {
//                 useGetUserInfoHook.getCurrentUser()
//                 // currentUserQuery.refetch()
//             } else {  
//                 onSendVerificationCode()
//             }
//         },
//         onError: (err) => {
//             // console.log(err)
//             setErrorMessage(err.response.data.errors[0].message)
//             toast.dismiss(toastId)
//             setLoginError(true)
//         },
//         onMutate: () => {
//             toastId = toast.loading("Loading...")
//         }
//     })

//     const {mutate: onSendVerificationCode} = useMutation(()=>{return getVerificationCode(authState.email)}, {
//         onSuccess: (data) => {
//             // console.log("confirm email",data)
//             toast.success("OTP sent")
//             toast.dismiss(toastId)
//             // navigate("/confirmemail")
//         },
//         onError: (err) => {
//             toast.dismiss(toastId)
//         },
//         onMutate: () => {
//             toastId = toast.loading("Verifing OTP")
//         }
//     })



//     const handlePasswordInput = (e) => {
//         if (e.target.className.includes("password-input")) {
//             setPasswordEntered(e.target.value)
//         }
//     }
    
//     return (
//         <Layout>
//             <Helmet>
//                 <meta charSet="utf-8" />
//                 <title>AdSpyder Login</title>
//                 <meta name="title" content="AdSpyder Login" />
//                 <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
//             </Helmet>

//             <LoginHeader>
//                 <p>Login in to your account</p>
//             </LoginHeader>
//             <SocialLogin page={"login"}/>
//             <Divider>
//                 <DividerWrapper>
//                     <span>or</span>
//                 </DividerWrapper>
//             </Divider>
//             <FormContainer onChange={
//                     (e) => {
//                         handlePasswordInput(e)
//                     }
//                 }
//                 onSubmit={
//                     handleSubmit(onSubmit)
//             }>
//                 <EmailInputContainer>
//                     <label>Email</label>
//                     <EmailInput // placeholder="Enter your email"
//                         type="email"
//                         error={errors.email}
//                         {...register("email", { required: {
//                             value: true, 
//                             message: "Enter your email"
//                         },
//                         pattern: {
//                             value: /\S+@\S+\.\S+/,
//                             message: "Enter valid email format"
//                           } })}
//                     />
//                     {errors.email && <Error>
//                         {errors.email.message}
//                     </Error>}
//                 </EmailInputContainer>
//                 <PasswordInputContainer>
//                     <label>Password</label>
//                     <PasswordContainer>
//                         <PasswordInput className='password-input'
//                             onChange={
//                                 (e) => setPasswordEntered(e.target.value)
//                             }
//                             error={errors.password}
//                             // placeholder="Enter a password*"
//                             type={passwordType}
//                             {...register("password", { 
//                                 required: "Enter a password",
//                                 minLength: {
//                                 value: 8,
//                                 message: "Password must have at least 8 characters"
//                             }
//                             })}/> {
//                         passwordEntered.length > 0 && <i className={
//                                 passwordType == "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
//                             }
//                             id="eye"
//                             onClick={
//                                 () => {
//                                     setPasswordType(passwordType == "password" ? "text" : "password")
//                                 }
//                         }></i>
//                     } </PasswordContainer>
//                     {errors.password && <Error>
//                         {errors.password.message}
//                     </Error>}
//                 </PasswordInputContainer>
//                 <CtaContainer>
//                     <ForgetPassword>
//                         <a onClick={
//                             () => {
//                                 navigate("/changepassword")
//                             }
//                         }>Forget your password?</a>
//                     </ForgetPassword>
//                     {loginError && <LoginError>
//                         <img src={WarningIcon}/> <p>{errorMessage}</p>
//                     </LoginError>}
//                     <LoginButton>Log in</LoginButton>
//                 </CtaContainer>
//             </FormContainer>
//             <FooterContainer>
//                 <p>Don't have an account?
//                     <a onClick={
//                         () => {
//                             navigate("/signup")
//                         }
//                     }>Sign up</a>
//                 </p>
//             </FooterContainer>
//         </Layout>
//     )
// }

// export default LoginPage
















import React, {useEffect, useState} from 'react'
import {appClient} from '../../../../utils/axios-utils'
import {Helmet} from "react-helmet"

import Layout from '../Components/LoginLayout/layout'
import {useLocation, useNavigate} from 'react-router-dom'
import {useForm} from "react-hook-form"
import {useMutation} from "@tanstack/react-query"
import {
    MainContainer,
    LoginHeader,
    FormContainer,
    Divider,
    DividerWrapper,
    EmailInputContainer,
    EmailInput,
    PasswordInputContainer,
    PasswordContainer,
    PasswordInput,
    CtaContainer,
    ForgetPassword,
    LoginButton,
    Error,
    LoginError,
    FormDivContainer,
    ContinueBtn,
    EmailInputWithContBtn,
    UseBusinesMailText,
    LayoutFooter,
    LayoutEle,
    SignINContainer,
    MailTextContainer,
} from './index.styled'
import toast from "react-hot-toast"

import {useAuth} from "../../../../context/auth"

import WrongBack from '../../../../media/Dashboard/wrongBack.svg'
import WarningIcon from '../../../../media/Dashboard/warningicon.svg'
import circleRightMark from "../../../../media/Dashboard/circleRightMark.svg"
import SocialLogin from '../SocialLogin'
import _static from '../../../../config/static'
import { useGetUserInfo } from "../../../hooks/useGetUserInfo"
import { getSearchParams } from '../../../../utils/helper'

const { baseUrl } = _static

const checkEmail = (data) => {
    const config = {
        url: "/app/onboarding/check-email",
        method: "POST",
        data
    }

    return appClient(config)
}

const emailSigninUser = (data) => {
    const config = {
        url: "/auth/local/login",
        method: "POST",
        data
    }

    return appClient(config)
}

const getVerificationCode = (email) => {
    const config = {
        url: "/auth/local/email-verification-code",
        method: "GET",
        data:{email}
    }

    return appClient(config)
}

const LoginPage = () => {
    const [passwordType, setPasswordType] = useState('password')
    const [passwordEntered, setPasswordEntered] = useState('')
    const [loginError, setLoginError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [existMail, setExistMail] = useState(null)
    const useGetUserInfoHook = useGetUserInfo()
    
    const {register, handleSubmit, formState: {
            errors 
        }} = useForm()

    const navigate = useNavigate()
    const { authState, authDispatch} = useAuth()
    let toastId;

    const location = useLocation()
   
    // const {mutate: onSubmit} = useMutation(emailSigninUser, {
    //     onSuccess: (data) => {
    //         // console.log(data)
    //         // if(data){
    //         //     authDispatch({
    //         //         type: 'CURRENT_USER_UPDATE',
    //         //         data:data.user,
    //         //         isAuthenticated: true
    //         //     })
    //         // }
    //         if(data){
    //             authDispatch({
    //                 type: 'SET_IS_AUTHENTICATED',
    //                 value: true
    //             })
    //         }

    //         setLoginError(false)
    //         toast.success("Successfully Signed in")
    //         toast.dismiss(toastId)
    //         if (data ?. user ?. emailVerified) {
    //             useGetUserInfoHook.getCurrentUser()
    //             // currentUserQuery.refetch()
    //         } else {  
    //             onSendVerificationCode()
    //         }
    //     },
    //     onError: (err) => {
    //         // console.log(err)
    //         setErrorMessage(err.response.data.errors[0].message)
    //         toast.dismiss(toastId)
    //         setLoginError(true)
    //     },
    //     onMutate: () => {
    //         toastId = toast.loading("Loading...")
    //     }
    // })

    const {mutate: onSendVerificationCode} = useMutation(()=>{return getVerificationCode(authState.email)}, {
        onSuccess: (data) => {
            // console.log("confirm email",data)
            toast.success("OTP sent")
            toast.dismiss(toastId)
            // navigate("/confirmemail")
        },
        onError: (err) => {
            toast.dismiss(toastId)
        },
        onMutate: () => {
            toastId = toast.loading("Verifing OTP")
        }
    })



    const { mutate: onLoginMutate } = useMutation(emailSigninUser, {
        onSuccess: (data) => {
            if(data){
                authDispatch({
                    type: 'SET_IS_AUTHENTICATED',
                    value: true
                })
            }

            setLoginError(false)
            toast.success("Successfully Signed in")
            toast.dismiss(toastId)
            if (data ?. user ?. emailVerified) {
                useGetUserInfoHook.getCurrentUser()
                // currentUserQuery.refetch()
            } else {  
                onSendVerificationCode()
            }
            window.clarity("set", "email", data.user.email)
            window.clarity("identify", data.user.email)
        },
        onError: (err) => {
            // console.log(err)
            setErrorMessage(err.response.data.errors[0].message)
            toast.dismiss(toastId)
            setLoginError(true)
        },
        onMutate: () => {
            toastId = toast.loading("Loading...")
        }
    })
    
    const { mutate: onSubmit } = useMutation(checkEmail, {
        onSuccess: (data) => {
            authDispatch({type: "UPDATE_FORM_EMAIL", value: data.email})
            if (data.userExist) {
                setExistMail(data.email)
            } else {
                navigate(`/signup`)
            }
            toast.dismiss(toastId)
        },
        onError: (err) => {
            // console.log(err)
            setErrorMessage(err.response.data.errors[0].message)
            toast.dismiss(toastId)
            setLoginError(true)
        },
        onMutate: () => {
            toastId = toast.loading("Loading...")
        }
    })
    
    useEffect(() => {
        const { email } = getSearchParams(location.search);
        console.log(location, email)
        if(email && !email.includes(" ")){
            onSubmit({email: email});
        }
    }, [])

    return (
        <Layout>
            <MainContainer>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>AdSpyder Login</title>
                    <meta name="title" content="AdSpyder Login" />
                    <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
                </Helmet>
                <SignINContainer>
                    <LoginHeader existMail={existMail}>
                        {!existMail && <p>You’re one click away from </p>}
                        {!existMail && <p>achieving your marketing goals faster</p>}
                        {existMail && <p>Welcome to Adspyder</p>}
                        {existMail && <h3>To get started, please sign in</h3>}
                    </LoginHeader>
                    <FormDivContainer>
                        <SocialLogin page={"login"} />
                        <Divider>
                            <DividerWrapper>
                                <span>OR</span>
                            </DividerWrapper>
                        </Divider>
                        <FormContainer onSubmit={handleSubmit((data) => {
                            console.log(existMail)
                            if(existMail){
                                console.log("login endpoint called")

                                onLoginMutate({
                                    email: data.email,
                                    password: data.password
                                })
                            }else{
                                console.log("email check called")

                                onSubmit({
                                    email: data.email
                                })
                            }
                            
                        })}>
                            {existMail && (<MailTextContainer><span>{existMail}</span><img src={WrongBack} onClick={()=>setExistMail(null)}/></MailTextContainer>)}

                            {!existMail && (<EmailInputWithContBtn>
                                <EmailInputContainer>
                                    <EmailInput placeholder="name@company.com"
                                        type="email"
                                        error={errors.email}
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message: "Enter your email"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Enter valid email format"
                                            }
                                        })}
                                    />
                                    {errors.email && <Error>
                                        {errors.email.message}
                                    </Error>}
                                </EmailInputContainer>



                                <ContinueBtn type="submit">Continue</ContinueBtn>

                            </EmailInputWithContBtn>)
                            }
                            {existMail && (
                                <>
                                    <PasswordInputContainer>
                                        <label>Password</label>
                                        <PasswordContainer>
                                            <PasswordInput className='password-input'
                                                onChange={
                                                    (e) => setPasswordEntered(e.target.value)
                                                }
                                                error={errors.password}
                                                // placeholder="Enter a password*"
                                                type={passwordType}
                                                {...register("password", {
                                                    required: "Enter a password",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must have at least 8 characters"
                                                    }
                                                })} /> {
                                                passwordEntered.length > 0 && <i className={
                                                    passwordType == "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
                                                }
                                                    id="eye"
                                                    onClick={
                                                        () => {
                                                            setPasswordType(passwordType == "password" ? "text" : "password")
                                                        }
                                                    }></i>
                                            } </PasswordContainer>
                                        {errors.password && <Error>
                                            {errors.password.message}
                                        </Error>}
                                    </PasswordInputContainer>
                                    <CtaContainer>
                                        <ForgetPassword>
                                            <a onClick={
                                                () => {
                                                    navigate("/changepassword")
                                                }
                                            }>Forget your password?</a>
                                        </ForgetPassword>
                                        {loginError && <LoginError>
                                            <img src={WarningIcon} /> <p>{errorMessage}</p>
                                        </LoginError>}
                                        <LoginButton type="submit">Log in</LoginButton>
                                    </CtaContainer>
                                </>
                            )}
                            {!existMail && (<UseBusinesMailText>Use your business email and get 10% off</UseBusinesMailText>)}
                        </FormContainer>
                    </FormDivContainer>
                </SignINContainer>
                {!existMail && (<LayoutFooter>
                    <LayoutEle><img src={circleRightMark} />
                        <p>1Billion+ adcopies from 100+ countries</p></LayoutEle>
                    <LayoutEle><img src={circleRightMark} />
                        <p>Save 2weeks of research time</p></LayoutEle>
                    <LayoutEle><img src={circleRightMark} />
                        <p>No Credit card required</p></LayoutEle>
                </LayoutFooter>)}
            </MainContainer>

        </Layout >
    )
}

export default LoginPage
