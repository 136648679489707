import styled from "styled-components";

export const GraphContainer = styled.div`
    width: calc(50% - 15px);
    height: 400px;
    background: #FFF;
    padding: 20px;
    border-radius: 8px;
`;

export const ChartContainer = styled.div`
    

`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    h2{
        font-size: 16px;
        font-weight: 500;
        color: #4F4F4F;
    }
    p{
        font-size: 12px;
        font-weight: 500;
        color: rgba(28, 28, 28, 0.40);
    }
`;