import styled from "styled-components";

export const PlansWrap = styled.div`
  height: 100vh;
  width: calc(100% - 100px);
  margin: auto;
  display: flex;
  -webkit-box-align: center;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  > p {
    color: #2e2e2e;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 36px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    width: 100%;
    padding: 28px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    padding: 40px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoints.mobileSmall}) {
    padding: 20px;
  }
`;

export const PlansContainer = styled.div`
  height: 450px;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: auto;

  @media screen and (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    max-width: calc(100vw - 56px);
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    max-width: calc(100vw - 80px);
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoints.mobileSmall}) {
    max-width: calc(100vw - 40px);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 6px;
    background-color: #ff711e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
`;

export const ToggleContainer = styled.div`
  height: 40px;
  display: flex;
  width: fit-content;
  background: #f9f9f9;
  border: 1px solid #ececec;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const MonthlyButton = styled.div`
  background: ${(props) => (props.isSelected ? "#1A68C2" : "none")};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => (props.isSelected ? "#FFFFFF" : "#505050")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  cursor: pointer;
  height: 100%;
`;

export const YearlyButton = styled.div`
  background: ${(props) => (props.isSelected ? "#1A68C2" : "none")};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => (props.isSelected ? "#FFFFFF" : "#505050")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  cursor: pointer;
  position: relative;
  height: 100%;
  span {
    color: #0051ae;
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 0.5px 3px #0000001a;
    border-radius: 4px;
    padding: 4px 6px;
    top: -15px;
    right: 16px;
  }
`;


export const PlansListContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .storetray {
    display: none;
    background: #e8e3e3;
    height: 3px;
    margin: auto;
    width: 110px;
    border-radius: 100px;
    margin-top: 20px;
  }
  .storeindicator {
    background: #ff711e;
    height: 3px;
    position: relative;
    width: 30px;
    border-radius: 100px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    align-items: center;
    width: auto;
    .storetray {
      display: unset;
    }
  }
`;

export const TermsAndCondition = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 300;
    color: #2e2e2e;
    margin-top: 22px;
    width: 400px;
    text-align: center;
    line-height: 21px;
    span {
      color: #003fbc;
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakPoints.desktop}) {
    flex-direction: column;
    margin-top: 20px;
    p {
      margin-top: 16px;
      width: auto;
    }
  }
`;

export const Note = styled.div`
  color: #2e2e2e;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`;

export const HomePageButton = styled.div`
  position: relative;
  img {
    filter: invert(1);
    height: 28px;

    position: absolute;
    left: 0;
    top: -80px;
    cursor: pointer;
  }
`;

export const FreePopup = styled.div`
  width: 100vw;
  position: absolute;
  left: 0;
  top: 64px;
  height: calc(100vh - 64px);
  background: #0000004d;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FreePopupBody = styled.div`
  height: 200px;
  width: 500px;
  background: #FFF;
  border-radius: 5px;
  padding: 20px;
  h2{
    margin-bottom: 20px;
  }
  p{
    font-size: 18px;
    display: flex;
    align-items: center;
    line-height: 24px;
    font-weight: 300;
  }
`;