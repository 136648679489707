import {
  AdContainer,
  AdImage,
  Advertiser,
  Header,
  ImageWrapper,
  Logo,
  LogoContainer,
  Name,
  OopsDiv,
  SponseredTag,
  TopImageAdsContainer,
  Wrapper,
} from "./index.styled";
import statics from "./statics";
import metaLogo from "./assets/metaLogo.svg";
import googleLogo from "./assets/googleLogo.svg";
import bingLogo from "./assets/bingLogo.svg";
import linkedInLogo from "./assets/linkedInLogo.svg";
import { useDashboardInfo } from "../../../../../hooks/useDashboardInfo";
import { useEffect } from "react";
import { useDashboard } from "../../../../../context/dashboard";

function TopImageAds() {
  const { data } = statics;

  const useDashboardInfoHook = useDashboardInfo();
  const {
    dashboardState: { selectedProject, imageAdsData },
    dashboardDispatch,
  } = useDashboard();

  useEffect(() => {
    useDashboardInfoHook.getImageAdsMutate({ projectId: selectedProject.id });
  }, []);

  return (
    <Wrapper>
      {imageAdsData?.topImageAds.length > 0 ? (
        <TopImageAdsContainer>
          {imageAdsData?.topImageAds.map((d) => {
            return (
              <AdContainer>
                <Header>
                  <LogoContainer>
                    <Logo
                      src={
                        d.platform === "facebook"
                          ? metaLogo
                          : d.platform.includes("google")
                          ? googleLogo
                          : d.platform.includes("bing")
                          ? bingLogo
                          : linkedInLogo
                      }
                    />
                  </LogoContainer>
                  <Advertiser>
                    <Name>{new URL(selectedProject.website).hostname}</Name>
                    <SponseredTag>Sponsered</SponseredTag>
                  </Advertiser>
                </Header>
                <ImageWrapper>
                  <AdImage
                    src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${d.id}`}
                    draggable={false}
                  />
                </ImageWrapper>
              </AdContainer>
            );
          })}
        </TopImageAdsContainer>
      ) : (
        <OopsDiv>
          <p>Oops! Ads can't be found.</p>
        </OopsDiv>
      )}
    </Wrapper>
  );
}

export default TopImageAds;
