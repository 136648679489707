import React, { useState } from "react";
import {
  Container,
  SelectContainer,
  CloseButton,
  Heading,
  PlatformsContainer,
  PlatformAvailable,
  Footer,
  CancelButton,
  ContinueButton,
} from "./platformSelect.styled";
import { useMutation } from "@tanstack/react-query";

import { appClient } from "../../../../../utils/axios-utils";
import { useAuth } from "../../../../../context/auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSubscription } from "../../../../hooks/useSubscription";
import { useGetUserInfo } from "../../../../hooks/useGetUserInfo";

// const currentUser = () => {
//   const config = {
//     url: "/auth/current-user",
//     method: "GET",
//   };

//   return appClient(config);
// };

const FreeCheckout = async (data) => {
  const config = {
    url: "/payment/session/checkout-free",
    method: "POST",
    data,
  };

  return appClient(config);
};

const SelectPlatform = ({
  setShow,
  selectedPlan,
  skipClicked,
  setSelectPlanClicked,
}) => {
  const { authState, authDispatch } = useAuth();
  const navigate = useNavigate();
  const subscriptionHook = useSubscription()
  const useGetUserInfoHook = useGetUserInfo()
  let toastId;

  const [currentSelected, setCurrentSelected] = useState("");

  // const handleSuccess = (data) => {
  //   let renamedRoutes = {
  //     facebook: "meta",
  //     "bing-ecom": "bingpla",
  //     "google-ecom": "googlepla",
  //   };

  //   if (data.features) {
  //     data = produce(data, (draft) => {
  //       let alteredArray = data?.features?.allowedPlatforms.map((platform) => {
  //         if (platform in renamedRoutes) {
  //           return renamedRoutes[platform];
  //         }

  //         return platform;
  //       });

  //       draft.features.allowedPlatforms = alteredArray;
  //     });
  //   }

  //   authDispatch({ type: "CURRENT_USER_UPDATE", data, isAuthenticated: true });
  //   authDispatch({ type: "STOP_LOADER" });
  //   navigate("/adspy");
  // };

  // const handleAcquireError = (err) => {
  //   // console.log(err);
  //   authDispatch({ type: "LOGOUT_SUCCESS" });
  //   authDispatch({ type: "STOP_LOADER" });
  // };

  // let currentUserQuery = useQuery(["current-user"], currentUser, {
  //   retry: false,
  //   // refetchInterval: 20000,
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false,
  //   enabled: false,
  //   onSuccess: handleSuccess,
  //   onError: handleAcquireError,
  // });

  const { mutate: checkoutFreeMutate, isLoading: isPaymentLoading } =
    useMutation(FreeCheckout, {
      onSuccess: (data) => {
        authDispatch({ type: "SET_PLAN_DETAILS", value: data.plan });
        // currentUserQuery.refetch();
        useGetUserInfoHook.getCurrentUser({redirect: true, welcome: true})
      },
      onError: (err) => {
        // console.log(err);
      },
    });

  const onProceed = () => {
    if (skipClicked) {
      checkoutFreeMutate({
        selectedPlatform: currentSelected,
        planName: selectedPlan.nickname,
      });
    } else {
      if (currentSelected == "google" || currentSelected == "facebook") {
        setShow(false);
        subscriptionHook.CreateSubscriptionMutate({
          priceId: selectedPlan.id,
          planName: selectedPlan.nickname,
          selectedPlatform: currentSelected,
        });
      } else {
        toast.error("Please select one platform");
      }
    }
  };

  return (
    <Container>
      <SelectContainer>
        <CloseButton onClick={() => setShow(false)}>x</CloseButton>
        <Heading>
          Please choose a platform with the{" "}
          <span>{selectedPlan.nickname == "free" ? "Free" : "Basic"}</span> plan
        </Heading>
        <PlatformsContainer>
          <PlatformAvailable
            isSelected={currentSelected == "google"}
            onClick={() => setCurrentSelected("google")}
          >
            Google Search ads
          </PlatformAvailable>
          <PlatformAvailable
            isSelected={currentSelected == "facebook"}
            onClick={() => setCurrentSelected("facebook")}
          >
            Meta ads
          </PlatformAvailable>
        </PlatformsContainer>
        <Footer>
          <CancelButton onClick={() => setShow(false)}>Cancel</CancelButton>
          <ContinueButton
            disabled={currentSelected.length == 0}
            onClick={() => {
              setSelectPlanClicked(true);
              onProceed();
            }}
          >
            Choose platform and proceed
          </ContinueButton>
        </Footer>
      </SelectContainer>
    </Container>
  );
};

export default SelectPlatform;
