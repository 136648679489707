import React, {useEffect, useRef} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'


// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'

import {useYoutube} from "../context/youtube"
import {appClient} from '../../../../../../utils/axios-utils'
import {useSearch} from "../../context/search"
import { getSearchParams, formatWithCommas, dateFormatter } from "../../../../../../utils/helper.js"

// Icons
import Results from './Results'
import { useYoutubeInfo } from '../hooks/useYoutubeInfo.js'
import { useAuth } from '../../../../../../context/auth.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'

const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/broad/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/phrase/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/youtube/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

const YoutubeAds = () => {
    const {youtubeState, youtubeDispatch} = useYoutube()
    const [searchParams, setSearchParams] = useSearchParams()
    const { authState, authDispatch } = useAuth()
    const {searchState, searchDispatch} = useSearch()
    const location = useLocation()

    const useYoutubeInfoHook = useYoutubeInfo()

    const myRef = useRef(null);


    function padDigits(number, digits) {
        return String(number).padStart(digits, '0');
    }

    useEffect(() => {
        if(location.pathname.includes("youtube")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'youtube'})
        }
    }, [])

    useEffect(() => {
        // console.log('search param updated')
        const searchParamFromState = getSearchParams(location.search)

        const {
            searchMode,
            keywords,
            phraseInput,
            keywordSearchType,
            searchIn,
            minimumShouldMatch,
            excludedTerm,
            page,
            selectedCountries,
            startDate,
            endDate,
            sortType
        } = searchParamFromState

        youtubeDispatch({type:'UPDATE_DETAILS_MODAL', value: false})
        
        if(searchMode){
            if(searchMode == "keyword"){
                authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                if(keywordSearchType){
                    authDispatch({type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })
                    if(keywordSearchType == "phrase"){
                        if(phraseInput){
                            authDispatch({type: 'SET_PHRASE_INPUT', value: phraseInput})
                        }
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    youtubeDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    youtubeDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }
                            })
                        }
                    }
                    if(keywordSearchType == "broad"){
                        if(keywords && keywords.length > 0){
                            authDispatch({type: 'SET_KEYWORDS', value: keywords.split(",")})
                        }

                        youtubeDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 }})
                        
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    youtubeDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    youtubeDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: false
                                    }})
                                }
                            })
                        }
                    }
                    if(excludedTerm){
                        youtubeDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm})
                    }
                }
            }

            if(selectedCountries && selectedCountries.length > 0){
                youtubeDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
            }else{
                youtubeDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
            }

            if(startDate){
                youtubeDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
            }else{
                youtubeDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(endDate){
                youtubeDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
            }else{
                youtubeDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(sortType){
                youtubeDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                    name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                    type: sortType
                }})
            }

            if(page){
                youtubeDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
            }
        }else{
            youtubeDispatch({type: 'CLEAR_ALL'})
        }

        // on filter applied
        if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){
            // console.log("inside filter")
            if (searchMode == "keyword") {
                if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                    if (keywordSearchType == "broad") {
                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")
                        

                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useYoutubeInfoHook.keywordBoardFilterMutate(reqBody)
                    } else if (keywordSearchType == "phrase") {
                        let fields = searchIn.split(",")

                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useYoutubeInfoHook.keywordPhraseFilterMutate(reqBody)
                    }
                }
            } 
        }else{
            // console.log("without filter")
            // on not filter applied
                if (searchMode == "keyword") {
                    if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                        if (keywordSearchType == "broad") {
                            let fields = searchIn.split(",")
                            let query = keywords.split(",").join(" ")
                    
                            const reqBody = {
                                query,
                                minimumShouldMatch: minimumShouldMatch,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page
                            }
                            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useYoutubeInfoHook.keywordBroadMutate(reqBody)
                        } else if (keywordSearchType == "phrase") {
                            let fields = searchIn.split(",")
                            
                            const reqBody = {
                                query: phraseInput,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page
                            }
                            youtubeDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useYoutubeInfoHook.keywordPhraseMutate(reqBody)
                        }
                    }
                }
        }

    }, [location.search])

    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        youtubeDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    // return <Container ref={myRef}>
    //     <SearchContainer>
    //         {/* <Content>
    //             <PlatformDropDown/>
    //             <SearchMode/>
    //             <Separator/> {
    //             youtubeState.searchMode === "keyword" ? <KeywordComponent/>: <UrlComponent/>
    //         } </Content>
    //         {
    //         !searchState.limitExceeded && < Filters />
    //         }  */}
    //         <ResultPageSearch/>
    //     </SearchContainer>

    //     <ScrollableContainer>
    //             {
    //                 !searchState.limitExceeded && < Filters />
    //             } 
    //                 {
    //                 youtubeState.ads && youtubeState.ads.length > 0 && (
    //                     <AdsCount>
    //                         <div><p>
    //                             <span>youtube Search ads{" "}</span>
    //                             - {(youtubeState.currentPageNo - 1) * 20 != 0 ? (youtubeState.currentPageNo - 1) * 20 + 1 : 1} - {(((youtubeState.currentPageNo - 1) * 20) + 20) > youtubeState.totalAds ? youtubeState.totalAds : ((youtubeState.currentPageNo - 1) * 20) + 20} of <span>{
    //                                 youtubeState.totalAds
    //                             }</span>
    //                                 {" "}results
    //                         </p></div>
    //                         {/* <SearchQuery> {
    //                             youtubeState.keywords.length > 0 ? (youtubeState.keywords.map((word, idx) => {
    //                                 return <span>{word}</span>;
    //                             })) : youtubeState.phraseInput ? (
    //                                 <span>{
    //                                     youtubeState.phraseInput
    //                                 }</span>
    //                             ) : (
    //                                 <span>{
    //                                     youtubeState.domainUrlInput.value
    //                                 }</span>
    //                             )
    //                         } </SearchQuery> */}
    //                     </AdsCount>
    //                 )
    //             }
    //                 <Results/> {
    //                 !searchState.limitExceeded && youtubeState.ads && youtubeState.ads.length > 0 && <Pagination/>
    //             }
    //         </ScrollableContainer>
    //      </Container>

    return <Container ref={myRef}>
        {
                <>
                    <Breadcrumb>
                        {"Overview > "} <span>{authState.searchMode === "keyword" ? "Text Search in Ad library" : "Url Search in Ad library"}</span>
                    </Breadcrumb>
                    <Header>
                        <HeaderLeft>
                            <h2>Youtube Search ads</h2>
                        </HeaderLeft>
                        <HeaderRight>
                            <TotalAdsFound>
                                <label>Total ads found :</label>
                                <span>
                                    {formatWithCommas(youtubeState.totalAds)}
                                </span>
                            </TotalAdsFound>
                            <SortType />
                            <MajorFilters />
                        </HeaderRight>
                    </Header>
                    <AppliedFilters>
                        {youtubeState.selectedCountries.length > 0 && <AppliedFilterItem>
                            <p>{youtubeState.selectedCountries.length == 1 ? youtubeState.selectedCountries[0] : youtubeState.selectedCountries.length > 1 ? youtubeState.selectedCountries[0] + " + " + (
                                youtubeState.selectedCountries.length - 1
                            ) : "no country"}</p>
                            <Close onClick={()=>ClearCountry()}>
                                x 
                            </Close>
                        </AppliedFilterItem>}
                        {youtubeState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                            <p>{youtubeState.selectedSortType.name}</p>
                            <Close onClick={()=>ClearSortType()}>
                                x 
                            </Close>
                        </AppliedFilterItem>}
                        {youtubeState.dateFilterType.length > 0 && <AppliedFilterItem>
                            <p>{youtubeState.dateFilterType}</p>
                            <Close onClick={()=>ClearDateFilter()}>
                                x 
                            </Close>
                        </AppliedFilterItem>}
                        {/* {(youtubeState.selectedCta?.length > 0 || youtubeState.selectedTone?.length > 0 || youtubeState.sentimentFilter?.length > 0 || youtubeState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                            <p>Advanced filters</p>
                            <Close onClick={()=>OpenAdvancedFilter()}>
                                x 
                            </Close>
                        </AppliedFilterItem>} */}
                    </AppliedFilters>
                    <ScrollableContainer>
                        <Results />
                        <Footer>
                            {(!authDispatch.limitExceeded && youtubeState.ads && !youtubeState.loadingResults && youtubeState.ads.length > 0) && <TotalResults>
                                <p>Showing {(youtubeState.currentPageNo - 1) * 20 != 0 ? (youtubeState.currentPageNo - 1) * 20 + 1 : 1} - {(((youtubeState.currentPageNo - 1) * 20) + 20) > youtubeState.totalAds ? youtubeState.totalAds : ((youtubeState.currentPageNo - 1) * 20) + 20} of <span>{
                                    youtubeState.totalAds
                                }</span>
                                results</p>
                            </TotalResults>}
                            {!authDispatch.limitExceeded && youtubeState.ads && !youtubeState.loadingResults && youtubeState.ads.length > 0 && <Pagination />}
                        </Footer>
                    </ScrollableContainer>
                </>
            }
        </Container>
}

export default YoutubeAds
