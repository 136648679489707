import React, { useRef, useState } from "react";
import { PopupContainer, PopupBody, LogoutButton, KeywordsListContainer, KeywordInputContainer, Error, Footer, AddMoreKeywordButton, SetupTrackingButton, ErrorBox } from "./index.styled"
import CloseIcon from "../../../../../../media/Dashboard/detailsPaneClose.svg"
import { useKeywordTracking } from "../../../context/keywordtracking";
import PlatformDropDown from "./components/PlatformDropDown"
import CountryDropDown from "./components/CountryDropDown";
import { useKeywordTrackingInfo } from "../../../hooks/useKeywordTrackingInfo";
import { getSearchParams } from '../../../../../../utils/helper'
import { useLocation } from 'react-router-dom'
import { ErrorIcon, InputContainer, KeywordInput, RightIcons } from "../../index.styled";
import errorIcon from "../../assets/errorIcon.svg"
const KeywordInputModal = ({ isEditMode = false }) => {
    const { keywordTrackingState, keywordTrackingDispatch } = useKeywordTracking()
    const keywordsDiv = useRef(null);
    const useKeywordTrackingInfoHook = useKeywordTrackingInfo()

    const [focussedInputIndex, setFocussedInputIndex] = useState(null)
    const [error, setError] = useState(false)

    const location = useLocation()

    const updateKeywordCountry = (updatingIndex, inputCountryData) => {
        const tempKeywordList = keywordTrackingState.keywordInputList.map((data, index) => {
            if ((updatingIndex === index)) {
                return { ...data, country: inputCountryData }
            }
            return data;
        });

        keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordList })
    }

    const updateKeywordPlatform = (keyword, inputPlatformData) => {

        const tempKeywordList = keywordTrackingState.keywordInputList.map((data, index) => {
            if (keyword == index) {
                // Update the region property for the specific country
                return { ...data, platform: inputPlatformData }
            }
            return data;
        });

        //   console.log("updateKeywordPlatform",tempKeywordList)

        keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordList })
    }

    const addNewKeyword = () => {
        if (keywordTrackingState.keywordInputList.length < 4) {
            keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: '' })
            keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: [...keywordTrackingState.keywordInputList, { keyword: '', platform: '', country: {}, platformError: '' }] })
        } else {
            keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'maximum 4 keywords at a time' })
        }
    }

    const onKeywordInput = (e, index) => {
        // const tempKeywordInputList = keywordTrackingState.keywordInputList
        const tempKeywordInputList = keywordTrackingState.keywordInputList.map((d, id) => {
            if (id === index) {
                // Update the region property for the specific country
                return { ...d, keyword: e.target.value };
            }
            return d;
        });
        keywordTrackingDispatch({
            type: 'SET_KEYWORD_INPUT_ERROR_OBJ', value: {
                ...keywordTrackingState.keywordInputErrorsObj, [index]: {
                    status: !e.target.value > 0,
                    message: !e.target.value > 0 ? 'Enter a keyword' : ''
                }
            }
        })
        // console.log(tempKeywordInputList[index])
        // tempKeywordInputList[index].keyword = e.target.value;
        // console.log(tempKeywordInputList)
        keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST', value: tempKeywordInputList })
    }

    const setUpTrackingfn = () => {
        if (keywordTrackingState.keywordInputList) {
            const setUpObj = {};
            setUpObj.keywords = [];
            // console.log(setUpObj,'setUpObj')
            keywordTrackingState.keywordInputList.map((item) => {
                if (item.keyword !== '') {
                    if (item.platform !== '') {
                        // console.log(item, 'itemitem');
                        setUpObj.keywords.push({
                            keyword: item.keyword,
                            platform: item.platform,
                            country: item?.country?.name ? item.country.name.common : 'global'
                        })
                        keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: '' });
                    } else {
                        // console.log('section checking');
                        keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Please choose the platform' });
                    }

                }
                else {
                    // console.log('keyword checking');
                    // keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_LIST_ERROR', value: 'Please Enter the Keyword' });
                }
            });

            if (!isEditMode) {
                setUpObj.keywords.length > 0 && useKeywordTrackingInfoHook.addTrackingKeywordMutation(setUpObj)
            } else {
                const searchParamFromState = getSearchParams(location.search)

                const { userKeywordRefId } = searchParamFromState
                const data = { ...setUpObj.keywords[0], userKeywordRefId, status: true }

                useKeywordTrackingInfoHook.updateTrackingKeywordMutation(data)
            }
        }

    }

    const eventEnterHandle = (e) => {
        if (e.key === 'Enter') {
            setUpTrackingfn()
        }
    }

    return <PopupContainer>
        <PopupBody>
            {/* temp change  */}
            <LogoutButton onClick={() => keywordTrackingDispatch({ type: 'SET_KEYWORD_INPUT_MODAL_OPEN', value: false })}>
                <img src={CloseIcon} />
            </LogoutButton>
            <h2>Setup Keyword Tracking</h2>
            <KeywordsListContainer ref={keywordsDiv}>
                {
                    keywordTrackingState.keywordInputList.map((keyword, idx) => {
                        return (
                            <div>
                                <KeywordInputContainer error={keywordTrackingState.keywordInputErrorsObj[idx]?.status ? true : false}>
                                    <InputContainer isFocussed={focussedInputIndex === idx} hasError={error}>
                                        <KeywordInput
                                            placeholder="Add a keyword"
                                            defaultValue={keyword.keyword}
                                            onChange={(e) => onKeywordInput(e, idx)}
                                            onKeyDown={(e) => {
                                                eventEnterHandle(e)
                                            }}
                                            onFocus={() => setFocussedInputIndex(idx)}
                                            onBlur={() => setFocussedInputIndex(null)}
                                        />
                                        <RightIcons>
                                            {keywordTrackingState.keywordInputErrorsObj[idx]?.status && <ErrorIcon src={errorIcon} alt=""/>}
                                        </RightIcons>
                                    </InputContainer>
                                    <PlatformDropDown keywordData={keyword} updateKeywordPlatform={updateKeywordPlatform} countryIndex={idx} />
                                    <CountryDropDown keywordData={keyword} updateKeywordCountry={updateKeywordCountry} countryIndex={idx} />
                                </KeywordInputContainer>
                                {keywordTrackingState.keywordInputErrorsObj[idx]?.status && <ErrorBox>Enter a keyword</ErrorBox>}
                            </div>
                        )
                    })
                }
            </KeywordsListContainer>
            <Error>{keywordTrackingState.keywordInputListError ? keywordTrackingState.keywordInputListError : ""}</Error>
            <Footer>
                {!isEditMode && (
                    <AddMoreKeywordButton isDisabled={keywordTrackingState.keywordInputListError} onClick={addNewKeyword}>
                        + Add more keyword
                    </AddMoreKeywordButton>
                )}
                <SetupTrackingButton onClick={setUpTrackingfn}>
                    {isEditMode ? "Update Tracking" : "Setup Tracking"}
                </SetupTrackingButton>
            </Footer>
        </PopupBody>
    </PopupContainer>
}

export default KeywordInputModal