import { useEffect } from "react";
import { useLocation } from "react-router-dom"

import {
    useMutation
} from "@tanstack/react-query"

import { appClient } from "../../../../../utils/axios-utils"
import { getSearchParams } from "../../../../../utils/helper";

import { ResultWrapper, Heading, SubHeading, OptionsContainer } from "./index.styled";

import statics from "../../_static"
import FunnelList from "./FunnelList";
import FunnelNavbar from "./Navbar";
import SwitchBoard from "./SwitchBoard";
import { useDomainFunnelAnalysis } from "../../context/domainFunnelAnalysis";
import { useAuth } from "../../../../../context/auth";
import AdCopies from "./AdCopies";
import { CTAs, ImportantKeywords } from "./CtaAndKeywords";
import TargetAudience from "./TargetAudience";

const FunnelAdsData = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }

    const config = {
        url: `/intelligence/${data.platform}/ads`,
        method: "POST",
        data
    }

    return appClient(config)
}

const FunnelStatsData = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }
    
    const config = {
        url: `/intelligence/${data.platform}/funnel-info`,
        method: "POST",
        data
    }

    return appClient(config)
}
const FunnelAnalyticsData = async (data) => {
    if(data.platform == "meta"){
        data.platform = "facebook"
    }

    const config = {
        url: `/funnelanalytics/google/domain`,
        method: "POST",
        data    
    }

    return appClient(config)
}

function Result() {
    const { authState, authDispatch } = useAuth()
    const location = useLocation()

    const { heading, subHeadingLabel, tempFunnelAdsData, tempFunnelStats } = statics

    const { domainFunnelAnalysisState, domainFunnelAnalysisDispatch }  = useDomainFunnelAnalysis()

    const { adsCountOnEachFunnelType, searchedDomain, activeFunnelType, activePlatform, activeNavItem  } = domainFunnelAnalysisState

    // const { mutate: getFunnelAds, isLoading: isFunnelAdsLoading } = useMutation(FunnelAdsData, {    
    //     onSuccess: (data) => {
    //         if(data){
    //             // console.log(data)
    //             if(data.processed){
    //                 // console.log("inside 1")
    //                 domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: data.ads})
    //                 if(!adsCountOnEachFunnelType){
    //                     domainFunnelAnalysisDispatch({type: 'SET_ADS_COUNT_ON_EACH_FUNNEL', value: data.funnelCount})
    //                 }
    //                 getFunnelStats({
    //                     "query": searchedDomain,
    //                     "funnelType": activeFunnelType.name.toLowerCase(),
    //                     "platform": activePlatform.name.toLowerCase()
    //                 })
    //                 domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
    //             }else{
    //                 // console.log("inside 2")
    //                 setTimeout(() => {
    //                     domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
        
    //                     getFunnelAds({
    //                         "query": searchedDomain,
    //                         "funnelType": activeFunnelType.name.toLowerCase(),
    //                         "count": 300,
    //                         "platform": activePlatform.name.toLowerCase()
    //                     })
                        
                    
    //                 }, 30000);
    //             }
    //         }else{
    //             domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: []})
    //             // domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_COUNT', value: {}})
    //             domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
    //         }    
    //     },
    //     onError: (err) => {
    //         console.log('err on getfunnelads', err)
    //     }
    // })

    // const { mutate: getFunnelStats, isLoading: isFunnelStatsLoading } = useMutation(FunnelStatsData, {    
    //     onSuccess: (data) => {
    //         if(data){
    //             domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_CTA_DATA', value: data.ctaText})
    //             domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_KEYWORDS_DATA', value: data.keywords})
    //             domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_TARGET_AUDIENCE_DATA', value: { 
    //                 demographicAudience: data.demographicAudience,
    //                 psychographicAudience: data.psychographicAudience
    //             }})
    //             domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: false})
    //             // domainFunnelAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: false})

    //         }    
    //     },
    //     onError: (err) => {
            
    //     }
    // })
    const { mutate: getFunnelData, isLoading: isFunnelDataLoading } = useMutation(FunnelAnalyticsData, {    
        onSuccess: (data) => {
            if(data){
              domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_DOMAIN_DATA', value: data})
            //   domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_CTA_DATA', value: data.ctaText})
            //               domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_KEYWORDS_DATA', value: data.keywords})
            //               domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_TARGET_AUDIENCE_DATA', value: { 
            //                   demographicAudience: data.demographicAudience,
            //                   psychographicAudience: data.psychographicAudience
            //               }})
            //               domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: false})
                          // domainFunnelAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: false})
            }    
        },
        onError: (err) => {
            
        }
    })
    const tempGetAdsDataSuccess = (data) => {
        if(data){
                if(data.processed){
                    // domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: data.ads})
                    if(!adsCountOnEachFunnelType){
                        domainFunnelAnalysisDispatch({type: 'SET_ADS_COUNT_ON_EACH_FUNNEL', value: data.funnelCount})
                    }
                    // getFunnelStats({
                    //     "query": searchedDomain,
                    //     "funnelType": activeFunnelType.name.toLowerCase(),
                    //     "platform": activePlatform.name.toLowerCase()
                    // })
                    tempGetFunnelStatsSuccess(tempFunnelStats)
                    domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
                }else{
                    setTimeout(() => {
                        domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
        
                        // getFunnelAds({
                        //     "query": searchedDomain,
                        //     "funnelType": activeFunnelType.name.toLowerCase(),
                        //     "count": 300,
                        //     "platform": activePlatform.name.toLowerCase()
                        // })
                        
                    
                    }, 30000);
                }
        }else{
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS', value: []})
            // domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_COUNT', value: {}})
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: false})
        }    
    }

    const tempGetFunnelStatsSuccess = (data) => {
        if(data){
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_CTA_DATA', value: data.ctaText})
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_KEYWORDS_DATA', value: data.keywords})
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_TARGET_AUDIENCE_DATA', value: data.targetAudience})
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: false})
            // domainFunnelAnalysisDispatch({type: 'SET_INITIAL_FUNNEL_LOADING', value: false})

        }  
    }

    useEffect(() => {
        const searchParamFromState = getSearchParams(location.search)
        const {
            keyword, country
        } = searchParamFromState
        getFunnelData({"domain": keyword, country: "global"})
        if(!authState.features?.isKeywordAnalysisAllowed){
            authDispatch({type: 'UPDATE_IS_RESTRICTED', value: true})
        }else{
            if(keyword){
                // setLocalUrl(url)
                // setLocalSearchMode(searchMode)
                domainFunnelAnalysisDispatch({type: 'SET_SEARCHED_KEYWORD', value: keyword})
            }
        }
    }, [location.search])

    useEffect(() => {

        if(searchedDomain){
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_ADS_LOADING', value: true})
            domainFunnelAnalysisDispatch({type: 'SET_FUNNEL_STATS_LOADING', value: true})
            // getFunnelAds({
            //     "query": searchedDomain,
            //     "funnelType": activeFunnelType.name.toLowerCase(),
            //     "count": 100,
            //     "platform": activePlatform.name.toLowerCase()
            // })
            tempGetAdsDataSuccess(tempFunnelAdsData)
        }
    }, [activeFunnelType, searchedDomain])

    const getContent = () => {
        const { id } = activeNavItem
        switch(id){
            case 1:
                return <AdCopies isFunnelDataLoading={isFunnelDataLoading}/>
            
            case 2:
                return <CTAs isFunnelDataLoading={isFunnelDataLoading}/>
            
            case 3:
                return <TargetAudience isFunnelDataLoading={isFunnelDataLoading}/>

            case 4:
                return <ImportantKeywords isFunnelDataLoading={isFunnelDataLoading}/>
            
            default:
                break
        }
        
    }
    return (
        <ResultWrapper>
            <Heading>{heading}</Heading>
            <SubHeading>{subHeadingLabel(20000)} <searchedDomain>{searchedDomain}</searchedDomain> </SubHeading>
            <FunnelList isFunnelDataLoading={isFunnelDataLoading}/>
            <OptionsContainer>
                <FunnelNavbar />
                <SwitchBoard />
            </OptionsContainer>
            {getContent()}
        </ResultWrapper>
    );
}

export default Result