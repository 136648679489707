import styled from "styled-components";

export const KeywordsWrapper = styled.div`
    border: 1px solid #E3E3E3;
    background: #FFF;
    height: auto;
    padding: 24px 26px 45px 32px;

`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const CtaContainer = styled.div`
    display: flex;
    gap: 16px;
`;

export const DeleteButton = styled.button`
    width: 104px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #111926;
    border-radius: 8px;
    background: #FFF;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: ${props => props.disabled ? "unset" : "pointer"};
    opacity: ${props => props.disabled ? "0.3": "1"};
`;

export const AddNewButton = styled.button`
    width: 164px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #FFF;
    border-radius: 8px;
    background: #111926;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export const TableWrap = styled.div`

`

export const TableHeader = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(234, 236, 240);
    background: rgb(249, 249, 249);
    > div{
        padding: 12px 24px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #667085;
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
    }
`
export const KeyHeader = styled.div`
    cursor: pointer;
    display: flex;

    input{
        cursor: pointer;
        height: 14px;
        width: 14px;
    }
`
export const KeywordHeader = styled.div`

`
export const CampaignCountHeader = styled.div`

`
export const AdsCountHeader = styled.div`

`
export const AvgMonthlySearchHeader = styled.div`

`
export const QuaterlyChangesHeader = styled.div`

`
export const AvgCpcHeader = styled.div`

`
export const TrendHeader = styled.div`

`
export const TableBody = styled.div`
    overflow-y: auto;
    min-height: 265px;
    max-height: 500px;

`

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border-bottom: 1px solid #EAECF0;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #667085;
    .table-header{
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
    }

    .no-change{
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #364254;
        border-radius: 16px;
        background: #F2F4F7;
        padding: 2px 8px;
    }

    .quaterlyChanges{
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .keyword{
        color: #101828;
        font-weight: 500;
    }

    .checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 16px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 6px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    }

    /* On mouse-over, add a grey background color */
    .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
    border: solid 1px #D0D5DD;
    }

    /* When the checkbox is checked, add a blue background */
    .checkbox-container input:checked ~ .checkmark {
    background-color: #0070FF;
    border: solid 1px #0070FF;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    }

    /* Show the checkmark when checked */
    .checkbox-container input:checked ~ .checkmark:after {
    display: block;
    }

    /* Style the checkmark/indicator */
    .checkbox-container .checkmark:after {
        left: 6px;
        top: 3px;
        width: 4px;
        height: 8px;
        border-style: solid;
        border-color: white;
        border-image: initial;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
    }
`