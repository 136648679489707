import React,{useEffect} from 'react'
import { AdDistributionWrap, MapsContainer, MapsContainerBody, CountryChartContainer } from './index.styled'
import MapChart from "../MapChart"
import MapChartTable from "../MapChartTable"
import { getSearchParams } from '../../../../../../utils/helper'
import { useLocation } from 'react-router-dom'
import { useDomainOverview } from '../../../context/domainoverview'
import { useDomainOverviewInfo } from '../../../hooks/useDomainOverviewInfo'
import Tooltip from 'rc-tooltip'
import helpCircle from '../../../../../../media/Dashboard/help-circle.svg'

const AdDistribution = () => {
  
  const location = useLocation()
  const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()
  const useDomainOverviewInfoHook = useDomainOverviewInfo()

  useEffect(() => {
    const searchParamFromState = getSearchParams(location.search)

      const {
        domain
      } = searchParamFromState
      
      useDomainOverviewInfoHook.getMapDataMutation({"query": domain})
  }, [])

  return (
    <AdDistributionWrap>
            <MapsContainer>
                <h4>Ad Distribution by country<Tooltip trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>Total ads found in each country</span>}>
                <img src={helpCircle}/>     
                    </Tooltip></h4>
                <MapsContainerBody>
                    <CountryChartContainer>
                        <MapChart />
                    </CountryChartContainer>
                    <MapChartTable isDataLoading={useDomainOverviewInfoHook.isGetMapDataLoading}/>
                </MapsContainerBody>
            </MapsContainer>
    </AdDistributionWrap>
  )
}

export default AdDistribution