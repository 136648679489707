import React, {useEffect} from 'react'
import Tracking from './component/tracking'
import { AdsContainer, GraphWrapper, Wrapper } from './index.styled'
import ActiveCampaign from './component/activeCampaign'
import TotalAds from './component/totalAds'
import TopDomains from './component/topDomains'
import AdDistribution from './component/adDistribution'
import Trend from './component/trend/index'
import { useKeywordOverview } from '../context/keywordOverview';
import TableComponent from './component/tableComponent/tableComponent'
import { useLocation } from 'react-router-dom'
import { getSearchParams } from '../../../../utils/helper'
import { countriesList } from '../../../../utils/countryData'
import { useKeywordOverviewInfo } from '../hooks/useKeywordOverviewInfo'

const KeywordOverviewDetails = () => {
  const {keywordOverviewState, keywordOverviewDispatch} = useKeywordOverview()
  const useKeywordOverviewInfoHook = useKeywordOverviewInfo()
  const location = useLocation()

  const handleCountrySearch = (data) => {
    let searchTerm = data;
    if (!searchTerm.trim()) {
        keywordOverviewDispatch({ type: 'SET_OVERVIEW_COUNTRY', value: {} });
        return; // Return early if searchTerm is empty
    }
    let country = countriesList.find((country) => country.name.common.toLocaleLowerCase() === searchTerm.toLocaleLowerCase())
    keywordOverviewDispatch({ type: 'SET_OVERVIEW_COUNTRY', value: country });
  }

  useEffect(() => {
    const searchParamFromState = getSearchParams(location.search)

    const {
      keyword,
      country
    } = searchParamFromState
    keywordOverviewDispatch({type: 'SET_KEYWORD_OVERVIEW_INPUT', value: keyword})
    handleCountrySearch(country)
    useKeywordOverviewInfoHook.getAdStatsMutation({ keyword, country: country ? country : "global" });
    useKeywordOverviewInfoHook.getTopDomainsMutation({ keyword, country: country ? country : "global" });
    useKeywordOverviewInfoHook.getHeatmapDataMutation({ keyword,country: country ? country : "global" });
    useKeywordOverviewInfoHook.trackingAnalysisApi({ keyword: keyword, country  });
  }, [location.search])

  return (
    <Wrapper>
        <Tracking />
        <AdsContainer>
          <GraphWrapper>
              <ActiveCampaign isTableDataLoading={useKeywordOverviewInfoHook.isGetAdStatsLoading}/>
              <TotalAds isTableDataLoading={useKeywordOverviewInfoHook.isGetAdStatsLoading}/>                                                       
          </GraphWrapper>
          <TopDomains isTopDomainsLoading={useKeywordOverviewInfoHook.isGetTopDomainsLoading}/>
        </AdsContainer>
        <AdDistribution isTableDataLoading={useKeywordOverviewInfoHook.isGetAdStatsLoading}/>
        <Trend isHeatMapDetailsLoading={useKeywordOverviewInfoHook.isGetHeatmapDataLoading} keyword={keywordOverviewState.overviewInput}/>
        <TableComponent isTrackingAnalysisLoading={useKeywordOverviewInfoHook.isTrackingAnalysisLoading} isOverviewAnalysisLoading={useKeywordOverviewInfoHook.isOverviewAnalysisLoading}/>
    </Wrapper>
  )
}

export default KeywordOverviewDetails   