import React from "react";
import { SearchContainer } from "./index.styled"
import PlatformDropDown from "./components/Platform"
import KeywordComponent from "./components/Keyword"
import UrlComponent from "./components/Url";
import { useAuth } from "../../../../context/auth";

const ResultPageSearch = () => {

    const {authState, authDispatch} = useAuth()
    
    return <SearchContainer>
                <PlatformDropDown/>
                {
                    authState.searchMode === "keyword" ?             
                    <KeywordComponent/> : <UrlComponent/>
                }
            </SearchContainer>
}

export default ResultPageSearch