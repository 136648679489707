import React, {useEffect, useRef} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'


// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'

import {useBingEcomSearch} from "../context/bingecom"

// Icons
import Results from './Results'
import {useSearch} from "../../context/search"
import { getSearchParams, urlPatternValidation, dateFormatter ,formatWithCommas } from "../../../../../../utils/helper.js"
import { useAuth } from '../../../../../../context/auth.js'
import { useBingEcomInfo } from '../hooks/useBingEcomInfo.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'

const BingSearchAds = () => {
    const {bingEcomSearchState, bingEcomSearchDispatch} = useBingEcomSearch()
    const [searchParams, setSearchParams] = useSearchParams()

    const {authState, authDispatch} = useAuth()

    const {searchState, searchDispatch} = useSearch()
    const location = useLocation()

    const useBingEcomInfoHook = useBingEcomInfo()

    const myRef = useRef(null);

    useEffect(() => {
        if(location.pathname.includes("bingpla")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'bingpla'})
        }
    }, [])
    
    useEffect(() => {
        // console.log('search param updated')
        const searchParamFromState = getSearchParams(location.search)

        const {
            searchMode,
            keywords,
            phraseInput,
            domainUrlInput,
            keywordSearchType,
            urlSearchType,
            searchIn,
            minimumShouldMatch,
            excludedTerm,
            page,
            selectedCountries,
            startDate,
            endDate,
            sortType
        } = searchParamFromState

        bingEcomSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: false})

        if(searchMode){
            if(searchMode == "keyword"){
                authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                if(keywordSearchType){
                    authDispatch({type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })
                    if(keywordSearchType == "phrase"){
                        if(phraseInput){
                            authDispatch({type: 'SET_PHRASE_INPUT', value: phraseInput})
                        }
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    bingEcomSearchDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    bingEcomSearchDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }
                            })
                        }
                    }
                    if(keywordSearchType == "broad"){
                        if(keywords && keywords.length > 0){
                            authDispatch({type: 'SET_KEYWORDS', value: keywords.split(",")})
                        }

                        bingEcomSearchDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 }})
                        
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    bingEcomSearchDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    bingEcomSearchDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: false
                                    }})
                                }
                            })
                        }
                    }
                    if(excludedTerm){
                        bingEcomSearchDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm})
                    }
                }
            }

            if (searchMode == "url"){
                authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                if(urlSearchType){
                    authDispatch({type: 'SET_URL_SEARCHTYPE', value: urlSearchType })
                    if(urlSearchType == "exact"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                        }
                    }
                    if(urlSearchType == "domain"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                        }
                    }
                    if(urlSearchType == "text"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: domainUrlInput.length > 0}})
                        }
                    }
                }
            }

            if(selectedCountries && selectedCountries.length > 0){
                bingEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
            }else{
                bingEcomSearchDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
            }

            if(startDate){
                bingEcomSearchDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
            }else{
                bingEcomSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(endDate){
                bingEcomSearchDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
            }else{
                bingEcomSearchDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(sortType){
                bingEcomSearchDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                    name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                    type: sortType
                }})
            }

            if(page){
                bingEcomSearchDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
            }

        }else{
            bingEcomSearchDispatch({type: 'CLEAR_ALL'})
        }

        // on filter applied
        if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){
            // console.log("inside filter")
            if (searchMode == "keyword") {
                if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                    if (keywordSearchType == "broad") {
                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")
                        

                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useBingEcomInfoHook.keywordBoardFilterMutate(reqBody)
                    } else if (keywordSearchType == "phrase") {
                        // console.log("innnnnnnnnn")
                        let fields = searchIn.split(",")
                        

                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useBingEcomInfoHook.keywordPhraseFilterMutate(reqBody)
                    }
                }
            } else if (searchMode == "url") {
                if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                    if (urlSearchType == "domain") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useBingEcomInfoHook.urlDomainFilterMutate(reqBody)
                    } else if (urlSearchType == "text") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useBingEcomInfoHook.urlTextFilterMutate(reqBody)
                    } else if (urlSearchType == "exact") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType
                        }
                        bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useBingEcomInfoHook.urlExactFilterMatchMutate(reqBody)
                    }
                }
            }
        }else{
            // console.log("without filter")
            // on not filter applied
                if (searchMode == "keyword") {
                    if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                        if (keywordSearchType == "broad") {
                            let fields = searchIn.split(",")
                            let query = keywords.split(",").join(" ")
                    
                            const reqBody = {
                                query,
                                minimumShouldMatch: minimumShouldMatch,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page
                            }
                            bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useBingEcomInfoHook.keywordBroadMutate(reqBody)
                        } else if (keywordSearchType == "phrase") {
                            let fields = searchIn.split(",")
                            
                            const reqBody = {
                                query: phraseInput,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page
                            }
                            bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useBingEcomInfoHook.keywordPhraseMutate(reqBody)
                        }
                    }
                } else if (searchMode == "url") {
                    if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                        if (urlSearchType == "domain") {
                            const reqBody = {
                                query: domainUrlInput,
                                page: page
                            }
                            bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useBingEcomInfoHook.urlDomainMutate(reqBody)
                        } else if (urlSearchType == "text") {
                    
                            const reqBody = {
                                query: domainUrlInput,
                                page: page
                            }
                            bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useBingEcomInfoHook.urlTextMutate(reqBody)
                        } else if (urlSearchType == "exact") {
                            const reqBody = {
                                query: domainUrlInput,
                                page: page
                            }
                            bingEcomSearchDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useBingEcomInfoHook.urlExactMutate(reqBody)
                        }
                    }
                }
        }

    }, [location.search])


    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        bingEcomSearchDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }

    // return <Container ref={myRef}>
    //     <SearchContainer>
    //         <ResultPageSearch/>
    //     </SearchContainer>
    //     <ScrollableContainer>
    //     {
    //         !searchState.limitExceeded && < Filters />
    //     }
    //     {
    //     bingEcomSearchState.ads && bingEcomSearchState.ads.length > 0 && (
    //         <AdsCount>
    //             <div><p>
    //                 <span>bingEcom Search ads{" "}</span>
    //                 - {(bingEcomSearchState.currentPageNo - 1) * 20 != 0 ? (bingEcomSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((bingEcomSearchState.currentPageNo - 1) * 20) + 20) > bingEcomSearchState.totalAds ? bingEcomSearchState.totalAds : ((bingEcomSearchState.currentPageNo - 1) * 20) + 20} of <span>{
    //                     bingEcomSearchState.totalAds
    //                 }</span>
    //                     {" "}results
    //             </p></div>
    //             <SearchQuery> {
    //                 bingEcomSearchState.keywords.length > 0 ? (bingEcomSearchState.keywords.map((word, idx) => {
    //                     return <span>{word}</span>;
    //                 })) : bingEcomSearchState.phraseInput ? (
    //                     <span>{
    //                         bingEcomSearchState.phraseInput
    //                     }</span>
    //                 ) : (
    //                     <span>{
    //                         bingEcomSearchState.domainUrlInput.value
    //                     }</span>
    //                 )
    //             } </SearchQuery>
    //         </AdsCount>
    //     )
    // }
    //     <Results/> {
    //     !searchState.limitExceeded && bingEcomSearchState.ads && bingEcomSearchState.ads.length > 0 && <Pagination/>
    // }
    // </ScrollableContainer>
    // </Container>

    return <Container ref={myRef}>
    {

            <>
                <Breadcrumb>
                    {"Overview > "} <span>{authState.searchMode === "keyword" ? "Text Search in Ad library" : "Url Search in Ad library"}</span>
                </Breadcrumb>
                <Header>
                    <HeaderLeft>
                        <h2>BingEcom Search ads</h2>
                    </HeaderLeft>
                    <HeaderRight>
                        <TotalAdsFound>
                            <label>Total ads found :</label>
                            <span>
                                {formatWithCommas(bingEcomSearchState.totalAds)}
                            </span>
                        </TotalAdsFound>
                        <SortType />
                        <MajorFilters />
                    </HeaderRight>
                </Header>
                <AppliedFilters>
                    {bingEcomSearchState.selectedCountries.length > 0 && <AppliedFilterItem>
                        <p>{bingEcomSearchState.selectedCountries.length == 1 ? bingEcomSearchState.selectedCountries[0] : bingEcomSearchState.selectedCountries.length > 1 ? bingEcomSearchState.selectedCountries[0] + " + " + (
                            bingEcomSearchState.selectedCountries.length - 1
                        ) : "no country"}</p>
                        <Close onClick={()=>ClearCountry()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {bingEcomSearchState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                        <p>{bingEcomSearchState.selectedSortType.name}</p>
                        <Close onClick={()=>ClearSortType()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {bingEcomSearchState.dateFilterType.length > 0 && <AppliedFilterItem>
                        <p>{bingEcomSearchState.dateFilterType}</p>
                        <Close onClick={()=>ClearDateFilter()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {/* {(bingEcomSearchState.selectedCta?.length > 0 || bingEcomSearchState.selectedTone?.length > 0 || bingEcomSearchState.sentimentFilter?.length > 0 || bingEcomSearchState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                        <p>Advanced filters</p>
                        <Close onClick={()=>OpenAdvancedFilter()}>
                            x 
                        </Close>
                    </AppliedFilterItem>} */}
                </AppliedFilters>
                <ScrollableContainer>
                    <Results />
                    <Footer>
                        {(!authDispatch.limitExceeded && bingEcomSearchState.ads && !bingEcomSearchState.loadingResults && bingEcomSearchState.ads.length > 0) && <TotalResults>
                            <p>Showing {(bingEcomSearchState.currentPageNo - 1) * 20 != 0 ? (bingEcomSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((bingEcomSearchState.currentPageNo - 1) * 20) + 20) > bingEcomSearchState.totalAds ? bingEcomSearchState.totalAds : ((bingEcomSearchState.currentPageNo - 1) * 20) + 20} of <span>{
                                bingEcomSearchState.totalAds
                            }</span>
                            results</p>
                        </TotalResults>}
                        {!authDispatch.limitExceeded && bingEcomSearchState.ads && !bingEcomSearchState.loadingResults && bingEcomSearchState.ads.length > 0 && <Pagination />}
                    </Footer>
                </ScrollableContainer>
            </>
        }
    </Container>
}

export default BingSearchAds
