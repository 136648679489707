const ValidateAndAddHttps = (input) => {
    // Remove any leading and trailing whitespaces
    input = input.trim();
    const url = new URL(input)
    const urlDomain = url.origin;
  // Regular expression to match a valid domain
    const domainRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  
    if (domainRegex.test(urlDomain) && (urlDomain.includes("www.") ? (urlDomain.split('.').length - 1) > 1 :  true)) {
      // Check if "https://" or "http://" is already present, and add "https://" if not
      if (!urlDomain.startsWith("http://") && !urlDomain.startsWith("https://")) {
        input = "https://" + urlDomain;
      }
      return urlDomain;
    } else {
      return false;
    }
  }

  export default ValidateAndAddHttps

  