import React from 'react'
import { Container, EditWrap, ProductName, ProductNameWrap, ProductWrap, RefreshWrap, TrackingDiv, UpdatedDiv, UpdatedWrap, BackButton, TrackDomainButton, ResultsContainer } from './index.styled'
import refresh from '../../../../../../media/Dashboard/refresh.svg'
import { useDomainOverview } from '../../../context/domainoverview'
import CountryDropDown from '../CountryDropDown'
import LeftArrow from "../../../../../../media/Dashboard/backarrow.svg"
import CrossHair from "../../../../../../media/Dashboard/crosshair.svg"
import DomainInputModal from './DomainInputModal/index'
import { useNavigate } from 'react-router-dom'

const Tracking = () => {
  const {domainOverviewState, domainOverviewDispatch} = useDomainOverview()
  const navigate = useNavigate()

  return (
    <Container>
      {
        domainOverviewState.domainInputModalOpen && 
        <ResultsContainer>
          <DomainInputModal/>
        </ResultsContainer>
      }
      <EditWrap>
        <BackButton src={LeftArrow} onClick={() => navigate('/domain-overview')}/>
        <TrackingDiv>
          <CountryDropDown/>
          <TrackDomainButton onClick={()=> {
             domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: true})
             domainOverviewDispatch({type: 'SET_DOMAIN_INPUT_LIST', value: [{ domain: domainOverviewState.domainInput, platform: '', country: domainOverviewState.domainCountry, platformError: '' }]})
          }}>
            <img src={CrossHair}/>Track domain
          </TrackDomainButton>
        </TrackingDiv>
      </EditWrap>
      <ProductWrap>
        <ProductNameWrap>
          <ProductName>{domainOverviewState.domainInput}</ProductName>
          <UpdatedWrap>
            <RefreshWrap><img src={refresh}/></RefreshWrap>
            <UpdatedDiv>updated recently</UpdatedDiv>
          </UpdatedWrap>
        </ProductNameWrap>
      </ProductWrap>
    </Container>
  )
}

export default Tracking