import styled from "styled-components";


export const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  input {
    display: none;
  }
  /* @media screen and (max-width: ${(props) =>
    props.theme.breakPoints.desktop}) {
      input{
        display: none;
      }
    } */
  input {
    margin-top: 6px;
    margin-right: 8px;
  }
`;

export const HeaderRight = styled.div`
  h3{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  h3 p{
    font-weight: 400;
    text-decoration: line-through;
    color: rgb(158, 158, 158);
  }
  h3 span{
    color: #388e3c;
    font-weight: 500;
    font-size: 12px;
    margin-left: 6px;
    margin-bottom: 2px;
  }
`;

export const BasicDetail = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #707070;
  margin-bottom: 15px;
`;

export const CardBody = styled.div``;

export const Detail = styled.p`
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 12px;
`;

export const PlanPaymentButton = styled.div`
    width: 234px;
    height: 40px;
    margin-top: 20px;
    border-radius: 8px;
    /* background-color: ${(props) =>
    props.currentPlan ? "#FFD0C0" : "#FF711E"}; */
    color: ${(props) => (props.currentPlan ? "#119F29" : "#FF711E")};
    font-size: 16px;
    font-weight: 500;
    border: ${(props) =>
    props.currentPlan ? "solid 1px #119F29" : "solid 1px #FF711E"};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    pointer-events: ${(props) =>
    props.isSubscriptionLoading || props.disable ? "none" : "auto"};
    :hover {
    /* filter: brightness(90%); */
    }
`;

export const Card = styled.div`
    /* height: 407px; */
    /* width: 270px; */
    min-width: 270px;
    max-width: 270px;
    height: 100%;
    padding: 16px;
    background: #f9f9f9;
    border: 1px solid #f2f2f2;
    pointer-events: ${(props) => (props.currentPlan ? "none" : "pointer")};
    /* background: ${(props) => (props.currentPlan ? "#9e9e9e4d" : "#FFFFFF")}; */
    background: #f9f9f9;
    /* background: ${(props) =>
    props.currentPlan
        ? "#9e9e9e4d"
        : props.isSelected
        ? "#F0F7FF"
        : "#FFFFFF"}; */
    /* background: ${(props) => (props.isSelected ? "#F0F7FF" : "#FFFFFF")};*/
    border: ${(props) =>
    props.currentPlan ? "1px solid #8EE39C" : "1px solid #F2F2F2"};
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    position: relative;
    /* @media screen and (max-width: ${(props) =>
    props.theme.breakPoints.desktop}) {
        height: 450px;
        padding: 16px;
        background: #F9F9F9;
        border: 1px solid #F2F2F2;
    }
    @media screen and (min-width: ${(props) =>
    props.theme.breakPoints.desktop}) {
        pointer-events: ${(props) => (props.currentPlan ? "none" : "pointer")};
        background: ${(props) =>
    props.currentPlan ? "#9e9e9e4d" : props.isSelected ? "#F0F7FF" : "#FFFFFF"};
    } */
`;

export const PlanName = styled.div`
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    color: #2e2e2e;
    line-height: 25px;
    margin-bottom: 2px;
`; 

export const Price = styled.div`
  font-size: 28px;
  color: #2e2e2e;
  line-height: 35px;
  font-weight: 600;
  display: flex;
  
  p {
    font-size: 28px;
    letter-spacing: -1.68px;
    color: #2e2e2e;
    line-height: 35px;
    font-weight: 600;
  }
  span {
    color: #2e2e2e;
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: 400;
  }

  > span{
    font-size: 28px;
    color: #2e2e2e;
    line-height: 35px;
    font-weight: 600;
    display: flex;
  }
`;
