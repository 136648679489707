import React, { useEffect, lazy, Suspense } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from 'react-router-dom'
import toast from "react-hot-toast"
import { useMutation } from "@tanstack/react-query"

import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

// import PaymentSuccessPage from './pages/Message/success'
// import PaymentCancelPage from './pages/Message/cancel'

// Pages
import LoginPage from "./pages/AuthPages/Login";
import SignupPage from "./pages/AuthPages/Signup";
import ChangePassword from "./pages/AuthPages/ChangePassword";
import Plans from "./pages/PaymentPages/Plans";
import ConfirmEmail from "./pages/AuthPages/ConfirmEmail";
import Layout from "./layout";
import PostLoginSurvey from "./pages/AuthPages/PostLoginSurvey";
import AccountPage from "./pages/AccountPages";
import AdGenerationPage from "./pages/AdGeneration";

// import AdGenerationNew from "./pages/AdGenerationNew"

import KeywordAnalysis from "./pages/KeywordAnalysis";

// import LandingPageAnalysis from "./pages/LandingPageAnalysis";
import UrlAnalysisProviderWrapper from "./pages/UrlAnalysis";

// import KeywordFunnelAnalyticsProviderWrapper from "./pages/KeywordFunnelAnalytics";
// import DomainFunnelAnalyticsProviderWrapper from "./pages/DomainFunnelAnalytics";

// import AdspyPage from "./pages/AdPages/Adspy/Adspy";
// import NotFound from "./pages/NotFoundPage";
import Tracking from "./pages/Tracking"
// import DomainTracking from "./pages/DomainTracking"

// import PaymentSuccess from "./pages/PaymentPages/paymentSucces";
// import PaymentFailure from "./pages/PaymentPages/paymentFailure";

import Welcome from "./pages/SecondaryPages/Welcome";

// Layouts
import ProtectedLayout from "./HOC/Protected";
import PublicLayout from "./HOC/Public";
import ProtectedEmailVerifiedLayout from "./HOC/ProtectedEmailVerify";
import ProtectedOnboardingLayout from "./HOC/ProtectedOnboarding";
import GlobalLoader from "./HOC/GlobalLoader";
import ProtectedPlansLayout from "./HOC/ProtectedPlansLayout";
import WelcomeLayout from "./HOC/WelcomeLayout";

import { useAuth } from "../context/auth";

import { appClient } from "../utils/axios-utils";
import { useGetUserInfo } from "./hooks/useGetUserInfo";

import { useSaveAds } from "./hooks/useSaveAds";

// Page Component
// import KeywordTracking from "./pages/KeywordTracking";
// import KeywordOverview from './pages/KeywordOverview';
// import DomainOverview from "./pages/DomainOverview";
import AdIntegration from "./pages/AdIntegration";
import AdManagementWrapper from './pages/AdManagement';
import OverviewProviderWrapper from "./pages/Overview";
// import ManageProjectsProviderWrapper from "./pages/ManageProjects";
// import SavedAds from "./pages/SavedAds";

//Lazy Components
// const PaymentSuccess = lazy(() => import("./pages/PaymentPages/paymentSucces"))
// const PaymentFailure = lazy(() => import("./pages/PaymentPages/paymentFailure"))
// const NotFound = lazy(() => import("./pages/NotFoundPage"))
// const SavedAds = lazy(()=> import("./pages/SavedAds"))
// const ManageProjectsProviderWrapper = lazy(() => import("./pages/ManageProjects"))
// const AdspyPage = lazy(() => import("./pages/AdPages/Adspy/Adspy"))
// const DomainOverview = lazy(() => import("./pages/DomainOverview"))
// const DomainFunnelAnalyticsProviderWrapper = lazy(() => import("./pages/DomainFunnelAnalytics"))
// const DomainTracking = lazy(() => import("./pages/DomainTracking"))
// const KeywordOverview = lazy(() => import("./pages/KeywordOverview"))
// const KeywordFunnelAnalyticsProviderWrapper = lazy(() => import("./pages/KeywordFunnelAnalytics"))
// const KeywordTracking = lazy(() => import("./pages/KeywordTracking"))
// const LandingPageAnalysis = lazy(() => import("./pages/LandingPageAnalysis"))
// const AdGenerationNew = lazy(() => import("./pages/AdGenerationNew"))

// normal page imports
import PaymentSuccess from "./pages/PaymentPages/paymentSucces"
import PaymentFailure from "./pages/PaymentPages/paymentFailure"
import NotFound from "./pages/NotFoundPage"
import SavedAds from"./pages/SavedAds"
import ManageProjectsProviderWrapper from "./pages/ManageProjects"
import AdspyPage from "./pages/AdPages/Adspy/Adspy"
import DomainOverview from "./pages/DomainOverview"
import DomainFunnelAnalyticsProviderWrapper from "./pages/DomainFunnelAnalytics"
import DomainTracking from "./pages/DomainTracking"
import KeywordOverview from "./pages/KeywordOverview"
import KeywordFunnelAnalyticsProviderWrapper from "./pages/KeywordFunnelAnalytics"
import KeywordTracking from "./pages/KeywordTracking"
import LandingPageAnalysis from "./pages/LandingPageAnalysis"
import AdGenerationNew from "./pages/AdGenerationNew"
import DashboardProviderWrapper from "./pages/Dashboard";

const currentUser = () => {
  const config = {
    url: "/auth/current-user",
    method: "GET",
  };

  return appClient(config);
};

const userLocation = () => {
  return axios.get("https://freeipapi.com/api/json");
}; 

const logoutUser = () => {
  const config = {
    url: "/auth/logout",
    method: "DELETE"
  }

  return appClient(config)
}

const Content = () => {
  console.log("index render")
  const navigate = useNavigate();
  const location = useLocation();
  const useGetUserInfoHook = useGetUserInfo()
  const useSaveAdsHook = useSaveAds()
  const { authDispatch, authState } = useAuth();
  let toastId = "";

  useQuery(["user-location"], userLocation, {
    retry: false,
    // refetchInterval: 20000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: ({ data }) => {
      localStorage.setItem(
        "usercurrency",
        data.countryCode != "IN" ? "USD" : "INR"
      );
      useGetUserInfoHook.getCurrentUser()
    },
    onError: (err) => { },
  });


  const { mutate: onLogout } = useMutation(logoutUser, {
    onSuccess: (data) => {
      authDispatch({ type: 'LOGOUT_SUCCESS' })
      toast.success("Session Expired")
      authDispatch({ type: 'STOP_LOADER' })
      toast.dismiss(toastId)
      navigate('/')
    },
    onError: (err) => {
      toast.dismiss(toastId)
    },
    onMutate: () => {
      authDispatch({ type: 'START_LOADER' })
      // toastId = toast.loading("Signing out")
    }
  })

  useEffect(() => {
    // Create the interceptor
    const interceptor = appClient.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          const excludedEndpoints = ["/current-user", "/logout"];

          const shouldLogout = !excludedEndpoints.some(endpoint =>
            error.response.config.url.includes(endpoint)
          );

          if (shouldLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);

      }
    );


    // Clean up the interceptor when the component unmounts
    return () => {
      appClient.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    if(window.clarity){
      window.clarity("set", "email", authState.email ? authState.email : "unknown")
      window.clarity("identify", authState.email ? authState.email : "unknown")
    }

  }, [location.pathname])
  
  if (authState.isLoading) {
    return <GlobalLoader />;
  }

  return (
    <Suspense fallback={<></>}>
      <Routes>
        {/* Not Found Page */}
        <Route path="*" element={<NotFound />} />

        {/* Temp redirect */}
        <Route path='/register' element={<Navigate to="/" />} />

        {/* PUBLIC ROUTES */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<LoginPage />} />

          <Route path="signup" element={<SignupPage />} />

          <Route path="changepassword" element={<ChangePassword />} />
        </Route>

        {/* PRIVATE ROUTES */}

        <Route element={<ProtectedEmailVerifiedLayout />}>
          <Route path="confirmemail" element={<ConfirmEmail />} />
        </Route>

        <Route element={<ProtectedOnboardingLayout />}>
          <Route path="loginsurvey" element={<PostLoginSurvey />} /> 
        </Route>

        <Route element={<WelcomeLayout />}>
          <Route path="welcome" element={<Welcome />} />
        </Route>

        <Route element={<ProtectedPlansLayout />}>
          <Route path="plans" element={<Layout>
            <Plans />
          </Layout>} />
        </Route>

        <Route element={<ProtectedLayout />}>

          <Route path="adspy/*" element={<AdspyPage />} />

          <Route path="account" element={<NotFound />} />

          <Route path="domain-tracking-old" element={<Layout><Tracking /></Layout>} />

          <Route path="domain-tracking/*" element={<DomainTracking />} />

          <Route path="domain-overview/*" element={<DomainOverview />} />

          <Route path="account/*" element={<AccountPage />} />

          <Route path="payment-success" element={<PaymentSuccess />} />

          <Route path="payment-failure" element={<PaymentFailure />} />

          <Route path="keyword-analysis-old/*" element={<KeywordAnalysis />} />

          <Route path="keyword-overview/*" element={<KeywordOverview />} />

          <Route path="keyword-tracking/*" element={<KeywordTracking />} />

          <Route path="keyword-funnel/*" element={<KeywordFunnelAnalyticsProviderWrapper />} />

          <Route path="domain-funnel/*" element={<DomainFunnelAnalyticsProviderWrapper />} />

          <Route path="landing-page-analysis/*" element={<LandingPageAnalysis />} />

          <Route path="url-analysis-old/*" element=
            {<UrlAnalysisProviderWrapper />} />

          <Route path="adgenerator-new/*" element={<AdGenerationPage />} />

          <Route path="adgenerator/*" element={<AdGenerationNew />} />
          <Route path="adintegration/*" element={<AdIntegration />} />
          <Route path="admanagement/*" element={<AdManagementWrapper />} />

          <Route path="projects/*" element={<ManageProjectsProviderWrapper />} />

          {/* <Route path="overview/*" element={<OverviewProviderWrapper />} /> */}
          <Route path="overview/*" element={<DashboardProviderWrapper />} />
          <Route path="saved-ads/*" element={<SavedAds />} />

        </Route>

      </Routes>
    </Suspense>
  );
};

export default Content;
