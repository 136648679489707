import { CircleFlag } from "react-circle-flags";
import { countriesList } from "../../../../../../../../utils/countryData";
import { Check, Country, CountryContainer, Dropdown, DropdownContainer } from "./index.styled";
import check from "./assets/check.svg"
function CountryDropdown({top, bottom, left, right, currentlySelected, onSelect}) {
    return ( 
        <DropdownContainer 
            top={top} bottom={bottom} left={left} right={right}
        >
            <Dropdown>
                {countriesList.map((country) => {
                    return(
                        <CountryContainer onClick={() => onSelect(country)} >
                            <CircleFlag countryCode={country.code.toLowerCase()} height={24} />
                            <Country>{country.name.common}</Country>
                            {currentlySelected.code === country.code && <Check src={check} draggable={false} />}
                        </CountryContainer>
                    )
                })}
            </Dropdown>
        </DropdownContainer>
     );
}

export default CountryDropdown;