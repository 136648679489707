import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    profileCurrentSubPage: "profile",
    isUserDataLoading: true,
    userData: {

    },

    currentPage: null,

    searchHistory: [],
    searchHistoryNewAdsCount: {},

    selectedKeyword: '',
    summaryKeywords: [],
    similarKeywords: [],
    keywordDomains: [],
    enableChangePassword: false
}

const AccountReducer = produce((draft, action) => {
    switch(action.type) {

        case 'UPDATE_PROFILE__SUB_PAGE':
            draft.profileCurrentSubPage = action.value
            break

        case 'UPDATE_IS_USER_DATA_LOADING':
            draft.isUserDataLoading = action.value
            break

        case 'UPDATE_USER_DATA':
            draft.userData = action.value
            break
        
        case 'UPDATE_SELECTED_KEYWORD':
            draft.selectedKeyword = action.value
            break

        case 'UPDATE_SEARCH_HISTORY':
            draft.searchHistory = action.value
            break
        
        case 'UPDATE_SEARCH_HISTORY_NEWADS_COUNT':
            draft.searchHistoryNewAdsCount = action.value
            break
            
        case 'UPDATE_SUMMARY_KEYWORDS':
            draft.summaryKeywords = action.value
            break
        
        case 'UPDATE_SIMILAR_KEYWORDS':
            draft.similarKeywords = action.value
            break

        case 'UPDATE_KEYWORD_DOMAINS':
            draft.keywordDomains = action.value
            break
            
        case 'SET_ENABLE_CHANGE_PASSWORD':
            draft.enableChangePassword = action.value
            break

        case 'SET_CURRENT_PAGE':
            draft.currentPage = action.value
            break
            
        default:
            break
    }
})

const useAccountReducer = () => {
    return useReducer(AccountReducer, initialState)
}

const AccountContext = createContext()

export const AccountProvider = ({ children }) => {
    const [state, dispatch] = useAccountReducer()
    
    const value = { accountState: state, accountDispatch: dispatch }
    
    return <AccountContext.Provider value={value}>
        {children}
    </AccountContext.Provider>
}

export const useAccount = () => {
    return useContext(AccountContext)
}



