import { useEffect } from "react";

export function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
        function handleClickOutside(event) {
    
            if (ref.current && ! ref.current.contains(event.target)) {
                setShow(false)
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside)
    
        return() => {
            document.removeEventListener("mousedown", handleClickOutside)
        };
    }, [ref])
}

