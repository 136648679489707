import { Helmet } from "react-helmet";
import DashboardLayout from "../../layout";
import Root from "./components";
import { DashboardProvider } from "./context/dashboard";

function DashboardComponent() {
    return ( 
        <DashboardLayout>
            <Root />
        </DashboardLayout>
     );
}


function DashboardProviderWrapper() {
    return ( 
        <DashboardProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | AdSpyder</title>
                <meta name="title" content="Project Dashboard | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
            <DashboardComponent />
        </DashboardProvider>
     );
}

export default DashboardProviderWrapper;