import { countryData } from "../../../../utils/countryData"
function getCountryByDialingCode(dialingCode) {
  for (const region of countryData) {
    for (const country of region.countries) {
      if (country.dialling_code === dialingCode) {
        return country;
      }
    }
  }
  return null;
}

export default getCountryByDialingCode