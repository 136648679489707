import React, { useState, useMemo, useEffect } from "react";
import PlusWhiteIcon from "../../../../../../media/Dashboard/plus-white.svg";
import TrashIcon from "../../../../../../media/Dashboard/trash-black.svg";
import DownArrowIcon from "../../../../../../media/Dashboard/arrow-down.svg"
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
} from "@tanstack/react-table";
import ArrowUpGreen from "../../../../../../media/Dashboard/arrow-up-green.svg"
import ArrowDownRed from "../../../../../../media/Dashboard/arrow-down-red.svg"
import {
    KeywordsWrapper,
    Header,
    CtaContainer,
    DeleteButton,
    AddNewButton,
    TableWrap,
    TableBody,
    Row,
    TableHeader,
    KeyHeader,
    KeywordHeader,
    CampaignCountHeader,
    AdsCountHeader,
    AvgMonthlySearchHeader,
    QuaterlyChangesHeader,
    AvgCpcHeader,
    TrendHeader,
    ChartContainer
} from "./index.styled";

import GoogleLogo from '../../../../../../media/Dashboard/Google16.svg'
import BingLogo from '../../../../../../media/Dashboard/Bing16.svg'
import YoutubeLogo from '../../../../../../media/Dashboard/YouTube16.svg'
import MetaLogo from '../../../../../../media/Dashboard/meta16.png'
import LinkedinLogo from '../../../../../../media/Dashboard/LinkedIn16.svg'
import RedditLogo from '../../../../../../media/Dashboard/Reddit16.svg'
import GooglePlaLogo from '../../../../../../media/Dashboard/Googlepla16.svg'
import BingPlaLogo from '../../../../../../media/Dashboard/Bingpla16.svg'
import AmazonLogo from '../../../../../../media/Dashboard/amazon16.svg'
import DisplayLogo from '../../../../../../media/Dashboard/Display16.svg'

import GlobeIcon from "../../../../../../media/Dashboard/GlobeGreyLogo.svg"
import { useDashboard } from "../../../context/dashboard";
import AddNewPopup from "../Popup";
import { useDashboardInfo } from "../../../hooks/useDashboardInfo";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../../../../../utils/formatTotal";
import Skeleton from "react-loading-skeleton";

const CompetitorsTable = () => {
    const [selectedItems, setSelectedItems] = useState([])
    const [sorting, setSorting] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const useDashboardInfoHook = useDashboardInfo()
    const [isAddNewPopupOpened, setAddNewPopupOpened] = useState(false)
    const { dashboardState: {competitorChoices, selectedCountry, competitors, selectedProject}, dashboardDispatch } = useDashboard()
    const navigate = useNavigate()

    const PlatformList = {
        google: GoogleLogo,
        bing: BingLogo,
        youtube: YoutubeLogo,
        facebook: MetaLogo,
        linkedin: LinkedinLogo,
        reddit: RedditLogo,
        googlepla: GooglePlaLogo,
        bingpla: BingPlaLogo,
        amazon: AmazonLogo,
        dispaly: DisplayLogo
    }

    const columnHelper = createColumnHelper();

    const columns = useMemo(() => [
        columnHelper.accessor("no", {
            id: "no",
            header: () => "No",
            cell: (info) => <label class="checkbox-container" >
            <input type="checkbox" checked={selectedItems.includes(info.row.original.userCompetitorRefId) ? "checked" : ""} />
            <span class="checkmark" onClick={() => {
                toggleCheckBox(info.row.original.userCompetitorRefId)
            }}></span>
        </label>,
        }),
        columnHelper.accessor("domainName", {
            id: "domainName",
            header: () => "Domain",
            cell: (info) => <p><img src={`https://www.google.com/s2/favicons?domain=${info.getValue()}`}/>{info.getValue()}</p>,
        }),
        columnHelper.accessor("totalAds", {
            id: "totalAds",
            header: () => "Total Ads",
            cell: (info) => formatNumber(info.getValue()),
        }),
        columnHelper.accessor("competingKeywords", {
            id: "competingKeywords",
            header: () => "Competing Keyword",
            cell: (info) => {
               return info.getValue() ? info.getValue().length : <Skeleton height={"24px"} width={"100%"}/> 
            }
        }),
        // columnHelper.accessor("adsFrequency", {
        //     id: "adsFrequency",
        //     header: () => "Avg Ads Frequency",
        //     cell: (info) => info.getValue(),
        // }),
        columnHelper.accessor("platformTargeted", {
            id: "platformTargeted",
            header: () => "Platforms Targeted",
            cell: (info) => <div>
                {
                    info.row.original.platformTargeted && Object.keys(info.row.original.platformTargeted).map(d => {
                        if(info.row.original.platformTargeted[d]){
                            return <img src={PlatformList[d]}/>
                        }
                    })
                }
            </div>,
        }),
        columnHelper.accessor("avgCpc", {
            id: "avgCpc",
            header: () => "Avg CPC",
            cell: (info) => {return "$" + info.getValue()},
        }),
        columnHelper.accessor("cta", {
            id: "cta",
            header: () => "",
            cell: (info) => {return info.row.original.domainName ? <span onClick={()=>navigate(`/adspy/google?searchMode=url&urlSearchType=exact&domainUrlInput=${info.row.original.domainName}&page=1&sortType=latest`)}>view ads</span> : ""},
        }),
    ], [columnHelper]);

    const columnWidth =
    {
        no: { width: "2", minWidth: "15px" },
        domainName: { width: "25", minWidth: "250px" },
        totalAds: { width: "10", minWidth: "110px" },
        competingKeywords: { width: "10", minWidth: "110px" },
        // adsFrequency: { width: "10", minWidth: "110px" },
        platformTargeted: { width: "20", minWidth: "130px" },
        avgCpc: { width: "10", minWidth: "110px" },
        cta: { width: "10", minWidth: "100px" }
    }

    const table = useReactTable({
        data: competitors,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    const toggleCheckBox = (userCompetitorRefId) => {
        if (selectedItems.includes(userCompetitorRefId)) {
            let finteredItems = selectedItems.filter(d => {
                return d != userCompetitorRefId
            })

            setSelectedItems(finteredItems)
        } else {
            setSelectedItems([...selectedItems, userCompetitorRefId])
        }
    }

    const toggleAllItems = () => {
        if (competitors.length === selectedItems.length) {
            setSelectedItems([])
        } else {
            let finalSelectedItems = competitors.map(d => {
                return d.userCompetitorRefId
            })

            setSelectedItems(finalSelectedItems)
        }
    }

    const openAddNewPopup = () => {
        // dashboardDispatch({
        //     type: "SET_ADD_NEW_POPUP_OPENED",
        //     value: true
        // })
        setAddNewPopupOpened(true)
    }

    const closeAddNewPopup = () => {
        // dashboardDispatch({
        //     type: "SET_ADD_NEW_POPUP_OPENED",
        //     value: false
        // })
        setAddNewPopupOpened(false)
    }

    const deleteCompetitors = () => {
        useDashboardInfoHook.deleteCompetitors({userCompetitorRefIds: selectedItems})
        setSelectedItems([])
    }

    useEffect(() => {
        if(selectedProject){
            useDashboardInfoHook.getCompetitorsData(selectedProject.id)
            setSelectedItems([])
        }
        // useDashboardInfoHook.getCompetitorsData("001b6538-7bd4-4d41-8fb4-c4b6c8e2efde")
        // setSelectedItems([])
    }, [])

    const handleSetup = (chosenChoicesIndices, userSelectedList) => { 
        const competitorsData = chosenChoicesIndices.map((index) => {
            return{
                domain: competitorChoices[index].domainName,
                source: "suggested"
            }
        })
        const userEnteredCompetitorData = userSelectedList.map((data) => {
            return{
                domain: data,
                source: "user",
                country: selectedCountry
            }
        })
        useDashboardInfoHook.addCompetitorsForTrackingMutate({
        projectId: selectedProject.id,
        domains: [...competitorsData,...userEnteredCompetitorData]
        })
        closeAddNewPopup()
    }

    return (competitors.length > 0) && 
        <KeywordsWrapper>
            <Header>
                <h2>Competitor Ad Performance Metrics</h2>
                <CtaContainer>
                    <DeleteButton disabled={selectedItems.length == 0} onClick={deleteCompetitors}>
                        <img src={TrashIcon} alt="Delete" />
                        Delete
                    </DeleteButton>
                    <AddNewButton onClick={openAddNewPopup}>
                        <img src={PlusWhiteIcon} alt="Add" />
                        Add new Competitor
                    </AddNewButton>
                </CtaContainer>
            </Header>
            <TableWrap>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Row key={headerGroup.id} >
                            {headerGroup.headers.map((header) => (
                                <div
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={{ width: columnWidth[header.column.id].width + "%", 
                                        // minWidth: columnWidth[header.column.id].minWidth
                                     }}
                                    className="table-header"
                                >
                                    {
                                        (header.column.id == "no") ?
                                            <label class="checkbox-container">
                                                <input type="checkbox" checked={(competitors.length === selectedItems.length) ? "checked" : ""} />
                                                <span class="checkmark" onClick={() => toggleAllItems()}></span>
                                            </label> :
                                            <>{flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                                {{
                                                    asc: <img src={DownArrowIcon} style={{ rotate: "180deg"}}/>,
                                                    desc: <img src={DownArrowIcon} />,
                                                }[header.column.getIsSorted()] ?? null}
                                            </>
                                    }

                                </div>
                            ))}
                        </Row>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => (
                        <Row key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <div
                                    className={cell.column.id}
                                    style={{ width: columnWidth[cell.column.id].width + "%", 
                                        // minWidth: columnWidth[cell.column.id].minWidth
                                     }}
                                    key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>
                            ))}
                        </Row>
                    ))}
                </TableBody>
            </TableWrap>
            { isAddNewPopupOpened && <AddNewPopup handleSetup={handleSetup} forKeyword={false} handleClose={closeAddNewPopup} />}
        </KeywordsWrapper>

};

export default CompetitorsTable;


