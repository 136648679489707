import React, {useState} from 'react'
import { AddKeyword, CountryData, CountryHeader, HoverWrapper, KeyData, KeyHeader, KeywordTracking, KeywordWrap, PlatformData, PlatformHeader, Row, StatusData, StatusHeader, TableBody, TableHeader, TableWrap, UpdatedData, UpdatedHeader, Wrapper } from './domainTable.styled'
import plus from '../../../../../../../media/Dashboard/plus-small.svg'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    ColumnDef,
    getSortedRowModel
  } from '@tanstack/react-table'
import { useDomainTracking } from '../../../../context/domaintracking';
import moment from 'moment';

import { useNavigate } from "react-router-dom"
import FilterIcon from "../../../../../../../media/Dashboard/filter.png";
import UpArrow from "../../../../../../../media/Dashboard/uparrow.png";
import DownArrow from "../../../../../../../media/Dashboard/downarrow.png";

const DomainTable = () => {
    const columnHelper = createColumnHelper();
    const [sorting, setSorting] = useState([]);

    const {domainTrackingState, domainTrackingDispatch} = useDomainTracking()
    const navigate = useNavigate()

    const columns = [
        columnHelper.accessor('no', {
            id: 'no',
            header: () => 'No',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('keyword', {
            id: 'keyword',
            header: () => 'Keyword',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: () => 'Status',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('platform', {
            id: 'platform',
            header: () => 'Platform',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('country', {
            id: 'country',
            header: () => 'Country',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor(row => `${new Date(row.updatedOn).getTime()}`, {
            id: 'updatedOn',
            header: () => 'updatedOn',
            cell: info => info.getValue(),
        }),
    ];

    const table = useReactTable({
        data: domainTrackingState.domainTableList,
        columns,
        state: {
            sorting,
            sortBy: [{ id: "datetime", desc: false }],
          },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    const domainDetailsFn = (data) => {
        navigate(`/domain-tracking/details?domainUserRefId=${data.id}`)
        // tableListApi({ query: domain });
        // topDomains({ query: domain })
    }


    return (
    <Wrapper>
        <KeywordWrap>
            <KeywordTracking>Domain Tracking</KeywordTracking>
            <AddKeyword onClick={()=>{
                domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_LIST', value: [{ url: domainTrackingState.urlInput, platform: '', country: {}, platformError: '' }]})
                domainTrackingDispatch({type: 'SET_DOMAIN_INPUT_MODAL_OPEN', value: true})
            }}>
                <img src={plus}/>
                Add Domain
            </AddKeyword>
        </KeywordWrap>
        <TableWrap>
                    {table.getHeaderGroups().map(headerGroup => {
                        // console.log(headerGroup)
                        return <TableHeader>
                        <KeyHeader onClick={headerGroup.headers[1].column.getToggleSortingHandler()}>
                          Domain{" "}
                            <img
                                src={
                                headerGroup.headers[1].column.getIsSorted() == "asc"
                                    ? UpArrow
                                    : headerGroup.headers[1].column.getIsSorted() == "desc"
                                    ? DownArrow
                                    : FilterIcon
                                }
                            />
                        </KeyHeader>
                        <StatusHeader onClick={headerGroup.headers[2].column.getToggleSortingHandler()}>
                            Status{" "}
                                <img
                                    src={
                                    headerGroup.headers[2].column.getIsSorted() == "asc"
                                        ? UpArrow
                                        : headerGroup.headers[2].column.getIsSorted() == "desc"
                                        ? DownArrow
                                        : FilterIcon
                                    }
                                />
                        </StatusHeader>
                        <PlatformHeader onClick={headerGroup.headers[3].column.getToggleSortingHandler()}>
                            Platform{" "}
                                <img
                                    src={
                                    headerGroup.headers[3].column.getIsSorted() == "asc"
                                        ? UpArrow
                                        : headerGroup.headers[3].column.getIsSorted() == "desc"
                                        ? DownArrow
                                        : FilterIcon
                                    }
                                /> 
                        </PlatformHeader>
                        <CountryHeader onClick={headerGroup.headers[4].column.getToggleSortingHandler()}>
                            Country{" "}
                                <img
                                    src={
                                    headerGroup.headers[4].column.getIsSorted() == "asc"
                                        ? UpArrow
                                        : headerGroup.headers[4].column.getIsSorted() == "desc"
                                        ? DownArrow
                                        : FilterIcon
                                    }
                                /> 
                        </CountryHeader>
                        <UpdatedHeader onClick={headerGroup.headers[5].column.getToggleSortingHandler()}>
                            Updated On{" "}
                                <img
                                    src={
                                    headerGroup.headers[5].column.getIsSorted() == "asc"
                                        ? UpArrow
                                        : headerGroup.headers[5].column.getIsSorted() == "desc"
                                        ? DownArrow
                                        : FilterIcon
                                    }
                                />
                        </UpdatedHeader>
                    </TableHeader>
                       
                            })}
                  <TableBody>
                  {table.getRowModel().rows.map((row, rowIndex) => (
                        <Row key={rowIndex}>
                            <HoverWrapper onClick={()=>domainDetailsFn(row.original)}>
                                <KeyData>{row.original.hostname}</KeyData>
                                <StatusData>
                                {row.original.status ? <p>{'Active'}</p>:<p className='inactive'>{'InActive'}</p>}
                                </StatusData>
                                <PlatformData>{row.original.platform}</PlatformData>
                                <CountryData>{row.original.country}</CountryData>
                                <UpdatedData> {moment(row.original.updatedOn).format('YYYY-MM-DD HH:mm')}</UpdatedData>
                            </HoverWrapper>
                        </Row>
                    ))}
                    </TableBody>
        </TableWrap>
    </Wrapper>
  )
}

export default DomainTable
