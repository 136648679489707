import DashboardSetup from "./DashboardSetup";
import { BackArrowWrap, Container } from "./index.styled";
import DashboardOverview from "./DashboardOverview";
import { useEffect } from "react";
import getCountryByDialingCode from "../../utils/getCountryByDialingCode";
import { useDashboard } from "../../context/dashboard";
import { useAuth } from "../../../../../context/auth";
import LeftArrow from "../../../../../media/Dashboard/backarrow.svg";

function ProjectOverview() {
  const {
    authState: { userLocation },
  } = useAuth();
  const {
    dashboardState: { isKeywordsSetupDone, isCompetitorsSetupDone,projectList },
    dashboardDispatch,
  } = useDashboard();
  useEffect(() => {
    const country = getCountryByDialingCode(userLocation.countryCode);
    dashboardDispatch({
      type: "SET_SELECTED_COUNTRY",
      value: country.name,
    });
  }, []);
  console.log(projectList.length,'!projectList.length')
  return (
    <>
      <BackArrowWrap>
        {projectList.length != 1 && (
          <img
            src={LeftArrow}
            width={"30px"}
            onClick={() =>
              dashboardDispatch({ type: "SET_SELECTED_PROJECT", value: null })
            }
          />
        )}
      </BackArrowWrap>
      <Container>
        {(!isKeywordsSetupDone || !isCompetitorsSetupDone) && (
          <DashboardSetup />
        )}
        <DashboardOverview />
      </Container>
    </>
  );
}

export default ProjectOverview;
