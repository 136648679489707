import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Tooltip from 'rc-tooltip';

import {
    Container,
    SearchContainer,
    ResultsContainer,
    Wrapper,
    HeadingWrapper,
    KeywordInput,
    TrackButton,
    InputContainer,
    ClearButton,
    HeadingLeft,
    HeaderRight,
    HeadLeftWrap,
    HowItWork,
    RightIcons,
    ErrorIcon,
    ErrorBox
} from "./index.styled"

import PPCRestrictionPopup from '../../../components/PPCRestrictionPopup';
import SearchIcon from "../../../../media/Dashboard/searchIcon.svg"
import CloseIcon from "../../../../media/Dashboard/detailsPaneClose.svg"
import { useAuth } from '../../../../context/auth';

import { useKeywordOverview } from '../context/keywordOverview';
import GlobeDropdown from './components/globeDropdown/index'
import overview from "../assets/overview.png"
import errorIcon from "../assets/errorIcon.svg"


const KeywordOverview = () => {
    const {keywordOverviewState, keywordOverviewDispatch} = useKeywordOverview()

    const {authState, authDispatch} = useAuth()
    const [inputFocussed, setInputFocussed] = useState(false)
    const [error, setError] = useState(false)

    const navigate = useNavigate()

    const onExploreClick = () => {
        if(keywordOverviewState.overviewInput.length > 0){
            setError(false)
            keywordOverviewDispatch({type: 'error', value: ''})
            console.log('navigate')
            navigate(`/keyword-overview/details?keyword=${keywordOverviewState.overviewInput}&country=${keywordOverviewState.overviewCountry.code ? keywordOverviewState.overviewCountry.name.common : 'global'}`)
        }else{
            setError(true)
            keywordOverviewDispatch({type: 'error', value: 'Enter a valid URL'})
        }
    }

    const eventEnterHandle = (e) =>{
        if (e.key === 'Enter') {
            onExploreClick()
        }
    }
useEffect(() => {
    keywordOverviewDispatch({type: 'SET_KEYWORD_OVERVIEW_INPUT', value: ""})
}, [])

    const headingText = "Keyword Overview "
    const descriptionText = `Discover how any keyword performs at various layers of analysis. Just enter your keyword below to access customized insights that will help you strategically optimize your campaigns for better targeting and improved results. Give your own ad campaigns a huge boost with AdSpyder’s keyword Overview feature.`


    const toolTipFunction = ()  =>{
        return (
            <div>
                <div style={{marginBottom: '16px',fontSize: '14px', color: 'var(--black-100, #1C1C1C)',fontWeight: '500'}}>How to use:</div>
                <ul style={{listStyleType: 'decimal',marginLeft: '16px'}}>
                    <li  style={{marginBottom: '16px'}}>In the search bar, enter your chosen keyword. Select the country from the dropdown and simply click on the “Explore” button. </li>
                    <li  style={{marginBottom: '16px'}}>Get campaign and platform level analysis on the keyword, along with the top contending domains of the keyword. Also get information on related keywords with metrics such as search volume, competition and average cost-per-click (CPC) for your keyword. </li>
                    <li  style={{marginBottom: '16px'}}>To change the target country, simply select the country from the dropdown at the top right corner of the workspace. </li>
                    <li style={{marginBottom: '16px'}}>To start tracking the keyword, click on the “Track Keyword” button on the top right corner of the workspace. </li>
                </ul>
            </div>
        )
    }

    const tooltipStyle = {
        marginTop: '100px'
    };
    
    const overlayStyle = {

    };
  
    const overlayInnerStyle = {
        backgroundColor: '#FFF7E8',
        color: 'rgba(28, 28, 28, 0.80)',
        padding: '24px 20px 44px 20px',
        fontSize: '14px',
        marginTop: '120px',
        width: '400px',
        maxHeight: '510px',
        paddingBottom: '44px',
        overflowY: 'auto'
    }; 

    return <Container>
         
                {authState.isPPCRestrictedPopup && 
                    <ResultsContainer>
                        <PPCRestrictionPopup/>
                    </ResultsContainer>
                }
                
               { !keywordOverviewState.keywordTableOpen && <Wrapper isNavCollapsed={authState.isNavCollapsed} >
                    <HeadingWrapper>
                        <HeadingLeft>
                            <HeadLeftWrap>
                            <h2>{headingText}</h2>
                            <Tooltip 
                                trigger={['hover']}  
                                style={tooltipStyle}
                                overlayStyle={overlayStyle}
                                overlayInnerStyle={overlayInnerStyle} 
                                showArrow={false}
                                arrowContent={null}
                                overlay={toolTipFunction()}
                            >
                                <HowItWork>Quick guide</HowItWork>
                            </Tooltip>
                            </HeadLeftWrap>
                            <p>{descriptionText}</p>
                        </HeadingLeft>
                        <HeaderRight>
                            <img src={overview} />
                        </HeaderRight>
                    </HeadingWrapper>
                    <SearchContainer>
                    
                        <InputContainer isFocussed={inputFocussed}>
                            <img src={SearchIcon}/>
                            <KeywordInput
                                value={keywordOverviewState.overviewInput}
                                placeholder='Enter a specific keyword' 
                                onChange={(e)=>{
                                    keywordOverviewDispatch({type: 'SET_KEYWORD_OVERVIEW_INPUT', value: e.target.value})
                                    if(e.target.value.length > 0) setError(false)
                                    else setError(true)
                                }}  
                                onKeyDown={(e) => {
                                    eventEnterHandle(e)
                                }}   
                                onFocus={() => setInputFocussed(true)}
                                onBlur={() => setInputFocussed(false)}
                            />
                            <RightIcons>
                                {keywordOverviewState.overviewInput.length > 0 && <ClearButton  onClick={()=>{keywordOverviewDispatch({type: 'SET_KEYWORD_OVERVIEW_INPUT', value: ""})}} src={CloseIcon}/>}
                                {error && <ErrorIcon src={errorIcon} alt=""/>}
                            </RightIcons>
                            
                        </InputContainer>
                        <GlobeDropdown />
                                            
                        <TrackButton onClick={onExploreClick}>
                            Explore
                        </TrackButton>
                    </SearchContainer>
                    {error && <ErrorBox>Enter a keyword</ErrorBox>}
                </Wrapper>}
            </Container>
};
    

export default KeywordOverview