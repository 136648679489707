import React, {useEffect, useRef} from 'react'
import { useLocation, useSearchParams} from 'react-router-dom'

// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'

import {useDisplay} from "../context/display"
import {useSearch} from "../../context/search"
import { getSearchParams, urlPatternValidation, dateFormatter ,formatWithCommas} from "../../../../../../utils/helper.js"

// Icons
import Results from './Results'
import { useAuth } from '../../../../../../context/auth.js'
import { useDisplayInfo } from '../hooks/useDisplayInfo.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'

const DisplayAds = () => {

    const {authState, authDispatch} = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()

    const {displayState, displayDispatch} = useDisplay()
    const {searchState, searchDispatch} = useSearch()
    const location = useLocation()

    const useDisplayInfoHook = useDisplayInfo()

    const myRef = useRef(null);


    useEffect(() => {
        if(location.pathname.includes("display")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'display'})
        }
    }, [])


    useEffect(() => {
            // console.log('search param updated')
            const searchParamFromState = getSearchParams(location.search)

            const {
                searchMode,
                keywords,
                phraseInput,
                domainUrlInput,
                keywordSearchType,
                urlSearchType,
                searchIn,
                minimumShouldMatch,
                excludedTerm,
                page,
                selectedCountries,
                startDate,
                endDate,
                sortType
            } = searchParamFromState

            displayDispatch({type:'UPDATE_DETAILS_MODAL', value: false})

            if(searchMode){

                if (searchMode == "url"){
                    authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                    if(urlSearchType){
                        authDispatch({type: 'SET_URL_SEARCHTYPE', value: urlSearchType })
                        if(urlSearchType == "exact"){
                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "domain"){
                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "text"){
                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: domainUrlInput.length > 0}})
                            }
                        }
                    }
                }

                if(selectedCountries && selectedCountries.length > 0){
                    displayDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
                }else{
                    displayDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
                }

                if(startDate){
                    displayDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
                }else{
                    displayDispatch({type: 'UPDATE_END_DATE',value: ''})
                }
    
                if(endDate){
                    displayDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
                }else{
                    displayDispatch({type: 'UPDATE_END_DATE',value: ''})
                }

                if(sortType){
                    displayDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                        name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                        type: sortType
                    }})
                }

                if(page){
                    displayDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
                }
            }else{
                displayDispatch({type: 'CLEAR_ALL'})
            }

            // on filter applied
            if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){
                // console.log("inside filter")
                if (searchMode == "url") {
                    if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                        if (urlSearchType == "domain") {
                            
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }
                            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useDisplayInfoHook.urlDomainFilterMutate(reqBody)
                        } else if (urlSearchType == "text") {

                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }
                            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useDisplayInfoHook.urlTextFilterMutate(reqBody)
                        } else if (urlSearchType == "exact") {
                            
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                countries: selectedCountries ? selectedCountries.split(","): [],
                                startDate: dateFormatter(startDate),
                                endDate: dateFormatter(endDate),
                                sort: sortType
                            }
                            displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                            useDisplayInfoHook.urlExactFilterMatchMutate(reqBody)
                        }
                    }
                }
            }else{
                // console.log("without filter")
                // on not filter applied
                     if (searchMode == "url") {
                        if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                            if (urlSearchType == "domain") {
                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page
                                }
                                displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useDisplayInfoHook.urlDomainMutate(reqBody)
                            } else if (urlSearchType == "text") {
                        
                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page
                                }
                                displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useDisplayInfoHook.urlTextMutate(reqBody)
                            } else if (urlSearchType == "exact") {
                                const reqBody = {
                                    query: domainUrlInput,
                                    page: page
                                }
                                displayDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                                useDisplayInfoHook.urlExactMutate(reqBody)
                            }
                        }
                    }
            }

    }, [location.search])
    
    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        displayDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }

    // return <Container ref={myRef}>
    //     <SearchContainer>
    //         {/* <Content>
    //             <PlatformDropDown/>
    //             <SearchMode/>
    //             <Separator/> {
    //             displayState.searchMode === "keyword" ? <KeywordComponent/>: <UrlComponent/>
    //         } </Content>
    //         {
    //         !searchState.limitExceeded && < Filters />
    //         }  */}
    //         <ResultPageSearch/>
    //     </SearchContainer>
    //     <ScrollableContainer>
    //     {
    //         !searchState.limitExceeded && < Filters />
    //     }
    //     {
    //     displayState.ads && displayState.ads.length > 0 && (
    //         <AdsCount>
    //             <div><p>
    //                 <span>Display Search ads{" "}</span>
    //                 - {(displayState.currentPageNo - 1) * 20 != 0 ? (displayState.currentPageNo - 1) * 20 + 1 : 1} - {(((displayState.currentPageNo - 1) * 20) + 20) > displayState.totalAds ? displayState.totalAds : ((displayState.currentPageNo - 1) * 20) + 20} of <span>{
    //                     displayState.totalAds
    //                 }</span>
    //                     {" "}results | Queries:{" "} 
    //             </p></div>
    //             {/* <SearchQuery> {
    //                 displayState.keywords.length > 0 ? (displayState.keywords.map((word, idx) => {
    //                     return <span>{word}</span>;
    //                 })) : displayState.phraseInput ? (
    //                     <span>{
    //                         displayState.phraseInput
    //                     }</span>
    //                 ) : (
    //                     <span>{
    //                         displayState.domainUrlInput.value
    //                     }</span>
    //                 )
    //             } </SearchQuery> */}
    //         </AdsCount>
    //     )
    // }
    //     <Results/> {
    //     !searchState.limitExceeded && displayState.ads && displayState.ads.length > 0 && <Pagination/>
    // } 
    // </ScrollableContainer>
    // </Container>

    return <Container ref={myRef}>
    {
            <>
                <Breadcrumb>
                    {"Overview > "} <span>{authState.searchMode === "keyword" ? "Text Search in Ad library" : "Url Search in Ad library"}</span>
                </Breadcrumb>
                <Header>
                    <HeaderLeft>
                        <h2>Display Search ads</h2>
                    </HeaderLeft>
                    <HeaderRight>
                        <TotalAdsFound>
                            <label>Total ads found :</label>
                            <span>
                                {formatWithCommas(displayState.totalAds)}
                            </span>
                        </TotalAdsFound>
                        <SortType />
                        <MajorFilters />
                    </HeaderRight>
                </Header>
                <AppliedFilters>
                    {displayState.selectedCountries.length > 0 && <AppliedFilterItem>
                        <p>{displayState.selectedCountries.length == 1 ? displayState.selectedCountries[0] : displayState.selectedCountries.length > 1 ? displayState.selectedCountries[0] + " + " + (
                            displayState.selectedCountries.length - 1
                        ) : "no country"}</p>
                        <Close onClick={()=>ClearCountry()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {displayState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                        <p>{displayState.selectedSortType.name}</p>
                        <Close onClick={()=>ClearSortType()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {displayState.dateFilterType.length > 0 && <AppliedFilterItem>
                        <p>{displayState.dateFilterType}</p>
                        <Close onClick={()=>ClearDateFilter()}>
                            x 
                        </Close>
                    </AppliedFilterItem>}
                    {/* {(displayState.selectedCta?.length > 0 || displayState.selectedTone?.length > 0 || displayState.sentimentFilter?.length > 0 || displayState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                        <p>Advanced filters</p>
                        <Close onClick={()=>OpenAdvancedFilter()}>
                            x 
                        </Close>
                    </AppliedFilterItem>} */}
                </AppliedFilters>
                <ScrollableContainer>
                    <Results />
                    <Footer>
                        {(!authDispatch.limitExceeded && displayState.ads && !displayState.loadingResults && displayState.ads.length > 0) && <TotalResults>
                            <p>Showing {(displayState.currentPageNo - 1) * 20 != 0 ? (displayState.currentPageNo - 1) * 20 + 1 : 1} - {(((displayState.currentPageNo - 1) * 20) + 20) > displayState.totalAds ? displayState.totalAds : ((displayState.currentPageNo - 1) * 20) + 20} of <span>{
                                displayState.totalAds
                            }</span>
                            results</p>
                        </TotalResults>}
                        {!authDispatch.limitExceeded && displayState.ads && !displayState.loadingResults && displayState.ads.length > 0 && <Pagination />}
                    </Footer>
                </ScrollableContainer>
            </>
        }
    </Container>

}

export default DisplayAds
