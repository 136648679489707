import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { appClient } from "../../../../utils/axios-utils";
import Layout from "./../Components/Layout";
import SuccessfulVerify from "../../../../media/Dashboard/paymentFailureIcon.png";

const Section = styled.div`
  h2 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #2e2e2e;
  }
  p {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    color: #707070;
  }
`;

const VerifiedContainer = styled.div`
  display: flex;
  width: 306px;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;

  /* margin: 0 auto; */
  img {
    width: 114px;
    height: 60px;
  }

  p {
    margin-top: 20px;
    margin-left: 20px;
    color: #707070;
    font-size: 16px;
    font-weight: 300;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const HomeButton = styled.button`
  width: 234px;
  height: 40px;
  border-radius: 8px;
  background-color: ${(props) => (props.currentPlan ? "#FFD0C0" : "#FF711E")};
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 16px;
  :hover {
    filter: brightness(90%);
  }
`;

const BackButton = styled.div`
  margin-left: 20px;
  font-size: 16px;
  font-weight: 300;
  color: #2e2e2e;
  cursor: pointer;
`;

const PaymentFailure = () => {
  // console.log("failure page");
  const completePurchase = () => {
    const config = {
      url: "/payment/app/complete-purchase",
      method: "GET",
    };

    return appClient(config);
  };

  const mailTriggerForCompletePurchase = useQuery(
    ["completePurchase"],
    completePurchase,
    {
      enabled: false,
    }
  );
  const navigate = useNavigate();

  const onHome = () => {
    navigate("/plans");
  };

  useEffect(() => {
    // const isCompleteMailTriggered = localStorage.getItem(
    //   "complete-purchase-mail-triggered"
    // );
    // if (!isCompleteMailTriggered) {
    //   localStorage.setItem("complete-purchase-mail-triggered", true);
    //   mailTriggerForCompletePurchase.refetch();
    // }
    mailTriggerForCompletePurchase.refetch();
  }, []);

  return (
    <Layout>
      <Section>
        <VerifiedContainer>
          <img src={SuccessfulVerify} />
        </VerifiedContainer>
        <h2>Payment Unsuccessful</h2>
        <p>
          Your card has been validated via a $1.00 reversible transaction. It
          may take about 10 minutes to be processed. You can start using
          AdSpyder now.
        </p>
        <ButtonContainer>
          <HomeButton onClick={() => navigate(-1)}>Retry Payment</HomeButton>
          <BackButton onClick={onHome}>Go back</BackButton>
        </ButtonContainer>
      </Section>
    </Layout>
  );
};

export default PaymentFailure;
