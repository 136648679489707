import React, { useState, useEffect, useRef } from 'react'
import { Container, Down, Row1, Row, Drop } from './index.styled'
import { useNavigate } from 'react-router-dom'

// import { useYoutube } from '../../../context/youtube'
// import { useSearch } from '../../../../context/search'
import { useAuth } from "../../../../../../../../context/auth"

import DropDownArrow from '../../../../../../../../media/Dashboard/drop-down-arrow.svg'

// import GoogleLogo from '../../../../../../../../media/Dashboard/Google16.svg'
// import BingLogo from '../../../../../../../../media/Dashboard/Bing16.svg'
// import YoutubeLogo from '../../../../../../../../media/Dashboard/YouTube16.svg'
// import MetaLogo from '../../../../../../../../media/Dashboard/meta16.png'
// import LinkedinLogo from '../../../../../../../../media/Dashboard/LinkedIn16.svg'
// import RedditLogo from '../../../../../../../../media/Dashboard/Reddit16.svg'
// import GooglePlaLogo from '../../../../../../../../media/Dashboard/Googlepla16.svg'
// import BingPlaLogo from '../../../../../../../../media/Dashboard/Bingpla16.svg'
// import FlipkartLogo from '../../../../../../../../media/Dashboard/flipkart16.png'
// import AmazonLogo from '../../../../../../../../media/Dashboard/amazon16.svg'
// import DisplayLogo from '../../../../../../../../media/Dashboard/Display16.svg'
import { useAdManagement } from '../../../../context/AdManagementContext'

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const PlatformList = [
    {
        // logo: GoogleLogo,
        platform: "google",
    },{
        // logo: BingLogo,
        platform: "bing",
    },{
        
    },{
        // logo: YoutubeLogo,
        platform: "youtube",
    },{
        
    },{
        // logo: MetaLogo,
        platform: "meta",
    },{
        // logo: LinkedinLogo,
        platform: "linkedin",
    },{
        // logo: RedditLogo,
        platform: "reddit"
    },{
        // logo: GooglePlaLogo,
        platform: "googlepla"
    },{
        // logo: BingPlaLogo,
        platform: "bingpla"
    },{
        // logo: FlipkartLogo,
        platform: "flipkart"
    },{
        // logo: AmazonLogo,
        platform: "amazon"
    },{
        // logo: DisplayLogo,
        platform: "display",
    },
]

const AllAccounts = () => {
    // const { youtubeState, youtubeDispatch } = useYoutube()
    const { authState, authDispatch } = useAuth()
    const { adManagementState, adManagementDispatch } = useAdManagement()

    // const { searchState, searchDispatch } = useSearch() 
    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()

    useOutsideAlerter(wrapperRef, setShow)

    const getCurrentPlatformImg = (platform) => {
        let currentPlatform = PlatformList.filter((p,idx)=>{
            return p.platform == platform
        })

        return currentPlatform[0].logo
    }

    return <Container ref={wrapperRef}>

        <Drop onClick={() => { setShow(prev => !prev) }}>
        All accounts
            {/* <img className="platform-logo" src={getCurrentPlatformImg(searchState.searchPlatform)}/> */}
            <img src={DropDownArrow} />
        </Drop>

        <Down show={show}>
        {adManagementState.isAdAccountList.length == 0 ? <Row1>
                <p>No accounts</p>
            </Row1>
            :
            adManagementState.isAdAccountList.map((platform, index) => {
                // if(platform.logo){
                    return <Row key={index}
                    // disabled={!authState.features.allowedPlatforms.includes(platform.platform) || platform.disabled}
                    onClick={() => {
                        // searchDispatch({type:'SET_SEARCH_PLATFORM', value: platform.platform})
                        setShow(false)
                        // navigate(`/adspy/${platform.customerId}`)
                    }}>
                        <p>{platform.customerId}</p>
                    </Row>
                // }else{
                //     return <Separator/>
                // }
            })}
        </Down>
    </Container>
}

export default AllAccounts