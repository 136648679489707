import React, {useState, useEffect, useRef} from "react";
import { io } from 'socket.io-client'

import BellIcon from "../../../../media/Dashboard/bellIconBlack.svg"
import { Container, NotificationHead, Count, NotificationBody, BodyHeader, Content, ToggleContainer, ToggleOptionContainer, MessageContainer, MessageContentContainer, MessageFooter, Time, ReadStatus, MarkAsRead, NoNotification, YellowBellContainer } from "./index.styled"
import toast, { Toaster, useToaster } from 'react-hot-toast';
import OpenedLetter from "../../../../media/Dashboard/openedLetter.svg"
import ClosedLetter from "../../../../media/Dashboard/closedLetter.svg"
import YellowBell from "../../../../media/Dashboard/yellowBell.svg"
import PinkTick from "../../../../media/Dashboard/pinkTick.svg"

import { useAuth } from "../../../../context/auth"

import _static from '../../../../config/static'
import { customToast } from "../../../../utils/toast-utils";

const { backendUrl, baseUrl } = _static

function useOutsideAlerter(ref, setNotificationOpen) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setNotificationOpen(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}



const Notification = () => {
    const { authState, authDispatch } = useAuth()

    const [socketInstance, setSocketInstance] = useState(null)

    // console.log('rendering', socketInstance)

    const [notificationOpen, setNotificationOpen] = useState(false)
    const [unReadMessages, setUnReadMessages] = useState([])
    const [currentTab, setCurrentTab] = useState("all")
    const wrapperRef = useRef(null)

    const [notificationData, setNotificationData] = useState([])

    useOutsideAlerter(wrapperRef, setNotificationOpen)

    const updateNotification = (notificationObj) => {
        setNotificationData((prevArray) => {
          return prevArray.map((obj) => {
            if (obj.id === notificationObj.id) {
              return notificationObj
            }
            return obj
          })
        })
    }

    const markRead = (notificationId) => {
        // console.log('soc ins', socketInstance)
        socketInstance.emit('mark-one-read', authState.userId, notificationId)
    }

    const markAllRead = () => {
        socketInstance.emit('mark-all-read', authState.userId)
    }

      
    const CountUnreadMessages = () => {
        // console.log("notificationData", notificationData)
        let unReadMessages = notificationData.filter((d)=>{
            if(!d.read){
                return d
            }
        })
        // console.log("unReadMessages", unReadMessages)

        setUnReadMessages(unReadMessages)
    }

    const onNotificationClick = (data) => {
        console.log("onNotificationClick",data)
        if(data.action){
            markRead(data.id)
            window.open(data.url)
        }else{
            markRead(data.id)
        }
    }

    useEffect(() => {
        if(authState.userId){
            const socket = io(backendUrl, {
                query: {
                    userId: authState.userId
                },
                transports: ["websocket"]
            })
            
            setSocketInstance(socket)
        
            return () => {
                setNotificationData([])
                setSocketInstance(null)
                socket.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        if(socketInstance) {
            socketInstance.emit('get-user-notifications', authState.userId)
        
            socketInstance.on('notification', (data) => {
                setNotificationData(prev => [data, ...prev])
                if(!notificationOpen){
                    toast(<span style={{cursor: "pointer"}} onClick={(t)=> { toast.dismiss(t.id); onNotificationClick(data) }}>{data.message}</span>, {
                        icon: '📣',
                        position: 'bottom-right',
                        duration: 10000, // Set the duration in milliseconds (optional)
                    });
                }
            })
        
            socketInstance.on("update-notification", (data) => {
                updateNotification(data)
            })
        
            socketInstance.on("notifications", (data) => {
                setNotificationData(data)
            })
            
            socketInstance.on("notifications-prepend", (data) => {
                setNotificationData(prev => [...data, ...prev])
            })
        }
    }, [socketInstance])

    useEffect(() => {
        CountUnreadMessages()
    }, [notificationData])
    

    return <Container ref={wrapperRef}>
        <NotificationHead onClick={()=>setNotificationOpen(!notificationOpen)}>
            <img src={BellIcon}/>
            {unReadMessages.length > 0 && <Count>
                {unReadMessages.length}
            </Count>}
        </NotificationHead>
        {notificationOpen && <NotificationBody>
            <BodyHeader>
                <ToggleContainer>
                    <ToggleOptionContainer
                        active={currentTab === "all"} 
                        onClick={() => {
                            setCurrentTab("all")
                        }}>
                        <label for="all"><p>All notification</p></label>
                    </ToggleOptionContainer>
                    <ToggleOptionContainer
                        active={currentTab === "unread"} 
                        onClick={() => {
                            setCurrentTab("unread")
                        }}>
                        <label for="unread"><p>Unread</p>
                        </label>
                    </ToggleOptionContainer>
                </ToggleContainer>
                <MarkAsRead isDisabled={unReadMessages.length == 0} onClick={markAllRead}>
                    <img src={OpenedLetter}/> <p>Mark all as Read</p>
                </MarkAsRead>
            </BodyHeader>
            <Content>
                {notificationData.length == 0 && currentTab != "unread" && <NoNotification>
                        <YellowBellContainer>
                            <img src={YellowBell}/>
                        </YellowBellContainer>
                        <h3>
                            Your notifications will appear here 📣
                        </h3>
                        <p>
                            This is where you'll find all the latest updates, alerts, and activity from AdSpyder. 
                        </p>
                    </NoNotification>
                }

                {
                    currentTab == "unread" ? unReadMessages.length == 0 ? <NoNotification>
                    <YellowBellContainer>
                        <img src={PinkTick}/>
                    </YellowBellContainer>
                    <h3>
                        You’re all caught up! ✅
                    </h3>
                    <p>
                        You're up-to-date with all your notifications. We'll ping you when there's something new! 
                    </p>
                </NoNotification> : unReadMessages.map((data)=>{
                    return <MessageContainer unreadread={!data.read} onClick={()=>onNotificationClick(data)} unread={!data.read}>
                        <MessageContentContainer>
                            <p>{data.message}</p>
                            {!data.read && <span>
                            </span>}
                        </MessageContentContainer>
                        <MessageFooter>
                            <Time>
                                1 hr ago
                            </Time>
                            {!data.read && <ReadStatus onClick={(e)=>{e.stopPropagation();markRead(data.id)}} unread={!data.read} className="notification-status">
                                <img src={!data.read ? ClosedLetter : OpenedLetter}/>
                            </ReadStatus>}
                        </MessageFooter>
                    </MessageContainer>
                }) : notificationData.length > 0 && notificationData.map((data)=>{
                    return <MessageContainer onClick={()=>onNotificationClick(data)} unread={!data.read}>
                        <MessageContentContainer>
                            <p>{data.message}</p>
                            {!data.read && <span>

                            </span>}
                        </MessageContentContainer>
                        <MessageFooter>
                            <Time>
                                {/* 1 hr ago */}
                            </Time>
                            {!data.read && <ReadStatus onClick={(e)=>{e.stopPropagation();markRead(data.id)}} unread={!data.read} className="notification-status">
                                <img src={!data.read ? ClosedLetter : OpenedLetter}/>
                            </ReadStatus>}
                        </MessageFooter>
                    </MessageContainer>
                })}
            </Content>
        </NotificationBody>}
    </Container>
}

export default Notification