import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom'


/* STYLES */
import { Container, Content, Wrapper } from './index.styled'

/* COMPONENTS */
import Header from './components/Header'
import SideNav from './components/SideNav'
import { useAuth } from '../../context/auth'

const DashboardLayout = ({children}) => {
    // console.log("layout render")
    const location = useLocation()

    const routesWithoutSideNav = ["home", "plan"]

    const [noSideNav,setNoSideNav] = useState(false)
    const {authState, authDispatch} = useAuth()
    
    useEffect(() => {
        routesWithoutSideNav.map((r)=>{
            if(location.pathname.includes(r)){
                setNoSideNav(true)
            }
        })
    }, [])
    
    if(location.pathname === "/browser-compatiblity") {
        return <Container>
                {children}
            </Container>
    } else {
        return <>
                <Container>
                    {!noSideNav && <SideNav/>}
                    <Wrapper isNavCollapsed={authState.isNavCollapsed} noSideNav={noSideNav}>
                        <Header noSideNav={noSideNav}/>
                        <Content noSideNav={noSideNav}>
                            {children}
                        </Content>
                    </Wrapper>  
                </Container>
            </>
    }
}

export default DashboardLayout