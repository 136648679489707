import styled from "styled-components";

export const FilterDropDown = styled.div`
    height: 100%;
    width: 112px;
    position: relative;
    margin-left: 8px;
`;

export const SelectedFilter = styled.div`
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FF711E;
    height: 100%;
    display: flex;
    color: #FFF;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
`;

export const FilterListDropDown = styled.div`
    width: 280px;
    height: 372px;
    position: absolute;
    top: 44px;
    z-index: 5;
    border-radius: 4px;
    border: 1px solid #CFD0D1;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FilterListContainer = styled.div`
    

`;

export const Divder = styled.div`
    border-bottom: solid 1px #DADFE4;
    margin: 0px 16px;
`;

export const FilterListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 12px;

    h3{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        color: rgba(28, 28, 28, 0.90);
        letter-spacing: 0.14px;
    }
`;

export const ClearAll = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: #6C95E4;
    line-height: 15px;
    cursor: pointer;
`;

export const FilterList = styled.div`
    height: 268px;
    overflow-y: auto;
`;

export const ExcludeTermContainer = styled.div`
    padding: 0px 16px 12px;
    input{
        border-radius: 4px;
        border: 1px solid #DADFE4;
        background: #FFF;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.13px;
        padding: 4px 10px;
        outline: none;
    }

`;

export const CountryFilterSection = styled.div`

`;


export const CountryFilterSectionHead = styled.div`
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img{
        transform: ${props => props.isActive ? "rotate(180deg)" : ""};
    }
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        color: ${props => props.isActive ? "rgba(28, 28, 28, 0.80)" : "rgba(28, 28, 28, 0.50)"};
    }
`;


export const CountryFilterSectionBody = styled.div`
    background: #F7F8F9;
`;

export const TimeFilterSection = styled.div`

`;


export const TimeFilterSectionHead = styled.div`
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img{
        transform: ${props => props.isActive ? "rotate(180deg)" : ""};
    }
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        color: ${props => props.isActive ? "rgba(28, 28, 28, 0.80)" : "rgba(28, 28, 28, 0.50)"};
    }
`;


export const TimeFilterSectionBody = styled.div`
    background: #F7F8F9;
`;



export const SearchInFilterSection = styled.div`

`;


export const SearchInFilterSectionHead = styled.div`
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img{
        transform: ${props => props.isActive ? "rotate(180deg)" : ""};
    } 

    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        color: ${props => props.isActive ? "rgba(28, 28, 28, 0.80)" : "rgba(28, 28, 28, 0.50)"};
    }
`;


export const SearchInFilterSectionBody = styled.div`
    background: #F7F8F9;
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    height: 50px;
    border-top: solid 1px #CFD0D1; 
`;


export const MoreFiltersBtn = styled.div`
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(255, 113, 30, 0.80);
    cursor: pointer;
`;


export const ApplyFiltersBtn = styled.button`
    border-radius: 3px;
    background: #FF711E;
    width: 108px;
    height: 26px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    color: #FFF;
    border: none;
    outline: none;
`;


