import React from "react";
import WarningIcon from "../../media/Dashboard/warning.svg"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {useMutation} from "@tanstack/react-query"
import {appClient} from '../../utils/axios-utils'
import {useAuth} from "../../context/auth"
import toast from "react-hot-toast"

const PopupContainer = styled.div`
    position: absolute;
    top: 0;
    z-index: 2;

    background: #00000045;
    left: 0;
    /* top: 54px;
    width: 100vw;
    height: calc(100vh - 54px); */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopupBody = styled.div`
    max-width: 520px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 112px;
    position: relative;
    img{
        width: 46px;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

const LogoutButton = styled.button`
    border: none;
    outline: none;
    background: none;
    position: absolute;
    right: 25px;
    top: 20px;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
`;

const UpgradeButton = styled.button`
    width: 276px;
    height: 32px;
    margin-left: 8px;
    border-radius: 8px;
    background-color: rgb(255, 113, 30);
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    margin-bottom: 8px;
`;

const AccountsButton = styled.button`
    width: 276px;
    height: 32px;
    margin-left: 8px;
    border-radius: 8px;
    color: #2E2E2E;
    font-size: 14px;
    border: none;
    cursor: pointer;
`;
const logoutUser = () => {
    const config = {
        url: "/auth/logout",
        method: "DELETE"
    }

    return appClient(config)
}
const LimitExceededPopup = ({err}) => {
    const {authState, authDispatch} = useAuth()

    let toastId = "";

    const {mutate: onLogout} = useMutation(logoutUser, {
        onSuccess: (data) => {
            authDispatch({type: 'LOGOUT_SUCCESS'})
            toast.success("Signed out succesfully")
            authDispatch({type:'STOP_LOADER'})
            toast.dismiss(toastId)
            navigate('/')
        },
        onError: (err) => {
            toast.dismiss(toastId)
        },
        onMutate: () => {
            authDispatch({type:'START_LOADER'})
            // toastId = toast.loading("Signing out")
        }
    })

    const navigate = useNavigate()
    let heading = err == "searches-exhausted" ? "You've reached your search limit" : err == "adviews-exhausted" ? "Ad Copy Limit Reached" : err == "access-time-expired" ? "Your Plan is expired" : err
    let content = err == "searches-exhausted" ? "You have reached the max search limit allowed in your plan. Please upgrade to a higher plan to continue using AdSpyder." : err == "adviews-exhausted" ? "Looks like you've viewed all the ad copies your current plan allows for. Please upgrade to a higher plan to continue using AdSpyder." : err == "access-time-expired" ? "" : "Looks like your free plan validity is expired. Please upgrade to a higher plan to continue using AdSpyder."

    return <PopupContainer>
        <PopupBody>
            <LogoutButton onClick={onLogout}>
                Log out
            </LogoutButton>
            <img src={WarningIcon}/>
            <h2>
                {heading}
            </h2>
            <p>
                {content}
            </p>
            <UpgradeButton onClick={()=>navigate("/plans")}>
                Upgrade plan
            </UpgradeButton>
            <AccountsButton onClick={()=>navigate("/account")}>
                Accounts & Settings
            </AccountsButton>
        </PopupBody>
    </PopupContainer>
}

export default LimitExceededPopup