import React, {useEffect} from "react"
import styled, {keyframes} from "styled-components"
import { useSearchParams, useNavigate } from "react-router-dom"

import KeywordSearchType from './Type'

/* Broad components */
import BroadKeywordsInput from "./BroadComponents/KeywordsInput"

/* Phrase components */
import PhraseInput from "./PhraseComponents/Input"

import ToolTipIcon from '../../../../../../media/Dashboard/info_button_tool_tip.svg'

// import { useSearch } from './../../../../context/search'
import { useAuth } from "../../../../../../context/auth"

const Button = styled.button `
    width: 15%;
    min-width: 75px;
    height: 40px;
    max-width: 80px;
    /* margin-left: 8px; */
    border-radius: 0px 8px 8px 0px;
    background-color: ${props => props.isDisabled ? "#E7E7E7" : "#FF711E"};
    pointer-events: ${props => props.isDisabled ? "none": "all"};
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    /* margin-top: 20px; */
    :hover {
        filter: brightness(90%);
    }
`;

const SpinAnime = keyframes `
  0% { -webkit-transform: rotate(0deg);}
  100% { -webkit-transform: rotate(360deg); }
`;

export const Spinner = styled.div `
  border: 2px dotted #dbf2ff; 
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-right: 2px solid #018df7;
  text-align:center;
  animation-name: ${SpinAnime};
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
  margin-left: 1rem;
`;

const ToolTipContainer = styled.div`
    font-size: 12px;
    font-weight: 300;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img{
        margin-left: 4px;
    }
`;

const Wrapper = styled.div`
    display: flex;
`;

const KeywordComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { authState, authDispatch } = useAuth()
    const navigate = useNavigate()

    // const { searchState, searchDispatch} = useSearch()

    const handleSearch = () => {
        const {searchMode} = authState

        authDispatch({type: "CLEAR_FILTERS"})

        if(!authState.searchPlatform){
            return authDispatch({type: 'SET_SEARCH_PLATFORM_ERROR', value: true})
        }

        if(!authState.features?.allowedPlatforms.includes(authState.searchPlatform)){
            authDispatch({type: 'UPDATE_IS_RESTRICTED', value: true})
        }else if(searchMode === "keyword") {
            // console.log("2", authState)
            const {keywordSearchType} = authState
            if (keywordSearchType === "broad") {
                const {broadMinShouldMatch, keywords} = authState

                if (keywords.length == 0) {
                    return authDispatch({type: 'SET_KEYWORD_INPUT_ERROR', value: true})
                }

                let broadSearchIn = ["content", "title"]
                if(authState.searchPlatform == "meta"){
                    broadSearchIn = broadSearchIn.concat(["innertitle","innerdescription"])
                }

                authDispatch({type: "SET_HIGHLIGHT_WORDS", value: keywords})

                let paramQuery = keywords.join(",")

                searchParams.set('searchMode',"keyword")
                searchParams.set('keywordSearchType',"broad")
                searchParams.set('keywords', paramQuery);
                searchParams.set('page', 1);
                searchParams.set('excludedTerm', "");
                searchParams.set('minimumShouldMatch', broadMinShouldMatch.value);
                searchParams.set('searchIn', broadSearchIn.join(","));
                searchParams.set('sortType', 'relevant');
                searchParams.delete('selectedCountries');
                searchParams.delete('language');
                searchParams.delete('startDate');
                searchParams.delete('endDate'); 
                searchParams.delete('cta'); 
                searchParams.delete('tone'); 
                searchParams.delete('otherFilters'); 
                searchParams.delete('sentiment'); 
                // setSearchParams(searchParams)                

                navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

            } else if (keywordSearchType === "phrase") {
                const { phraseInput} = authState
                if (phraseInput.trim().length == 0) {
                    return authDispatch({type: 'SET_KEYWORD_INPUT_ERROR', value: true})
                }
                
                let phraseSearchIn = ["content", "title"]
                if(authState.searchPlatform == "meta"){
                    phraseSearchIn = phraseSearchIn.concat(["innertitle","innerdescription"])
                }

                authDispatch({type: "SET_HIGHLIGHT_WORDS", value: phraseInput.split(" ")})

                let paramQuery = phraseInput.trim()

                searchParams.set('searchMode',"keyword")
                searchParams.set('keywordSearchType',"phrase")
                searchParams.set('phraseInput', paramQuery);
                searchParams.set('page', 1);
                searchParams.set('excludedTerm', "");
                searchParams.set('searchIn', phraseSearchIn.join(","));
                searchParams.set('sortType', 'relevant');
                searchParams.delete('selectedCountries');
                searchParams.delete('language');
                searchParams.delete('startDate');
                searchParams.delete('endDate');  
                searchParams.delete('cta'); 
                searchParams.delete('tone'); 
                searchParams.delete('otherFilters'); 
                searchParams.delete('sentiment'); 
                // setSearchParams(searchParams)
                navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

            }
        }
    }


    return <React.Fragment>
        <KeywordSearchType/> 
        {
        authState.keywordSearchType === "broad" ? 
        <React.Fragment>
            <BroadKeywordsInput/>
            {/* <BroadMinShouldMatch/> */}
            {/* <ExcludeTerm/> */}
            {/* <BroadSearchIn/> */}
        </React.Fragment> 
        : <React.Fragment>
            <PhraseInput/>
            {/* <ExcludeTerm/> */}
            {/* <PhraseSearchIn/> */}
        </React.Fragment>
        }
        <Button isDisabled={authState.searchDisabled} onClick={handleSearch}>Search</Button>

    {/* {(isKeywordBroadLoading || isKeywordPhraseLoading) && <Spinner />} */} 
    </React.Fragment>
}
    
export default KeywordComponent
