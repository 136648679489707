import React from "react";
import WarningIcon from "../../media/Dashboard/warning.svg"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";

const PopupContainer = styled.div`
    position: fixed;
    z-index: 10;

    background: #00000045;
    left: 0;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopupBody = styled.div`
    max-width: 520px;
    width: 80%;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000003D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 112px;
    position: relative;
    img{
        width: 46px;
        margin-bottom: 24px;
    }
    h2{
        margin-bottom: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #2E2E2E;       
    }
    p{
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color: #4E4E4E;
        text-align: center;
        line-height: 21px;
    }
`;

const LogoutButton = styled.button`
    border: none;
    outline: none;
    background: none;
    position: absolute;
    right: 25px;
    top: 20px;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 300;
`;

const UpgradeButton = styled.button`
    width: 276px;
    height: 32px;
    margin-left: 8px;
    border-radius: 8px;
    background-color: rgb(255, 113, 30);
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    margin-bottom: 8px;
`;

const AccountsButton = styled.button`
    width: 276px;
    height: 32px;
    margin-left: 8px;
    border-radius: 8px;
    color: #2E2E2E;
    font-size: 14px;
    border: none;
    cursor: pointer;
`;

const DomainAnalysisRestrictionPopup = ({title, Heading}) => {
    const {authState, authDispatch} = useAuth()
    const navigate = useNavigate()

    return <PopupContainer>
        <PopupBody>
        {/* temp change  */}
            <LogoutButton onClick={()=>{console.log("close clicked");authDispatch({type: 'UPDATE_IS_PPC_RESTRICTED', value: false})}}>
                close
            </LogoutButton>
            <img src={WarningIcon}/>
            <h2>
                You cannot access Domain Analysis with your current plan.
            </h2>
            <p>
                Consider subscribing to one of our higher plans to access Domain Analysis and other premium features.
            </p>
            <UpgradeButton onClick={()=>navigate("/plans")}>
                Upgrade plan
            </UpgradeButton>
        </PopupBody>
    </PopupContainer>
}

export default DomainAnalysisRestrictionPopup