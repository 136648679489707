import React, { useEffect } from 'react'

import { Navigate, Outlet, useLocation } from "react-router-dom"


import _static from '../../config/static'
import { useAuth } from '../../context/auth'

const { adspyPage } = _static

const WelcomeLayout = () => {
    const { authState } = useAuth()
    const location = useLocation()

    if(!authState.isAuthenticated){
        return <Navigate to={`/`} state={{ from: location }} replace/>
    }else if(authState.old && !authState.notified){
        return <Outlet/>
    }else if(!authState.emailVerified){
        return <Navigate to={`/confirmemail`} state={{ from: location }} replace/>
    }else if(!authState.postloginSurvey){
        return <Navigate to={`/loginsurvey`} state={{ from: location }} replace/>
    }else if(authState.plan.active){
        return <Navigate to={`/adspy`} state={{ from: location }} replace/>
    }else{
        return <Navigate to={'/plans'} state={{ from: location }} replace />
    }
}

export default WelcomeLayout